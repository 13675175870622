import React, { useState } from "react";
import { IoMdCreate } from "react-icons/io";
import Card from "components/card";
const CustomerDetailsForm = () => {
  const [notes, setNotes] = useState("");
  const [editMode, setEditMode] = useState(false);

  const handleEditNotes = () => {
    setEditMode(!editMode);
  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
    <div className="mx-auto max-w-2xl mt-10">
      <div className="mb-4 rounded bg-blue-300 px-8 pt-6 pb-8 shadow-md">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full rounded-md border px-4 py-2"
              placeholder="Enter your name"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="email"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full rounded-md border px-4 py-2"
              placeholder="Enter your email"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="mobile"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Mobile Number
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              className="w-full rounded-md border px-4 py-2"
              placeholder="Enter your mobile number"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="country"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Country
            </label>
            <input
              type="text"
              id="country"
              name="country"
              className="w-full rounded-md border px-4 py-2"
              placeholder="Enter your country"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="city"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              className="w-full rounded-md border px-4 py-2"
              placeholder="Enter your city"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="service"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Package Type
            </label>
            <input
              type="text"
              id="service"
              name="service"
              className="w-full rounded-md border px-4 py-2"
              placeholder="Enter the service type"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="date"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Date Of Lead
            </label>
            <input
              type="date"
              id="date"
              name="date"
              className="w-full rounded-md border px-4 py-2"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="requirements"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Requirements
            </label>
            <textarea
              id="requirements"
              name="requirements"
              className="w-full rounded-md border px-4 py-2"
              placeholder="Enter your requirements"
            ></textarea>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="status"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              className="w-full rounded-md border px-4 py-2"
            >
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="in-progress">In Progress</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="notes"
              className="mb-2 text-sm font-bold text-gray-700"
            >
              Notes
            </label>
            <div className="relative">
              {editMode ? (
                <textarea
                  id="notes"
                  name="notes"
                  className="w-full rounded-md border px-4 py-2"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                ></textarea>
              ) : (
                <input
                  type="text"
                  id="notes"
                  name="notes"
                  className="w-full rounded-md border px-4 py-2"
                  value={notes}
                  readOnly
                />
              )}
              <button
                className="absolute top-2 right-2 text-gray-600"
                onClick={handleEditNotes}
              >
                <IoMdCreate />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Card>
  );
};

export default CustomerDetailsForm;
