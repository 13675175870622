import Card from "components/card";
import { useEffect, useState, useRef, useMemo } from "react";
import { useAdmin } from "../../../../../hooks/useAdmin";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const InvoiceData = (props) => {
  const { getCombinedInvoiceData, loading, invoiceCombinedData } = useAdmin();
  const [showAll, setShowAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleRecords, setVisibleRecords] = useState(5);

  const [showloading, setShowLoading] = useState(true);

  useState(false);

  useEffect(() => {
    getCombinedInvoiceData();
    setShowLoading(false);
  }, [loading]);
  console.log(invoiceCombinedData);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (invoiceCombinedData && Array.isArray(invoiceCombinedData)) {
      // Reverse the quotationCombinedData array and then set it to filteredData state
      const reversedData = [...invoiceCombinedData].reverse();
      setFilteredData(reversedData);
    }
  }, [invoiceCombinedData]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (showAll) {
      setVisibleRecords(invoiceCombinedData.length);
    } else {
      setVisibleRecords(5);
    }
  }, [showAll, invoiceCombinedData]);

  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const record = filteredData?.length
    ? filteredData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredData?.length
    ? Math.ceil(filteredData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };

  return (
    <>
      {showloading ? (
        <Skeleton count={10} height={40} />
      ) : (
        <Card extra={"w-full pb-10 h-full"}>
          <header className="relative flex items-center justify-between"></header>
          <div className="mt-4 flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="inline-block min-w-full p-1.5 align-middle">
                <div className="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y  dark:divide-gray-700">
                      <thead className="bg-gray-400 dark:bg-gray-700">
                        <tr className="whitespace-nowrap">
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Sr No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Package Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Total Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {record && record?.length >= 0
                          ? record?.map((data, index) => {
                              return (
                                <>
                                  <tr>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                      {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                      {data.cust_name}
                                    </td>

                                    <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {new Date(
                                      data.start_date
                                    ).toLocaleDateString("en-IN")}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                      {data.package_type}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                      {data.deficit}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          :   (
                            <tr>
                              <td colSpan="7" className="py-4 text-center">
                              No data found.
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!showAll && (
            <div className="mt-4 flex justify-center underline">
              <Link
                to="/admin/profile"
                className="text-red-500 hover:text-red-700"
                onClick={toggleShowAll}
              >
                View All
              </Link>
            </div>
          )}
        </Card>
      )}
    </>
  );
};

export default InvoiceData;
