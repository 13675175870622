import axios from "axios";
import { useAdmin } from "../../../hooks/useAdmin";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Validation } from "./components/Validation";
import { MdDeleteForever } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { toast } from "react-toastify";

const UpdateForm = ({ onClose, data }) => {
  const { updateLeadDetails, deleteFiles, newdocuments } = useAdmin();
  const { deletePath, setDeletePath } = useState(newdocuments || []);
  const [documents, setDocuments] = useState([]);
  const [id, setId] = useState(data._id);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [citites, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [adharPhoto, setAdharPhoto] = useState(null);
  const [adharBackPhoto, setAdharBackPhoto] = useState(null);
  const [panPhoto, setPanPhoto] = useState(null);
  const [passportBackPhoto, setPassportBackPhoto] = useState(null);
  const [passportFrontPhoto, setPassportFrontPhoto] = useState(null);
  const [licencePhoto, setLicencePhoto] = useState(null);
  const [birthCerificate, setBirthCerificate] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const baseURL = "https://otripserver.fresherszone.in";

  function openDocumentInNewTab(documentPath) {
    const url = `${baseURL}/${documentPath}`;
    window.open(url, "_blank");
  }
  useEffect(() => {
    if (data) {
      setUploadedDocuments(data.lead_documents);
    }
  }, []);

  useEffect(() => {
    if (newdocuments) {
      setUploadedDocuments(newdocuments);
    }
  }, [newdocuments]);

  const [formData, setFormData] = useState({
    id: data._id,
    name: "",
    country: "",
    state: "",
    city: "",
    firm_name: "",
    cust_gst: "",
    mobileNumber: "",
    note: "",
    email: "",
    dateOfLead: "",
  });

  const handleDeleteFile = (documentId, leadId) => {
    deleteFiles({ documentId, leadId });
  };

  useEffect(() => {
    if (data) {
      setFormData({
        id: data._id,
        name: data.name,
        country: data.country,
        state: data.state,
        city: data.city,
        firm_name: data.firm_name,
        cust_gst: data.cust_gst,
        mobileNumber: data.mobile_no,
        email: data.email,
        note: data.note,
        dateOfLead: data.dateOfLead,
      });
      const country = data.country;
      const state = data.state;
      getAllCountries();
      getStateFromCountries(country);
      getCititiesFromState(country, state);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (files) {
      const filesArray = Array.from(files);
      const maxSize = 4 * 1024 * 1024;
      const oversizedFiles = filesArray.filter((file) => file.size > maxSize);
      if (oversizedFiles.length > 0) {
        alert("Please add files less than 4 MB.");
        e.target.value = "";
        return;
      }
      setDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
    }
  };

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };
  const handleAdharPhotoChange = (event) => {
    setAdharPhoto(event.target.files[0]);
  };
  const handleAdharBackPhotoChange = (event) => {
    setAdharBackPhoto(event.target.files[0]);
  };
  const handlePanPhotoChange = (event) => {
    setPanPhoto(event.target.files[0]);
  };
  const handlePassportPhotoChange = (event) => {
    setPassportBackPhoto(event.target.files[0]);
  };
  const handleFrontPassportPhotoChange = (event) => {
    setPassportFrontPhoto(event.target.files[0]);
  };
  const handleLicencePhotoChange = (event) => {
    setLicencePhoto(event.target.files[0]);
  };
  const handleBirthPhotoChange = (event) => {
    setBirthCerificate(event.target.files[0]);
  };
  const handleClose = () => {
    onClose();
  };

  const { FullnameValidation, 
    NoteValidation, 
    FirmNameValidation,
    CustomerGSTnumberValidation
  } =
    Validation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("formData", JSON.stringify(formData));
    documents.forEach((file, index) => {
      data.append(`lead_documents`, file);
    });
    updateLeadDetails(data);
    // setInterval(() => {
    //   window.location.reload();
    // }, 1000);

    setFormData({
      name: "",
      note: "",
      email: "",
      phone: "",
      country: "",
      state: "",
      city: "",
      firm_name: "",
      cust_gst: "",
      package_type: "",
      dateOfLead: "",
    });
    onClose();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  function getAllCountries() {
    axios
      .get("https://countriesnow.space/api/v0.1/countries/positions")
      .then((result) => {
        setCountries(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getStateFromCountries(country) {
    setCountry(country);
    axios
      .post("https://countriesnow.space/api/v0.1/countries/states", {
        country: country,
      })
      .then((result) => {
        setStates(result.data.data.states);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getCititiesFromState(country, state) {
    setState(state);
    axios
      .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
        country: country,
        state: state,
      })
      .then((result) => {
        setCitites(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div className="relative">
        <div class="fixed inset-0 z-50 m-1 flex items-center justify-center overflow-scroll bg-gray-900 bg-opacity-50 pb-10 pt-40 backdrop-blur">
          <div
            className="z-50 w-full max-w-2xl rounded-xl bg-gray-200 p-6 shadow-xl"
            style={{ marginTop: "60px" }}
          >
            <span
              className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
              onClick={handleClose}
              style={{
                marginRight: "8px",
                cursor: "pointer",
                zIndex: "1100px",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <FaTimes size={24} />
            </span>
            <h2 className="mb-2 text-nowrap text-center text-lg font-semibold underline">
              Update Lead Form
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label htmlFor="name" className="mb-1 block">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer Name"
                  onKeyUp={FullnameValidation}
                  required
                />
                <span id="fullnamevalidation"></span>
              </div>

              <div className="mb-2">
                <label htmlFor="mobileNumber" className="mb-1 block">
                  Contact Number
                </label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer Contact Number"
                  required
                  maxLength={10}
                  minLength={10}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="email" className="mb-1 block">
                  Email ID
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer Email"
                  required
                />
              </div>
              <div className="mb-2">
                <label htmlFor="email" className="mb-1 block">
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer City"
                  required
                />
              </div>

              <div className="mb-2">
                <label htmlFor="firm_name" className="mb-1 block">
                  Firm Name
                </label>
                <input
                  type="text"
                  id="firm_name"
                  name="firm_name"
                  value={formData.firm_name}
                  onChange={handleChange}
                  // onKeyUp={FirmNameValidation}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Firm Name"
                  // required
                />
                <span id="FirmName"></span>
              </div>

              <div className="mb-2">
                <label htmlFor="cust_gst" className="mb-1 block">
                  Customer GST Number
                </label>
                <input
                  type="text"
                  id="cust_gst"
                  name="cust_gst"
                  value={formData.cust_gst}
                  onChange={handleChange}
                  onKeyUp={CustomerGSTnumberValidation}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer GST Number"
                  // style={{ textTransform: "uppercase" }}
                  // required
                />
                <span id="custgst"></span>
              </div>

              <div className="mb-2">
                <label htmlFor="note" className="mb-1 block">
                  Note
                </label>
                <input
                  type="text"
                  id="note"
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Add a Note"
                  onKeyUp={NoteValidation}
                  required
                />
                <span id="notevalidation"></span>
              </div>

              <div className="mb-2 flex flex-wrap">
                <label htmlFor="dateOfLead" className="mb-1 block">
                  Date of Lead
                </label>
                <input
                  type="text"
                  id="dateOfLead"
                  name="dateOfLead"
                  value={new Date(formData.dateOfLead).toLocaleDateString(
                    "en-IN"
                  )}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Date of Lead "
                  readOnly
                />
              </div>
              <div className="mb-2">
                <div className="mt-2 flex flex-col items-start space-y-2">
                  <h6>Select Document Type:</h6>
                  <select
                    className="w-full rounded-lg border-gray-800 px-3 py-2"
                    value={documentType}
                    onChange={handleDocumentTypeChange}
                  >
                    <option value="">Select Document Type</option>
                    {/* {formData.country === "India" && ( */}
                    <>
                      <option value="adhar">Aadhar Card</option>
                      <option value="pan">PAN Card</option>
                    </>
                    {/* )} */}
                    <option value="passport">Passport</option>
                    <option value="licence">License</option>
                    <option value="birthcertificate">Birth Certificate</option>
                  </select>
                  {documentType === "adhar" && (
                    <div className="y-2 flex flex-row space-x-6">
                      <div>
                        <h6 className="ml-4">Front Photo :</h6>
                        <input
                          type="file"
                          id="adhar_front_photo"
                          name="adhar_front_photo"
                          onChange={handleChange}
                          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                          className="cursor-pointer appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:bg-gray-500 focus:bg-gray-500"
                          style={{ maxWidth: "200px" }}
                        />
                      </div>
                      <div>
                        <h6 className="ml-4">Back Photo :</h6>
                        <input
                          type="file"
                          id="adhar_back_photo"
                          name="adhar_back_photo"
                          onChange={handleChange}
                          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                          className="cursor-pointer appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:bg-gray-500 focus:bg-gray-500"
                          style={{ maxWidth: "200px" }}
                        />
                      </div>
                    </div>
                  )}
                  {documentType === "passport" && (
                    <div className="y-2 flex flex-row space-x-6">
                      <div>
                        <h6 className="ml-4">Front Photo :</h6>
                        <input
                          type="file"
                          id="passport_front_photo"
                          name="passport_front_photo"
                          onChange={handleChange}
                          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                          className="cursor-pointer appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:bg-gray-500 focus:bg-gray-500"
                          style={{ maxWidth: "200px" }}
                        />
                      </div>
                      <div>
                        <h6 className="ml-4">Back Photo :</h6>
                        <input
                          type="file"
                          id="passport_back_photo"
                          name="passport_back_photo"
                          onChange={handleChange}
                          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                          className="cursor-pointer appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:bg-gray-500 focus:bg-gray-500"
                          style={{ maxWidth: "200px" }}
                        />
                      </div>
                    </div>
                  )}
                  {documentType === "pan" && (
                    <div>
                      <h6>PAN Card Photo:</h6>
                      <input
                        type="file"
                        className="cursor-pointer appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:bg-gray-500 focus:bg-gray-500"
                        id="pan_card_photo"
                        name="pan_card_photo"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                      />
                    </div>
                  )}
                  {documentType === "licence" && (
                    <div>
                      <h6>License Photo:</h6>
                      <input
                        type="file"
                        className="cursor-pointer appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:bg-gray-500 focus:bg-gray-500"
                        id="licence_photo"
                        name="licence_photo"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                      />
                    </div>
                  )}
                  {documentType === "birthcertificate" && (
                    <div>
                      <h6>Birth Certificate:</h6>
                      <input
                        type="file"
                        className="appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800"
                        id="birthcertificate"
                        name="birthcertificate"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                      />
                    </div>
                  )}
                </div>

                <div className="mt-3">
                  {uploadedDocuments &&
                    uploadedDocuments.map((file) => {
                      const filename = file.path.split("\\").pop();
                      return (
                        <div className="flex cursor-pointer space-x-5 ">
                          <li>{filename}</li>
                          <span className="mt-1">
                            <MdDeleteForever
                              className="text-red-500  hover:text-green-600 focus:text-green-600"
                              onClick={() =>
                                handleDeleteFile(file._id, data._id)
                              }
                            />
                          </span>
                          <span className="mt-1">
                            <GrView
                              className="text-green-600  hover:text-red-400 focus:text-red-400"
                              onClick={() => openDocumentInNewTab(file.path)}
                            />
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="m-auto rounded-lg bg-blue-500 px-4 py-2 text-white"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default UpdateForm;

{
  /* <div className="mb-2 flex flex-wrap gap-2">
                <div className="mb-2">
                  <label htmlFor="country" className="mb-1 block">
                    Country
                  </label>
                  <select
                    className="mt-2 block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-indigo-500 sm:text-sm"
                    style={{ width: "150px" }}
                    id="country"
                    name="country"
                    onChange={(e) => {
                      const selectedCountry = e.target.value;
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        country: selectedCountry,
                      }));
                      getStateFromCountries(selectedCountry);
                    }}
                    value={formData.country}
                    required // Added required attribute
                  >
                    <option value="">Select Country</option>{" "}
                    {/* Added value attribute */
}
//       {countries.map((country, index) => {
//         return (
//           <option
//             key={index}
//             value={country.name}
//             style={{ width: "150px" }}
//           >
//             {" "}
//             {/* Added key and value attributes */}
//             {country.name}
//           </option>
//         );
//       })}
//     </select>
//   </div>

//   <div className="mb-2">
//     <label htmlFor="state" className="mb-1 block">
//       State
//     </label>
//     <select
//       className="mt-2 block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 sm:text-sm"
//       style={{ width: "150px" }}
//       id="state"
//       name="state"
//       onChange={(e) => {
//         const selectedState = e.target.value;
//         setFormData((prevFormData) => ({
//           ...prevFormData,
//           state: selectedState,
//         }));
//         getCititiesFromState(country, selectedState);
//       }}
//       value={formData.state}
//     >
//       <option>Select States</option>
//       {states.map((state, index) => {
//         return <option id={index}>{state.name}</option>;
//       })}
//     </select>
//   </div>

//   <div className="mb-2">
//     <label htmlFor="city" className="mb-1 block">
//       City
//     </label>
//     <select
//       className="mt-2 block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 sm:text-sm"
//       style={{ width: "150px" }}
//       onChange={(e) => {
//         const selectedCity = e.target.value;
//         setFormData((prevFormData) => ({
//           ...prevFormData,
//           city: selectedCity,
//         }));
//         getCititiesFromState(e);
//       }}
//       value={formData.city}
//     >
//       <option>Select City</option>

//       {citites.map((city, index) => {
//         return <option id={index}>{city}</option>;
//       })}
//     </select>
//   </div>
// </div> */}
