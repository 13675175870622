import React, { useEffect } from "react";
import { useState } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { useAdmin } from "../../../hooks/useAdmin";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";

export default function UpdateDomestic({ data, onClose }) {
  const [id, setId] = useState(data._id);
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [totaldiscount, setTotaldiscount] = useState("");
  const [applygst, setApplygst] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const { updateDomesticInvoice } = useAdmin();
  const [formChange, setFormChange] = useState(false);
  // const [disableSubmit, setdisableSubmit] = useState('');

  const [formData, setFormData] = useState({
    quotation_type: "",
    cust_name: "",
    start_date: "",
    end_date: "",
    proposal_title: "",
    budget: "",
    follow_up_date: "",
    no_of_adult: "",
    no_of_children: "",
    package_type: "",
    destination: "",
    markup: "",
    travel_date: "",
    duration: "",
    net_cost: "",
    gross_cost: "",
    discount: "",
    gst: "",
    gstamount:"",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
    cancellation_charges: "",
    refund_amount: "",
    date_time: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        quotation_type: data.quotation_type,
        cust_name: data.cust_name,
        start_date: data.start_date,
        end_date: data.end_date,
        proposal_title: data.proposal_title,
        budget: data.budget,
        follow_up_date: data.follow_up_date,
        no_of_adult: data.no_of_adult,
        no_of_children: data.no_of_children,
        package_type: data.package_type,
        destination: data.destination,
        markup: data.markup,
        travel_date: data.travel_date,
        duration: data.duration,
        net_cost: data.net_cost,
        gross_cost: data.gross_cost,
        discount: data.discount,
        gst: data.gst,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        deficit: data.deficit,
        cancellation_charges: data.cancellation_charges,
        refund_amount: data.refund_amount,
        date_time: data.date_time,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormChange(true);
  };

  useEffect(() => {
    const netCost = parseFloat(formData.net_cost) || 0;
    const markup = parseFloat(formData.markup) || 0;
    const discount = parseFloat(formData.discount) || 0;
    const actualCollection = parseFloat(formData.actual_collection) || 0;
    const gst = parseFloat(formData.gst) || 0;

    if(gst===5)
      {
        var add=(netCost+markup);
        var total=add+(add*5/100);
        var gst5 = add*5/100;
      }
      else{
        var total=netCost+markup+(markup*18/100);
        var gst18 = markup*18/100;
      }
      const grossCost= total;
    const totalWithDiscount = grossCost - discount;
    const idealCollection = totalWithDiscount ;
    const deficit = (idealCollection - actualCollection).toFixed(2);
    const totalgst = gst5 || gst18 || 0;


    setFormData((prevFormData) => ({
      ...prevFormData,
      gross_cost: grossCost.toFixed(2),
      ideal_collection: idealCollection.toFixed(2),
      gstamount: totalgst,
      deficit,
    }));
  }, [
    formData.net_cost,
    formData.markup,
    formData.discount,
    formData.gst,
    formData.actual_collection,
  ]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   updateDomesticInvoice({ id, formData });
  //   setInterval(() => {
  //     window.location.reload();
  //   }, 1000);
  // };

  const handleSubmit = async (e) => {
    // setdisableSubmit(true)
    e.preventDefault();

    // Check if all mandatory fields are filled
    const requiredFields = [
      "quotation_type",
      "cust_name",
      "start_date",
      "end_date",
      "proposal_title",
      "budget",
      "follow_up_date",
      "no_of_adult",
      "no_of_children",
      "package_type",
      "destination",
      "markup",
      "travel_date",
      "duration",
      "net_cost",
      "gross_cost",
      // "discount",
      "gst",
      "ideal_collection",
      // "actual_collection",
      "deficit",
      // "cancellation_charges",
      // "refund_amount",
      "date_time",
    ];
    const isValid = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (!isValid) {
      toast.error("Please fill all mandatory fields");
      // setdisableSubmit(false)
      return;
    }

    try {
      await updateDomesticInvoice({ id, formData });
      window.location.reload();
    } catch (error) {
      console.error("Error updating quotation:", error);
      toast.error("Failed to update quotation");
    }
  };

  //Validation Code
  const {
    LastnameValidation,
    DurationValidation,
    NetVisaCostValidation,
    MarkUpValidation,
    ActualCollectionValidation,
    DiscountValidation,
    GrrosCostValidation,
    CancelationChargesValidation,
    RefundAmountValidation,
  } = Validation();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <div className="relative">
        <div class="fixed inset-0 z-50 m-1  items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 pb-10 pl-5 pr-5 pt-10 backdrop-blur-0 lg:pl-10 lg:pr-10">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <div>
                <span
                  className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    zIndex: "1100px",
                  }}
                >
                  {" "}
                  <FaTimes size={24} />{" "}
                </span>
                <h2 className="mb-4 text-center text-lg font-semibold underline">
                  Update Domestic Package Invoice
                </h2>
              </div>
              <div class="border-b border-gray-900/10 pb-12">
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label
                      for="destination"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="custname"
                        id="custname"
                        autoComplete="given-name"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="destination"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Destination <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="destination"
                        id="destination"
                        autoComplete="given-name"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.destination}
                        onKeyUp={LastnameValidation}
                      />
                      <span id="last"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="travel_date"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Travel Date <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="date"
                        name="travel_date"
                        id="travel_date"
                        autocomplete="travel_date"
                        class="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 
                shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        // value={formData.travel_date.slice(0, 10)}
                        value={
                          formData.travel_date
                            ? formData.travel_date.split("T")[0]
                            : ""
                        }
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label
                      for="duration"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Duration(In Days){" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="duration"
                        name="duration"
                        type="text"
                        autocomplete="duration"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.duration}
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gst type"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      GST <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <select
                        id="gst"
                        name="gst"
                        value={formData.gst}
                        onChange={handleInputChange}
                        required
                        className="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Select Package</option>
                        <option value="18">With 18% GST</option>
                        <option value="5">With 5% GST</option>
                      </select>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="netCost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="net_cost"
                        id="netCost"
                        autocomplete="netCost"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.net_cost}
                        onKeyUp={NetVisaCostValidation}
                      />
                      <span id="netVisaCostValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="markup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="markup"
                        id="markup"
                        autocomplete="markUp"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.markup}
                        onKeyUp={DurationValidation}
                      />
                      <span id="Duration"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gross_cost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Gross Cost <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="gross_cost"
                        id="gross_cost"
                        autoComplete="gross_cost"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gross_cost}
                        onKeyUp={GrrosCostValidation}
                        readOnly
                      />
                      <span id="grrosCostValidation"></span>
                    </div>
                  </div>

                  {/* <div class="sm:col-span-3">
                    <label
                      for="discount"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Discount <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="discount"
                        id="discount"
                        autocomplete="discount"
                        class="text-grey-500 ring-grey-300 focus:ring-grey-600 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm 
                ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.discount}
                        onKeyUp={DiscountValidation}
                      />
                      <span id="discountValidation"></span>
                    </div>
                  </div> */}

               
                  <div class="sm:col-span-3">
                    <label
                      for="actualCollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="ideal_collection"
                        id="idealCollection"
                        autocomplete="idealCollection"
                        class="text-grey-500 ring-grey-300 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 
                ring-inset focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        readOnly
                      />
                    </div>
                  </div>

                  {/* <div class="sm:col-span-3">
                    <label
                      for="actualCollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Actual Collection{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="actual_collection"
                        id="actualCollection"
                        autocomplete="actualCollection"
                        class="text-grey-500 ring-grey-300 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 
                ring-inset focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.actual_collection}
                        onKeyUp={ActualCollectionValidation}
                      />
                      <span id="actualCollectionValidation"></span>
                    </div>
                  </div> */}
                  <div className="sm:col-span-3">
                    <label
                      for="tls-cost"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit
                      <sup classNameName="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="deficit"
                        id="deficit"
                        autocomplete="deficit"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        readOnly
                      />
                    </div>
                  </div>

                  {/* <div class="sm:col-span-3">
                    <label
                      for="cancellation charges"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Cancellation Charges{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="cancellation_charges"
                        id="cancellationCharges"
                        autocomplete="cancellationCharges"
                        class="text-grey-500 ring-grey-300 focus:ring-grey-600 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm 
                ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cancellation_charges}
                        onKeyUp={CancelationChargesValidation}
                      />
                      <span id="cancelationChargesValidation"></span>
                    </div>
                  </div> */}

                  {/* <div class="sm:col-span-3">
                    <label
                      for="refund amount"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Refund Amount <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="refund_amount"
                        id="refundamount"
                        autocomplete="refundamount"
                        class="text-grey-500 ring-grey-30 focus:ring-grey-600 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm 
                ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.refund_amount}
                        onKeyUp={RefundAmountValidation}
                      />
                      <span id="refundAmountValidation"></span>
                    </div>
                  </div> */}
                </div>
              </div>
              <div class="mt-4 flex place-content-center">
                <button
                  class="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  onClick={handleSubmit}
                  // disabled={disableSubmit}
                >
                  Update
                </button>
                {/* <button class="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                  onClick={handleClose}
                >
                  Back
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
