// import CreateQuotation from "./CreateQuotation";
import Card from "components/card";
import { useEffect, useState, useRef } from "react";
import { IoFilter } from "react-icons/io5";
import { useMemo } from "react";
import { PiDotsThreeBold } from "react-icons/pi";
import { useAdmin } from "../../../../hooks/useAdmin";
import UpdateInternational from "views/auth/update_invoice/UpdateInternational";
import UpdateDomestic from "views/auth/update_invoice/UpdateDomestic";
import UpdateHotelBook from "views/auth/update_invoice/UpdateHotelBook";
import UpdateVisa from "views/auth/update_invoice/UpdateVisa";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UpdateInsurance from "views/auth/update_invoice/UpdateInsurance";
import UpdateFlightOneWay from "views/auth/update_invoice/UpdateFlightOneWay";
import UpdateFlightRoundTrip from "views/auth/update_invoice/UpdateFlightRoundTrip";
import UpdateFlightMultiCity from "views/auth/update_invoice/UpdateFlightMultiCity";
import { useReactToPrint } from "react-to-print";
import { data } from "autoprefixer";
import Remark from "./Remark";
import ViewRemark from "./ViewRemark";
import AddFile from "./AddFile";
import DownloadFile from "./DownloadFile";

const InvoiceTable = (props) => {
  const { getCombinedInvoiceData, loading, invoiceCombinedData } = useAdmin();
  const [updateDomesticInvoice, setUpdateDomesticInvoice] = useState(false);
  const [updateHotelBooking, setUpdateHotelBooking] = useState(false);
  const [showupdateVisa, setUpdateVisa] = useState(false);
  const [showloading, setShowLoading] = useState(true);
  const [UpdateInvoiceInsurance, setUpdateInvoiceInsurance] = useState(false);
  const [UpdateInvoiceFlightOneWay, setUpdateInvoiceFlightOneWay] =
    useState(false);
  const [UpdateInvoiceFlightRound, setUpdateInvoiceFlightRound] =
    useState(false);
  const [UpdateInvoiceFlightMultiCity, setUpdateInvoiceFlightMultiCity] =
    useState(false);
  const [isRemarkFormVisible, setIsRemarkFormVisible] = useState(false);
  const [isViewRemark, setIsViewRemark] = useState(false);
  const menuRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    getCombinedInvoiceData();
    // setTimeout(() => {
    //   setShowLoading(false);
    // }, 2000);
  }, [loading]);

  // useEffect(() => {
  //   getCombinedInvoiceData();
  //   // setShowLoading(false);
  // }, [loading]);

  useEffect(() => {
    if (invoiceCombinedData && Array.isArray(invoiceCombinedData)) {
      const sortedData = invoiceCombinedData.sort((a, b) => {
        return new Date(b.date_time) - new Date(a.date_time);
      });

      setFilteredData([...sortedData]);
    }
  }, [invoiceCombinedData]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [data1, setdata1] = useState();

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const [updateInternationalInvoice, setUpdateInternationalInvoice] =
    useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [formData, setFormData] = useState({});

  const initialFormData = {};

  const toggleDropdown = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (e, data) => {
    if (e === "Edit") {
      setdata1(data);
      // console.log(data);
      setFormData(initialFormData);
      if (data.package_type === "International") {
        setUpdateInternationalInvoice(true);
      } else if (data.package_type === "Domestic") {
        setUpdateDomesticInvoice(true);
      } else if (data.package_type === "HotelBook") {
        setUpdateHotelBooking(true);
      } else if (data.package_type === "Visa") {
        setUpdateVisa(true);
      } else if (data.package_type === "Insurance") {
        setUpdateInvoiceInsurance(true);
      } else if (data.package_type === "Flight") {
        if (data.trip_type === "One Way") {
          setUpdateInvoiceFlightOneWay(true);
        } else if (data.trip_type === "Round Trip") {
          setUpdateInvoiceFlightRound(true);
        } else if (data.trip_type === "MultiCity") {
          setUpdateInvoiceFlightMultiCity(true);
        }
      } else {
        setFormData({});
      }
    } else if (e === "Remark") {
      setIsRemarkFormVisible(true);
      setdata1(data);
      setFormData(initialFormData);
    } else if (e === "View Remark") {
      setIsViewRemark(true);
      setdata1(data);
      setFormData(initialFormData);
    }
  };

  const [adddocument, setAdddocument] = useState(null);
  const [isvisible, setIsvisible] = useState(false);

  const handleAddFile = (id, quotationType) => {
    setAdddocument({ id, quotationType });
    // setIsvisible(true);
  };

  
  useEffect(() => {
    if (adddocument !== undefined) {
      setIsvisible(true);
    }
  }, [adddocument]);

  
  const invoice="invoice";
  const [fetchdocument, setFetchDocument] = useState(null);
  const [visibledocument, setVisibleDocument] = useState(false);

  const hadnleDownloadDocument = (invoiceid, invoiceType) => {
    setFetchDocument({ invoiceid, invoiceType,invoice});
    setVisibleDocument(true);
  };
  const closePopupForm = () => {
    setVisibleDocument(false);
    setFetchDocument(true);
  };

  const closePopup = () => {
    setIsvisible(false);
    setAdddocument(null);
  };

  const handleOptionprint = (data) => {
    if (data?.package_type === "Visa") {
      navigate(`/admin/visa-invoice?id=${data?._id}`);
    } else if (data?.package_type === "Insurance") {
      navigate(`/admin/insurance-invoice?id=${data?._id}`);
    } else if (data?.package_type === "International") {
      navigate(`/admin/international-invoice?id=${data?._id}`);
    } else if (data?.package_type === "Domestic") {
      navigate(`/admin/domestic-invoice?id=${data?._id}`);
    } else if (data?.package_type === "HotelBook") {
      navigate(`/admin/hotelbook-invoice?id=${data?._id}`);
    } else if (data?.package_type === "Flight") {
      if (data?.trip_type === "One Way") {
        navigate(`/admin/onewaytrip-invoice?id=${data?._id}`);
      } else if (data?.trip_type === "Round Trip") {
        navigate(`/admin/roundtrip-invoice?id=${data?._id}`);
      } else if (data?.trip_type === "MultiCity") {
        navigate(`/admin/miticitytrip-invoice?id=${data?._id}`);
      }
    }
  };

  const closeNewLeadForm = () => {
    setUpdateInternationalInvoice(false);
    setUpdateHotelBooking(false);
    setUpdateDomesticInvoice(false);
    setUpdateVisa(false);
    setUpdateInvoiceInsurance(false);
    setUpdateInvoiceFlightOneWay(false);
    setUpdateInvoiceFlightRound(false);
    setUpdateInvoiceFlightMultiCity(false);
    setIsRemarkFormVisible(false);
    setIsViewRemark(false);
  };

  //  Get Data of Lead
  const [selectedValue, setSelectedValue] = useState();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(invoiceCombinedData);
  }, [invoiceCombinedData]);

  // filteredData
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    filterData(e.target.value);
  };

  const filterData = (range) => {
    // console.log(range);
    const currentDate = new Date();
    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1);

    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(currentDate.getDate() - 7);

    const oneMonthAgo = new Date(currentDate);
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    switch (range) {
      case "day":
        const filteredOneDayAgo = invoiceCombinedData?.filter((item) => {
          const itemDate = new Date(item.date_time);
          return itemDate >= oneDayAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneDayAgo);
        break;

      case "week":
        const filteredOneWeekAgo = invoiceCombinedData.filter((item) => {
          const itemDate = new Date(item.date_time);
          return itemDate >= oneWeekAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneWeekAgo);
        break;
      case "month":
        const filteredOneMonthAgo = invoiceCombinedData.filter((item) => {
          const itemDate = new Date(item.date_time);
          return itemDate >= oneMonthAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneMonthAgo);
        break;
      case "year":
        const filteredOneYearAgo = invoiceCombinedData?.filter((item) => {
          const itemDate = new Date(item.date_time);
          return itemDate >= oneYearAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneYearAgo);
        break;

      default:
        // No filter, set all data
        setFilteredData(invoiceCombinedData);
        return;
    }
  };

  // pagination
  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const record = filteredData?.length
    ? filteredData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredData?.length
    ? Math.ceil(filteredData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(nPages);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const filteredResults = invoiceCombinedData.filter(
      (item) =>
        item?.invoice_number
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item?.cust_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  // Page Print

  const [isPrinting, setIsPrinting] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice",
    onAfterPrint: () => {
      if (data.package_type === "International") {
        getCombinedInvoiceData(true);
      } else if (data.package_type === "Domestic") {
        getCombinedInvoiceData(true);
      } else if (data.package_type === "HotelBook") {
        getCombinedInvoiceData(true);
      } else if (data.package_type === "Visa") {
        getCombinedInvoiceData(true);
      } else if (data.package_type === "Insurance") {
        getCombinedInvoiceData(true);
      } else if (data.package_type === "Flight") {
        if (data.trip_type === "One Way") {
          getCombinedInvoiceData(true);
        } else if (data.trip_type === "Round Trip") {
          getCombinedInvoiceData(true);
        } else if (data.package_type === "MultiCity") {
          getCombinedInvoiceData(true);
        } else {
          getCombinedInvoiceData({});
        }
      }
      setIsPrinting(false);
    },
  });

  // Data Fetch

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting, handlePrint]);

  // console.log(invoiceCombinedData);
  return (
    <>
      {!loading ? (
        <Skeleton count={5} height={40} />
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          {/* <div
            ref={componentRef}
            className={`overflow border-gray h-full w-full border ${
              isPrinting ? "print-page" : ""
            }`}
            style={{ padding: "20px 20px" }}
          ></div> */}
          <header className="relative flex items-center justify-between"></header>
          <div className="mt-4 flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="inline-block min-w-full p-1.5 align-middle">
                <div className="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                  <div className="px-4 py-3">
                    <div className="flex items-center justify-between">
                      <div className="relative min-w-96">
                        <label className="sr-only">Search</label>
                        <input
                          type="text"
                          name="hs-table-with-pagination-search"
                          id="hs-table-with-pagination-search"
                          className="dark:bg-slate-900 border-black block w-full rounded-lg border px-4 py-2 ps-10 text-sm"
                          placeholder="Search By Name/Invoice No."
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                          <svg
                            className="size-4 text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                          </svg>
                        </div>
                      </div>
                      <div className="relative mr-20">
                        <div className="-right-1/2 mx-auto translate-x-1/2 transform rounded-lg bg-white py-1 ">
                          <select
                            id="exampleSelect"
                            value={selectedValue}
                            onChange={handleChange}
                            className="mt-1 rounded-md border border-gray-300 p-2 focus:border-blue-300 focus:outline-none focus:ring"
                          >
                            <option value="">Filter</option>
                            <option value="day">One day ago</option>
                            <option value="week">One week ago</option>
                            <option value="month">One month ago</option>
                            <option value="year">One year ago</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {updateInternationalInvoice ? (
                    <UpdateInternational
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    ""
                  )}
                  {updateDomesticInvoice ? (
                    <UpdateDomestic onClose={closeNewLeadForm} data={data1} />
                  ) : (
                    ""
                  )}
                  {updateHotelBooking ? (
                    <UpdateHotelBook onClose={closeNewLeadForm} data={data1} />
                  ) : (
                    ""
                  )}
                  {showupdateVisa ? (
                    <UpdateVisa onClose={closeNewLeadForm} data={data1} />
                  ) : (
                    ""
                  )}
                  {UpdateInvoiceInsurance ? (
                    <UpdateInsurance onClose={closeNewLeadForm} data={data1} />
                  ) : (
                    ""
                  )}
                  {UpdateInvoiceFlightOneWay ? (
                    <UpdateFlightOneWay
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    ""
                  )}
                  {UpdateInvoiceFlightRound ? (
                    <UpdateFlightRoundTrip
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    ""
                  )}
                  {UpdateInvoiceFlightMultiCity ? (
                    <UpdateFlightMultiCity
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    ""
                  )}
                  {isRemarkFormVisible ? (
                    <Remark onClose={closeNewLeadForm} data={data1} />
                  ) : (
                    ""
                  )}
                  {isViewRemark ? (
                    <ViewRemark onClose={closeNewLeadForm} data={data1} />
                  ) : (
                    ""
                  )}
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-400 dark:bg-gray-700">
                        <tr className="whitespace-nowrap">
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Sr No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Invoice Number
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Package Type
                          </th>
                          {/* <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Total Amount
                          </th> */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Date Of Invoice
                          </th>
                          {/* <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Payment Status
                          </th> */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Print
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {record && record?.length >= 0 ? (
                          record?.map((data, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {index +
                                      1 +
                                      (currentPage - 1) * recordPerPage}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.cust_name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.invoice_number}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.package_type}
                                  </td>

                                  {/* <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.deficit}
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {new Date(
                                      data.date_time
                                    ).toLocaleDateString("en-IN")}
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.status}
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    <button
                                      onClick={() => handleOptionprint(data)}
                                      className="rounded-md bg-blue-500 px-3 py-1 text-white hover:bg-blue-700"
                                    >
                                      Print
                                    </button>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium">
                                    <button
                                      onClick={() => toggleDropdown(index)}
                                      className="text-black mt-1 items-center px-3 py-2 text-center sm:ml-3"
                                    >
                                      <PiDotsThreeBold />
                                    </button>
                                    {openIndex === index && (
                                      <div
                                        ref={menuRef}
                                        className="origin-top-center ring-black w-30 absolute right-0 mt-2 rounded-md bg-white shadow-lg ring-1 ring-opacity-5"
                                      >
                                        <div
                                          className="py-1"
                                          role="menu"
                                          aria-orientation="vertical"
                                          aria-labelledby="options-menu"
                                        >
                                          <button
                                            onClick={() =>
                                              handleOptionClick("Edit", data)
                                            }
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Edit
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleOptionClick("Remark", data)
                                            }
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Add Remark
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleOptionClick(
                                                "View Remark",
                                                data
                                              )
                                            }
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            View Remark
                                          </button>
                                          <button
                                            onClick={() => {
                                              handleAddFile(
                                                data._id,
                                                data.package_type
                                              );
                                            }}
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Add File
                                          </button>
                                          <button
                                            onClick={() => {
                                              hadnleDownloadDocument(
                                                data._id,
                                                data.package_type
                                              );
                                            }}
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Download File
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" className="py-4 text-center">
                              No data found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      <nav className="flex items-center justify-center -space-x-px rounded-md shadow-sm">
        <ul className="flex items-center justify-center border-t px-4 py-3 sm:px-6">
          {currentPage !== 1 && currentPage !== 2 && (
            <>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={goToFirstPage}
                  aria-label="First"
                >
                  First
                </Link>
              </li>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={prePage}
                  aria-label="Previous"
                >
                  Previous
                </Link>
              </li>
            </>
          )}
          {numbers.map(
            (n, i) =>
              (n === currentPage ||
                n === currentPage - 1 ||
                n === currentPage + 1) && (
                <li
                  className={`page-item ${currentPage === n ? "active" : ""}`}
                  key={i}
                >
                  <Link
                    href="#"
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                      currentPage === n ? "bg-gray-200" : ""
                    }`}
                    onClick={() => changeCPage(n)}
                  >
                    {n}
                  </Link>
                </li>
              )
          )}
          {currentPage !== nPages && (
            <>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={nextPage}
                  aria-label="Next"
                >
                  Next
                </Link>
              </li>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={goToLastPage}
                  aria-label="Last"
                >
                  Last
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      {isvisible && <AddFile data={adddocument} onClose={closePopup} />}
      {visibledocument && (
        <DownloadFile data={fetchdocument} onClose={closePopupForm} />
      )}
    </>
  );
};

export default InvoiceTable;
