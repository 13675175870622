import { useEffect, useState } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { useAdmin } from "../../../hooks/useAdmin";
import { toast } from "react-toastify";
export default function Visa({ parentData, clientId, name, onClose }) {
  const { AddVisaQuotation } = useAdmin();
  const [disableSubmit, setdisableSubmit] = useState(false)


  const [formData, setFormData] = useState({
    visa_type: "",
    no_of_person: "",
    net_cost: "",
    mark_up: "",
    gst: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ideal_collection: idealcost,
      gst: withgst,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  // Reset form data to initial state

  const handleDiscard = () => {
    setFormData({
      visa_type: "",
      vendorname: "",
      no_of_person: "",
      net_cost: "",
      mark_up: "",
      gst: "",
      ideal_collection: "",
      actual_collection: "",
      deficit: "",
    });
  };

  //Validation Code
  const {
    NetVisaCostValidation,
    MarkupValidation,
    PersonNumberValidation,
    ActualCollectionValidation,
    VendornameValidation,
  } = Validation();

  //form close
  const [showForm, setShowForm] = useState(true);
  const handleClose = () => {
    onClose();
    // setShowForm(false);
    setShowForm(!showForm);
  };

  function validateForm(){
    console.log(formData);
    if( 
    formData.visa_type!== ""&&
    formData.no_of_person!== ""&&
    formData.net_cost!== ""&&
    formData.mark_up!== ""&&
    formData.gst!== ""&&
    formData.ideal_collection!== ""&&
    formData.deficit!== ""){
     return true;
    }
    return false;
  }
  const handleSubmit =async (e) => {
    setdisableSubmit(true)

    e.preventDefault();
    if(validateForm()===true)
    {
     await AddVisaQuotation({ formData, parentData, clientId, name });
    setInterval(() => {
      window.location.reload();
    }, 1000);
    }else{
      toast.error("Please fill manadatory field")
      setdisableSubmit(false); // Enable the submit button
     }
  };

  return (
    <>
      {showForm ? (
        <div className="relative">
          {/* <div class="fixed inset-0 z-50 m-1  items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0"> */}
            <div>
              <div className="z-50 rounded-bl-lg rounded-br-lg bg-gray-200 p-5 shadow-xl">
                {/* <div className="flex items-center justify-between">
                  <span
                    className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-blue-600"
                    onClick={() => handleBack(false)}
                    style={{
                      marginRight: "8px",
                      cursor: "pointer",
                      zIndex: "1100px",
                    }}
                  >
                    {" "}
                    <IoIosArrowBack size={24} />{" "}
                  </span> */}
                <span
                  className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    zIndex: "1100px",
                  }}
                >
                  {" "}
                  <FaTimes size={24} />{" "}
                </span>
                <h2 className="mb-4 text-center text-lg font-semibold underline">
                  Visa
                </h2>

                {/* </div> */}

                <form onSubmit={handleSubmit}>
                  <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                      <label
                        for="typeofvisa"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Type of Visa
                      </label>
                      <div class="mt-2">
                        <select
                          id="visa_type"
                          name="visa_type"
                          autoComplete="typeofvisa"
                          value={formData.visa_type}
                          onChange={handleInputChange}
                          required
                          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option>Select</option>
                          <option>Tourist</option>
                          <option>Student</option>
                          <option>Work</option>
                          <option>Business</option>
                        </select>
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        for="vendor-name"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        Vendor Name <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="vendorname"
                          id="vendorname"
                          autocomplete="vendorname"
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.vendorname}
                          // onKeyUp={VendornameValidation}
                          required
                        />
                        <span id="vendornameValidation"></span>
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="numofperson"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Number of Person{" "}
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name="no_of_person"
                          id="no_of_person"
                          autocomplete="numofperson"
                          class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.no_of_person}
                        onKeyUp={PersonNumberValidation}
                        />
                        <span id="PersonNumberValidation"></span>
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="netcost"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Net Cost <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name="net_cost"
                          id="net_cost"
                          autocomplete="net_cost"
                          class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.net_cost}
                          onKeyUp={NetVisaCostValidation}
                        />
                        <span id="netVisaCostValidation"></span>
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="markup"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Mark Up <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name="mark_up"
                          id="mark_up"
                          autocomplete="markup"
                          class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.mark_up}
                          onKeyUp={MarkupValidation}
                        />
                        <span id="MarkupValidation"></span>
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="gstcost"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        GST 18% on Markup
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name="gst"
                          id="gst"
                          autocomplete="gst"
                          class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.gst}
                          readOnly
                          required
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="idealcollection"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Ideal Collection{" "}
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name="ideal_collection"
                          id="ideal_collection"
                          autocomplete="ideal_collection"
                          class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.ideal_collection}
                          readOnly
                        />
                      </div>
                    </div>
                    {/* <div class="sm:col-span-3">
                      <label
                        for="actualcollection"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Actual Collection{" "}
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name="actual_collection"
                          id="actual_collection"
                          autocomplete="actualcollection"
                          class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.actual_collection}
                          onKeyUp={ActualCollectionValidation}
                          readOnly
                        />
                        <span id="actualCollectionValidation"></span>
                      </div>
                    </div> */}
                    <div class="sm:col-span-3">
                      <label
                        for="deficit"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Deficit <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name="deficit"
                          id="deficit"
                          autocomplete="deficit"
                          class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.deficit}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex flex-wrap justify-center items-center">
                    <button
                      className="rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                      onClick={handleSubmit}
                      disabled={disableSubmit}
                    >
                      Save
                    </button>
                    <div className="mx-4 sm:mx-4"></div>
                    <button
                      type="button"
                      onClick={handleDiscard}
                      

                      className="rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                    >
                      Discard
                    </button>
                  </div>
                </form>
              </div>
            </div>
          {/* </div> */}
        </div>
      ) : null}
    </>
  );
}
