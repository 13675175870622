import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import CustomerDetailsForm from "views/admin/marketplace/CustomerDetailsForm";
import SignIn from "views/auth/SignIn/SignIn";
import PasswordReset from "views/auth/SignIn/PasswordReset";
import ForgetPass from "views/auth/SignIn/ForgetPass";
import ResetPass from "views/auth/SignIn/ResetPass";
import ViewRemark from "views/admin/marketplace/components/ViewRemark";

// Invoive Print

import InternationalInvoicePrint from "views/auth/print_pages/InternationalInvoicePrint";
import HotelBookInvoicePrint from "views/auth/print_pages/HotelBookInvoicePrint";
import VisaInvoicePrint from "views/auth/print_pages/VisaInvoicePrint";
import InsuranceInvoicePrint from "views/auth/print_pages/InsuranceInvoicePrint";
import OneWayInvoicePrint from "views/auth/print_pages/OneWayInvoicePrint";
import RoundTripInvoicePrint from "views/auth/print_pages/RoundTripInvoicePrint";
import MultiCityInvoicePrint from "views/auth/print_pages/MultiCityInvoicePrint";
import DomesticInvoicePrints from "views/auth/print_pages/DomesticInvoicePrints";

// Quotation Print

import HotelBookQuotPrint from "views/auth/print_pages/HotelBookQuotPrint";
import VisaQuotPrint from "views/auth/print_pages/VisaQuotPrint";
import InsuranceQuotPrint from "views/auth/print_pages/InsuranceQuotPrint";
import OneWayQuotPrint from "views/auth/print_pages/OneWayQuotPrint";
import RoundTripQuotPrint from "views/auth/print_pages/RoundTripQuotPrint";
import MultiCityQuotPrint from "views/auth/print_pages/MultiCityQuotPrint";
import InternationalQuotationPrint from "views/auth/print_pages/InternationalQuotationPrint";
import DomesticQuotPrint from "views/auth/print_pages/DomesticQuotPrint";
import LogOut from "views/auth/SignOut/LogOut";
import { AuthProvider } from "store/auth/AuthProvider";
import { useDispatch } from "react-redux";
import { login } from "store/auth/reducer";

const App = () => {
  useEffect(() => {
    setInterval(() => {
      const storedData = localStorage.getItem('timeSpan');
      if (storedData) {
        const { timeSpan } = JSON.parse(storedData);
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - timeSpan;
        if (timeDifference > 24 * 60 * 60 * 1000) {
          
          localStorage.removeItem('token');
          localStorage.removeItem('timeSpan');
        }
      }
    }, 6000); 
  }, [])

  const token = localStorage.getItem('token')
  const dispatch = useDispatch()
  if (token) {
    dispatch(login())
  }
  return (
    <>
      <ToastContainer hideProgressBar={true} autoClose={"3000"} />
      <Routes>
        <Route path="auth/*" element={<AuthProvider><AuthLayout /></AuthProvider>} />
        <Route path="admin/*" element={<AuthProvider><AdminLayout /></AuthProvider>} />
        <Route path="rtl/*" element={<AuthProvider><RtlLayout /></AuthProvider>} />
        <Route path="/cutomer-details" element={<CustomerDetailsForm />} />
        <Route path="/" element={<SignIn />} />
        <Route path="/logout" element={<LogOut />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/forget-pass" element={<ForgetPass />} />
        <Route path="/forget-pass/reset-pass" element={<ResetPass />} />
        <Route path="/view-remark" element={<ViewRemark />} />
        {/* <Route path="/forgotPassword/:id/:token" element={<ForgotPassword />} /> */}
        {/* Invoice Print Routes */}

        <Route
          path="/admin/international-invoice"
          element={<InternationalInvoicePrint />}
        />
        <Route
          path="/admin/domestic-invoice"
          element={<DomesticInvoicePrints />}
        />
        <Route
          path="/admin/hotelbook-invoice"
          element={<HotelBookInvoicePrint />}
        />
        <Route path="/admin/visa-invoice" element={<VisaInvoicePrint />} />
        <Route
          path="/admin/insurance-invoice"
          element={<InsuranceInvoicePrint />}
        />
        <Route
          path="/admin/onewaytrip-invoice"
          element={<OneWayInvoicePrint />}
        />
        <Route
          path="/admin/roundtrip-invoice"
          element={<RoundTripInvoicePrint />}
        />
        <Route
          path="/admin/miticitytrip-invoice"
          element={<MultiCityInvoicePrint />}
        />

        {/* Quotation Print Routes */}

        <Route
          path="/admin/international-quotation"
          element={<InternationalQuotationPrint />}
        />
        <Route
          path="/admin/domestic-quotation"
          element={<DomesticQuotPrint />}
        />
        <Route
          path="/admin/hotelbook-quotation"
          element={<HotelBookQuotPrint />}
        />
        <Route path="/admin/visa-quotation" element={<VisaQuotPrint />} />
        <Route
          path="/admin/insurance-quotation"
          element={<InsuranceQuotPrint />}
        />
        <Route
          path="/admin/onewaytrip-quotation"
          element={<OneWayQuotPrint />}
        />
        <Route
          path="/admin/roundtrip-quotation"
          element={<RoundTripQuotPrint />}
        />
        <Route
          path="/admin/multicitytrp-quotation"
          element={<MultiCityQuotPrint />}
        />
      </Routes>
    </>
  );
};

export default App;
