import { useEffect, useState } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { toast } from "react-toastify";
export default function UpdateVisaQuotation({ data, onClose }) {
  const { updateVisaQuotation } = useAdmin();

  const [id, setId] = useState(data._id);
  const [disablesubmit, setdisableSubmit] = useState('');
  const [formData, setFormData] = useState({
    quotation_type: "",
    cust_name: "",
    start_date: "",
    end_date: "",
    proposal_title: "",
    budget: "",
    follow_up_date: "",
    no_of_adult: "",
    no_of_children: "",
    package_type: "",
    visa_type: "",
    no_of_person: "",
    net_cost: "",
    mark_up: "",
    gst: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
    date_time: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        quotation_type: data.quotation_type,
        cust_name: data.cust_name,
        start_date: data.start_date,
        end_date: data.end_date,
        proposal_title: data.proposal_title,
        budget: data.budget,
        follow_up_date: data.follow_up_date,
        no_of_adult: data.no_of_adult,
        no_of_children: data.no_of_children,
        package_type: data.package_type,
        visa_type: data.visa_type,
        no_of_person: data.no_of_person,
        net_cost: data.net_cost,
        mark_up: data.mark_up,
        gst: data.gst,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        deficit: data.deficit,
        date_time: data.date_time,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ideal_collection: idealcost,
      gst: withgst,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  //Validation Code
  const {
    NetVisaCostValidation,
    NumberOfValidation,
    MarkupValidation,
    ActualCollectionValidation,
  } = Validation();

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setdisableSubmit(true);
  
    const requiredFields = [
      "visa_type",
      "no_of_person",
      "net_cost",
      "mark_up",
    ];
  
    const isValid = requiredFields.every((field) => formData[field].trim() !== "");
  
    if (!isValid) {
      toast.error("Please fill all mandatory fields");
      setdisableSubmit(false);
      return;
    }
  
    try {
      await updateVisaQuotation({ id, formData });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("An error occurred during submission");
      setdisableSubmit(false);
    }
  };
  
  const handleClose = () => {
    onClose();
  };
  
  return (
    <>
      <div className="relative">
        <div class="fixed inset-0 z-50 m-1  items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <div >
                
                <span
                  className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    zIndex: "1100px",
                  }}
                >
                  {" "}
                  <FaTimes size={24} />{" "}
                </span>
                <h2 className="mb-4 text-center text-lg font-semibold underline">
                  Update Visa Quotation
                </h2>
              </div>

              <form onSubmit={handleSubmit}>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label
                      for="hotelname"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="cust_name"
                        id="cust_name"
                        autoComplete="cust_name"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        required
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="visa_type"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Type of Visa
                    </label>
                    <div class="mt-2">
                      <select
                        id="visa_type"
                        name="visa_type"
                        autoComplete="typeofvisa"
                        onChange={handleInputChange}
                        value={formData.visa_type}
                        required
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                         <option value="">Select</option> 
                        <option >Tourist</option>
                        <option >Student</option>
                        <option >Work</option>
                        <option >Business</option>
                      </select>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="numofperson"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Number of Person{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="no_of_person"
                        id="no_of_person"
                        autocomplete="numofperson"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.no_of_person}
                        //  onKeyUp={NumberOfValidation}
                      />
                      {/* <span id="NumberOfValidation"></span> */}
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="netcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="net_cost"
                        id="net_cost"
                        autocomplete="net_cost"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.net_cost}
                        onKeyUp={NetVisaCostValidation}
                      />
                      <span id="netVisaCostValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="markup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="mark_up"
                        id="mark_up"
                        autocomplete="markup"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.mark_up}
                        onKeyUp={MarkupValidation}
                      />
                      <span id="MarkupValidation"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gstcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      GST 18% on Markup
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="gst"
                        id="gst"
                        autocomplete="gst"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gst}
                        readOnly
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="idealcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="ideal_collection"
                        id="ideal_collection"
                        autocomplete="ideal_collection"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        readOnly
                      />
                    </div>
                  </div>
                  {/* <div class="sm:col-span-3">
                    <label
                      for="actualcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Actual Collection{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="actual_collection"
                        id="actual_collection"
                        autocomplete="actualcollection"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.actual_collection}
                        onKeyUp={ActualCollectionValidation}
                        readOnly
                      />
                      <span id="actualCollectionValidation"></span>
                    </div>
                  </div> */}
                  <div class="sm:col-span-3">
                    <label
                      for="deficit"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="deficit"
                        id="deficit"
                        autocomplete="deficit"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex place-content-center">
                  <button
                    className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                    onClick={handleSubmit}
                    disabled={disablesubmit}
                  >
                    Update
                  </button>
                  {/* <button class="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                  onClick={handleClose}
                >
                  Back
                </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
