import Card from "components/card";

import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";

import { useMemo, useRef } from "react";

import { useAdmin } from "hooks/useAdmin";

import { Link } from "react-router-dom";

const LeadsData = (props) => {
  const [showAll, setShowAll] = useState(false);
  const [visibleRecords, setVisibleRecords] = useState(5);

  const [showloading, setShowLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { getLeadDetails, leadData, loading } = useAdmin();

  useEffect(() => {
    getLeadDetails();
    setShowLoading(false);
  }, [loading]);

  // console.log(leadData);

  useEffect(() => {
    setFilteredData(leadData);
  }, [loading]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (showAll) {
      setVisibleRecords(leadData.length);
    } else {
      setVisibleRecords(5);
    }
  }, [showAll, leadData]);

  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [searchTerm, setSearchTerm] = useState("");

  const [isOpen, setIsOpen] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);

  const initialFormData = {};

  const toggleDropdown = (index) => {
    setIsOpen(true);
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const filteredLeadData = useMemo(() => {
    // Filter the data based on searchTerm and client_type
    const filteredData = leadData?.filter(
      (item) =>
        item?.client_type === "Lead" &&
        item?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort the filtered data by dateOfLead property in descending order
    const sortedData = filteredData?.sort(
      (a, b) => new Date(b.dateOfLead) - new Date(a.dateOfLead)
    );

    return sortedData;
  }, [leadData, searchTerm]);

  const record = filteredLeadData?.length
    ? filteredLeadData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredLeadData?.length
    ? Math.ceil(filteredLeadData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };

  return (
    <>
      <Card extra={"w-full pb-10 h-full"}>
        <div className="mt-4 flex flex-col ">
          <div className="-m-1.5 overflow-x-auto">
            <div className="inline-block min-w-full p-1.5 align-middle">
              <div className="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-400 dark:bg-gray-700">
                      <tr className="whitespace-nowrap">
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                        >
                          Sr No
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                        >
                          Email-ID
                        </th>

                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                        >
                          Contact
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 text-lg dark:divide-gray-700">
                      {record && record?.length >= 0 ? (
                        record?.map((data, index) => {
                          return (
                            <>
                              <tr>
                                <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                  {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                  {data.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                  {new Date(data.dateOfLead).toLocaleDateString(
                                    "en-IN"
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                  {data.email}
                                </td>

                                <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                  {data.mobile_no}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7" className="py-4 text-center">
                            No data found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!showAll && (
          <div className="mt-4 flex justify-center underline">
            <Link
              to="/admin/nft-marketplace"
              className="text-red-600 hover:text-red-600"
              onClick={toggleShowAll}
            >
              View All
            </Link>
          </div>
        )}
      </Card>
    </>
  );
};
export default LeadsData;
