import { useEffect, useState } from "react";
import Card from "components/card";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { Validation } from "./Validation";
import { toast } from "react-toastify";
export default function MultiCiti({
  parentData,
  clientId,
  name,
  tripType,
  handleCloseTripType,
}) {
  const { AddFlightQuotation } = useAdmin();

  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [disableSubmit, setdisableSubmit] = useState(false);

  const [data, setData] = useState([
    { from: "", to: "", traveldate: "", pnr: "" },
  ]);
  const [actualcollection, setActualcollection] = useState("");
  // const [showOneWay, setShowOneWay] = useState(false);
  const [formData, setFormData] = useState({
    tripType: "",
    airlines: "",
    bookingsource: "",
    netcost: "",
    markup: "",
    gstcost: "",
    idealcollection: "",
    actualcollection: "",
    cancellationCharges: "",
    refundAmount: "",
  });

  const handleClick = () => {
    setData([...data, { from: "", to: "", traveldate: "", pnr: "" }]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  const swapValues = (index) => {
    const updatedData = [...data];
    const tempTo = updatedData[index].to;
    updatedData[index].to = updatedData[index].from;
    updatedData[index].from = tempTo;
    setData(updatedData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = name === "pnr" ? value.toUpperCase() : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: uppercaseValue,
    }));

    //set to data in from
    if (name === "to") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        from2: value,
      }));
    }

    // showOneWay(false);

    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };

  useEffect(() => {
    const amount = parseFloat(netcost) || 0;
    const markupCost = parseFloat(markup) || 0;
    const actualcost = parseFloat(actualcollection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      idealcollection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [netcost, markup, actualcollection]);

  // Reset form data to initial state

  const handleDiscard = () => {
    setFormData({
      airlines: "",
      bookingsource: "",
      netcost: "",
      markup: "",
      gstcost: "",
      idealcollection: "",
      actualcollection: "",
      cancellationCharges: "",
      refundAmount: "",
      deficit: "",
    });
    setData([{ from: "", to: "", traveldate: "", pnr: "" }]);
  };
  //Validation Code

  const {
    AlphaValidation,
    ToValidation,
    error1,
    errorMessage,
    PnrValidation,
    pnrError,
    AirlineValidation,
    airlineError,
    BookingSourceValidation,
    bookingError,
    NetCostValidation,
    netcostError,
    MarkupValidation,
    markupError,
    ActualCollectionValidation,
    actuallcollectionError,
    CancellationValidation,
    cancellationError,
    RefundValidation,
    refundError,
  } = Validation();

  // console.log(formData)

  //form close
  const [showForm, setShowForm] = useState(true);
  const handleClose = () => {
    setShowForm(false);
    handleCloseTripType(false);
  };
  const handleSubmit = async (e) => {
    setdisableSubmit(true);

    e.preventDefault();
    if (validateForm() === true) {
      await AddFlightQuotation({
        formData,
        parentData,
        clientId,
        name,
        tripType,
        data,
      });
      setInterval(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Please fill manadatory field");
    }
  };

  function validateForm() {
    if (
      formData.airlines !== "" &&
      formData.netcost !== "" &&
      formData.markup !== "" &&
      formData.gstcost !== "" &&
      // formData.cancellationCharges!== ""&&
      // formData.actualcollection!== ""&&
      formData.bookingsource !== "" &&
      // formData.refundAmount!== ""&&
      formData.idealcollection !== ""
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      {showForm ? (
        <div className={`$ "hidden" : "block" relative`}>
          <div class="fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0">
            <div>
              <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
                <span
                  className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    zIndex: "1100px",
                  }}
                >
                  <FaTimes size={24} />
                </span>

                <form onSubmit={handleSubmit} style={{ padding: "40px 40px" }}>
                  <h2 className="mb-4 text-center text-lg font-semibold underline">
                    Multi City
                  </h2>
                  <div class="border-b border-gray-900/10 pb-12">
                    <div className="mt-4">
                      <div className="mb-4 flex flex-wrap">
                        {data.map((val, i) => (
                          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-1">
                              <label
                                for="from2"
                                className="text-black block text-sm font-semibold leading-6"
                              >
                                From <sup className="mb-2 text-red-500">*</sup>
                              </label>
                              <div className="mt-2">
                                <input
                                  id="from"
                                  name="from"
                                  type="text"
                                  autoComplete="from"
                                  className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 
                                  focus:ring-inset focus:ring-indigo-600 sm:w-[380px] sm:text-sm sm:leading-6"
                                  onChange={(e) => handleChange(e, i)}
                                  value={val.from}
                                  onKeyUp={AlphaValidation}
                                  required
                                />
                                {errorMessage && (
                                  <span style={{ color: "red" }}>
                                    {errorMessage}
                                  </span>
                                )}
                              </div>
                            </div>

                            <FontAwesomeIcon
                              icon={faArrowRightArrowLeft}
                              onClick={() => swapValues(i)}
                              className="mx-auto mb-4 text-red-500 sm:ml-[180px] sm:mt-0"
                              style={{ marginTop: "40px" }}
                            />
                            <div className="sm:col-span-2">
                              <label
                                for="to2"
                                className="text-black block text-sm font-semibold leading-6"
                              >
                                To<sup className="mb-2 text-red-500">*</sup>
                              </label>
                              <div className="mt-2">
                                <input
                                  id="to"
                                  name="to"
                                  type="text"
                                  autoComplete="to"
                                  className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => handleChange(e, i)}
                                  value={val.to}
                                  onKeyUp={ToValidation}
                                  required
                                />
                                {error1 && (
                                  <span style={{ color: "red" }}>{error1}</span>
                                )}
                              </div>
                            </div>

                            <div className="sm:col-span-2">
                              <label
                                for="pnr1"
                                className="text-black block text-sm font-semibold leading-6"
                              >
                                PNR
                              </label>
                              <div className="mt-2">
                                <input
                                  id="pnr"
                                  name="pnr"
                                  type="text"
                                  autoComplete="pnr"
                                  className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => handleChange(e, i)}
                                  value={val.pnr}
                                  // onKeyUp={PnrValidation}
                                  required
                                />
                                {pnrError && (
                                  <span style={{ color: "red" }}>
                                    {pnrError}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="sm:col-span-2">
                              <label
                                for="traveldate"
                                className="text-black mr-4 block text-sm font-semibold leading-6"
                              >
                                Travel Date{" "}
                                <sup className="mb-2 text-red-500">*</sup>
                              </label>
                              <div className="mt-2">
                                <input
                                  id="traveldate"
                                  name="traveldate"
                                  type="date"
                                  autoComplete="traveldate"
                                  className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => handleChange(e, i)}
                                  value={val.traveldate}
                                  reuired
                                />
                              </div>
                            </div>

                            <button
                              type="button"
                              className="mt-7  rounded border-gray-600 bg-red-500 px-2 py-2 text-white hover:bg-red-700"
                              onClick={() => handleDelete(i)}
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      </div>
                      <button
                        type="button"
                        className="mt-7  rounded border-gray-600 bg-green-500 px-5 py-2 text-white hover:bg-green-600"
                        onClick={handleClick}
                      >
                        Add +
                      </button>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          for="airlines"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Airlines<sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="airlines"
                            name="airlines"
                            type="text"
                            autocomplete="airlines"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.airlines}
                            onKeyUp={AirlineValidation}
                            required
                          />
                          {airlineError && (
                            <span style={{ color: "red" }}>{airlineError}</span>
                          )}
                        </div>
                      </div>
                      <div class="sm:col-span-3">
                        <label
                          for="bookingsource"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Booking Source
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="bookingsource"
                            name="bookingsource"
                            type="text"
                            autocomplete="bookingsource"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.bookingsource}
                            onKeyUp={BookingSourceValidation}
                            required
                          />
                          {bookingError && (
                            <span style={{ color: "red" }}>{bookingError}</span>
                          )}
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="netcost"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Net Cost<sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="netcost"
                            name="netcost"
                            type="text"
                            autocomplete="netcost"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.netcost}
                            onKeyUp={NetCostValidation}
                            required
                          />
                          {netcostError && (
                            <span style={{ color: "red" }}>{netcostError}</span>
                          )}
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="markup"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Mark Up<sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="markup"
                            name="markup"
                            type="text"
                            autocomplete="markup"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.markup}
                            onKeyUp={MarkupValidation}
                            required
                          />
                          {markupError && (
                            <span style={{ color: "red" }}>{markupError}</span>
                          )}
                        </div>
                      </div>
                      <div class="sm:col-span-3">
                        <label
                          for="gstcost"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          GST 18% on Mark Up
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="gstcost"
                            name="gstcost"
                            type="text"
                            autocomplete="gstcost"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.gstcost}
                            readOnly
                          />
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="idealcollection"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Ideal Collection
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="idealcollection"
                            name="idealcollection"
                            type="text"
                            autocomplete="idealcollection"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.idealcollection}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div class="sm:col-span-3">
                        <label
                          for="actualcollection"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Actual Collection
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="actualcollection"
                            name="actualcollection"
                            type="text"
                            autocomplete="actualcollection"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.actualcollection}
                            onKeyUp={ActualCollectionValidation}
                            readOnly
                            required
                          />
                          {actuallcollectionError && (
                            <span style={{ color: "red" }}>
                              {actuallcollectionError}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div class="sm:col-span-3">
                        <label
                          for="deficit"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Deficit <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="deficit"
                            name="deficit"
                            type="text"
                            autocomplete="deficit"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.deficit}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* <div class="sm:col-span-3">
                        <label
                          for="cancellationCharges"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Cancellation Charges
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="cancellationCharges"
                            name="cancellationCharges"
                            type="text"
                            autocomplete="cancellationCharges"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.cancellationCharges}
                            onKeyUp={CancellationValidation}
                            required
                          />
                          {cancellationError && (
                            <span style={{ color: "red" }}>
                              {cancellationError}
                            </span>
                          )}
                        </div>
                      </div> */}

                      {/* <div class="sm:col-span-3">
                        <label
                          for="refundAmount"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Refund Amount
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="refundAmount"
                            name="refundAmount"
                            type="text"
                            autocomplete="refundAmount"
                            class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={formData.refundAmount}
                            onKeyUp={RefundValidation}
                            required
                          />
                          {refundError && (
                            <span style={{ color: "red" }}>{refundError}</span>
                          )}
                        </div>
                      </div>
                    </div> */}
                    </div>
                    <div className="mt-4 flex place-content-center ">
                      <button
                        className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                        type="submit"
                        onClick={handleSubmit}
                        // disabled={disableSubmit}
                      >
                        Save
                      </button>
                      <button
                        onClick={handleDiscard}
                        className="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
