import React, { useState } from "react"; // Make sure to import React
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Image from "./pic.png"; // Assuming this import is correct
import { Link } from "react-router-dom";

export default function PasswordReset() {
  const [showOTPBox, setShowOTPBox] = useState(false); // State for displaying OTP box
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "", // Add email to formData state
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowOTPBox(true);
  };

  return (
    <>
      <ToastContainer />
      <section className="bg-gray-50 min-h-screen flex items-center justify-center">
        <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
          <div className="md:block hidden w-1/2">
            <img className="rounded-2xl" src={Image} alt="Login" />
          </div>
          <div className="md:w-1/2 px-8 md:px-16">
            <h2 className="font-bold text-2xl text-[#002D74]">Forget Password ?</h2>
            <p className="text-xs mt-4 text-[#002D74]">
              Welcome back please enter your Email-ID
            </p>

            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <input
                className="p-2 mt-8 rounded-xl border"
                type="text"
                name="email"
                placeholder="Enter Email-ID"
                onChange={handleInputChange}
                value={formData.email}
                required
              />
             
              
              {/* {showOTPBox && (
                <input
                  className="p-2 mt-8 rounded-xl border"
                  type="text"
                  name="otp"
                  placeholder="Enter OTP"
                  // Add necessary props and event handlers
                />
              )} */}
              
              <button className="bg-[#FF0000] rounded-xl text-white py-2 hover:scale-105 duration-300">
                {showOTPBox ? "Submit OTP" : "Submit"}
              </button>

              <div class="ml-15 py-4 text-[#FF0000]">
               <Link to ="/"><u>Back to SignIn</u></Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}