import React, { useState } from "react";
import ProfileSetting from "./ProfileSetting";
import AccountSetting from "./AccountSetting";

const Settings = () => {
  const [activetab, setActivetab] = useState("tab1");

  const handleTab = (tab) => {
    setActivetab(tab);
  };

  return (
    <>
      <div className="mx-auto -mb-px flex max-w-screen-xl flex-wrap items-center justify-center p-4">
        <ul
          className="mb-px flex items-center text-center text-base font-semibold"
          id="default-styled-tab"
        >
          <li className="me-2  " role="presentation">
            <button
              data-tabs-target="#styled-dashboard"
              type="button"
              role="tab1"
              aria-controls="dashboard"
              aria-selected={activetab === "tab1"}
              className={`tab-item ${activetab === "tab1" ? "active" : ""}`}
              onClick={() => handleTab("tab1")}
              // style={{marginLeft:"280px"}}
            >
              Profile Settings
            </button>
          </li>

          <li className="me-2" role="presentation">
            <button
              data-tabs-target="#styled-settings"
              type="button"
              role="tab2"
              aria-controls="settings"
              aria-selected={activetab === "tab2"}
              className={`tab-item ${activetab === "tab2" ? "active" : ""}`}
              onClick={() => handleTab("tab2")}
            >
              Account Settings
            </button>
          </li>
        </ul>

        {/* Conditionally render the corresponding component based on the active tab */}
        {activetab === "tab1" && <ProfileSetting />}
        {activetab === "tab2" && <AccountSetting />}
      </div>
    </>
  );
};

export default Settings;
