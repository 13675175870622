import Card from "components/card";
import { useEffect, useState, useRef } from "react";
import { useMemo } from "react";
import { PiDotsThreeBold } from "react-icons/pi";
import Quotation from "views/auth/quotation/Quotation";
import { useAdmin } from "../../../../hooks/useAdmin";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UpdateIternationalQuotation from "views/auth/update_quotation/UpdateIternationalQuotation";
import UpdateDomesticQuotation from "views/auth/update_quotation/UpdateDomesticQuotation";
import UpdateHotelbookQuotation from "views/auth/update_quotation/UpdateHotelbookQuotation";
import UpdateVisaQuotation from "views/auth/update_quotation/UpdateVisaQuotation";
import UpdateInsuranceQuotation from "views/auth/update_quotation/UpdateInsuranceQuotation";
import UpdateFlightOneWayQuotation from "views/auth/update_quotation/UpdateFlightOneWayQuotation";
import UpdateFilghtRoundWayQuotation from "views/auth/update_quotation/UpdateFilghtRoundWayQuotation";
import UpdateFlightMultiCityQuotation from "views/auth/update_quotation/UpdateFlightMultiCityQuotation";
import AddFile from "./AddFile";
import DownloadFile from "./DownloadFile";

const QuotationTable = (props) => {
  const [showloading, setShowLoading] = useState(true);
  const [updateInternationalQuotation, setUpdateInternationalQuotation] =
    useState(false);
  const [showupdateDomesticQuotation, setShowupdateDomesticQuotation] =
    useState(false);
  const [updateHotelbookQuot, setupdateHotelbookQuot] = useState(false);
  const [updateVisaQuot, setupdateVisaQuot] = useState(false);
  const [updateInsuranceQuot, setupdateInsuranceQuot] = useState(false);
  const {
    getCombinedQuotationData,
    saveInvoiceDetails,
    quotationCombinedData,
    addfiles,
    loading,
  } = useAdmin();

  const [updateFlightOneWayQuot, setUpdateFlightOneWayQuot] = useState(false);
  const [updateFlightRoundTripQuot, setUpdateFlightRoundTripQuot] =
    useState(false);
  const [updateFlightMultiCityQuot, setUpdateFlightMultiCityQuot] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const menuRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    getCombinedQuotationData();
    // setTimeout(() => {
    //   setShowLoading(false);
    // }, 2000);
  }, [loading]);

  useEffect(() => {
    setFilteredData(quotationCombinedData);
  }, [quotationCombinedData]);

  // useEffect(() => {
  //   getCombinedQuotationData();
  //   // setShowLoading(false);
  // }, [loading]);
  useEffect(() => {
    if (quotationCombinedData && Array.isArray(quotationCombinedData)) {
      const reversedData = [...quotationCombinedData].reverse();
      setFilteredData(reversedData);
    }
  }, [quotationCombinedData]);

  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [QuotationCustomerDetailes, showQuotationCustomerDetailes] =
    useState(false);
  const [data1, setdata1] = useState();

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleChange = (e) => {
    if (e.target.value === "International") {
      setSelectedValue(e.target.value);
      const filteredResults = quotationCombinedData.filter((item) =>
        item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredData(filteredResults);
    } else if (e.target.value === "Domestic") {
      setSelectedValue(e.target.value);
      const filteredResults = quotationCombinedData.filter((item) =>
        item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredData(filteredResults);
    } else if (e.target.value === "HotelBook") {
      setSelectedValue(e.target.value);
      const filteredResults = quotationCombinedData.filter((item) =>
        item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredData(filteredResults);
    } else if (e.target.value === "Visa") {
      setSelectedValue(e.target.value);
      const filteredResults = quotationCombinedData.filter((item) =>
        item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredData(filteredResults);
    } else if (e.target.value === "Flight") {
      setSelectedValue(e.target.value);
      const filteredResults = quotationCombinedData.filter((item) =>
        item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredData(filteredResults);
    } else if (e.target.value === "Insurance") {
      setSelectedValue(e.target.value);
      const filteredResults = quotationCombinedData.filter((item) =>
        item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredData(filteredResults);
    } else if (e.target.value === "select") {
      setSelectedValue(e.target.value);
      setFilteredData(quotationCombinedData);
    }
  };

  const closeNewLeadForm = () => {
    showQuotationCustomerDetailes(false);
    setUpdateInternationalQuotation(false);
    setShowupdateDomesticQuotation(false);
    setupdateHotelbookQuot(false);
    setupdateVisaQuot(false);
    setupdateInsuranceQuot(false);
    setUpdateFlightOneWayQuot(false);
    setUpdateFlightRoundTripQuot(false);
    setUpdateFlightMultiCityQuot(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isOpen, setIsOpen] = useState();

  const [openIndex, setOpenIndex] = useState(null);
  const [formData, setFormData] = useState({});

  const initialFormData = {};

  const handleUpdate = (updatedData) => {
    setFormData(updatedData);
  };

  const toggleDropdown = (index) => {
    setIsOpen(true);
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleOptionClick = (e, data) => {
    setIsOpen(false);
    if (e === "Edit") {
      setdata1(data);
      setIsUpdateForm(true);
      setFormData(initialFormData);
      if (data.package_type === "International") {
        setUpdateInternationalQuotation(true);
      } else if (data.package_type === "Domestic") {
        setShowupdateDomesticQuotation(true);
      } else if (data.package_type === "HotelBook") {
        setupdateHotelbookQuot(true);
      } else if (data.package_type === "Visa") {
        setupdateVisaQuot(true);
      } else if (data.package_type === "Insurance") {
        setupdateInsuranceQuot(true);
      } else if (data.package_type === "Flight") {
        if (data.trip_type === "One Way") {
          setUpdateFlightOneWayQuot(true);
        } else if (data.trip_type === "Round Trip") {
          setUpdateFlightRoundTripQuot(true);
        } else if (data.trip_type === "MultiCity") {
          setUpdateFlightMultiCityQuot(true);
        }
      }
    } else {
      setFormData({});
    }
  };

  const handleConvertInvoice = (id, quotationType) => {
    saveInvoiceDetails({ id, quotationType });
    setInterval(() => {
      window.location.reload();
    }, 1000);
  };

  const [fetchdocument, setFetchDocument] = useState(null);
  const [visibledocument, setVisibleDocument] = useState(false);

  const hadnleDownloadDocument = (quotationid, quotationType) => {
    setFetchDocument({ quotationid, quotationType });
    setVisibleDocument(true);
  };
  const closePopupForm = () => {
    setVisibleDocument(false);
    setFetchDocument(true);
  };

  const [adddocument, setAdddocument] = useState(null);
  const [isvisible, setIsvisible] = useState(false);

  const handleAddFile = (id, quotationType) => {
    setAdddocument({ id, quotationType });
  };

  useEffect(() => {
    if (adddocument !== undefined) {
      setIsvisible(true);
    }
  }, [adddocument]);

  const closePopup = () => {
    setIsvisible(false);
    setAdddocument(null);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const filteredResults = quotationCombinedData.filter(
      (item) =>
        item?.cust_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item?.quotation_number
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };
  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 when data changes
  }, [quotationCombinedData, searchTerm]);

  // pagination
  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const record = filteredData?.length
    ? filteredData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredData?.length
    ? Math.ceil(filteredData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(nPages);
  };

  const handleOptionPrint = (data) => {
    // console.log(data);
    if (data?.package_type === "Visa") {
      navigate(`/admin/visa-quotation?id=${data?._id}`);
    } else if (data?.package_type === "Insurance") {
      navigate(`/admin/insurance-quotation?id=${data?._id}`);
    } else if (data?.package_type === "International") {
      navigate(`/admin/international-quotation?id=${data?._id}`);
    } else if (data?.package_type === "Domestic") {
      navigate(`/admin/domestic-quotation?id=${data?._id}`);
    } else if (data?.package_type === "HotelBook") {
      navigate(`/admin/hotelbook-quotation?id=${data?._id}`);
    } else if (data?.package_type === "Flight") {
      if (data?.trip_type === "One Way") {
        navigate(`/admin/onewaytrip-quotation?id=${data?._id}`);
      } else if (data?.trip_type === "Round Trip") {
        navigate(`/admin/roundtrip-quotation?id=${data?._id}`);
      } else if (data?.trip_type === "MultiCity") {
        navigate(`/admin/multicitytrp-quotation?id=${data?._id}`);
      }
    }
  };

  return (
    <>
      {!loading ? (
        <Skeleton count={5} height={40} />
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between"></header>
          <div className="mt-4 flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="inline-block min-w-full p-1.5 align-middle">
                <div className="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                  <div className="px-4 py-3">
                    <div className="flex items-center justify-between">
                      <div className="relative min-w-96">
                        <label className="sr-only">Search</label>
                        <input
                          type="text"
                          name="hs-table-with-pagination-search"
                          id="hs-table-with-pagination-search"
                          className="dark:bg-slate-900 border-black block w-full rounded-lg border px-4 py-2 ps-10 text-sm"
                          placeholder="Search By Name/Quotation No."
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                          <svg
                            className="size-4 text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                          </svg>
                        </div>
                      </div>
                      <div className="relative mr-20">
                        <div className="-right-1/2 mx-auto translate-x-1/2 transform rounded-lg bg-white py-1 ">
                          <select
                            id="exampleSelect"
                            value={selectedValue}
                            onChange={handleChange}
                            className="mt-1 rounded-md border border-gray-300 p-2 focus:border-blue-300 focus:outline-none focus:ring"
                          >
                            <option value="select">Filter</option>
                            <option value="International">International</option>
                            <option value="Domestic">Domestic</option>
                            <option value="HotelBook">Hotel Booking</option>
                            <option value="Visa">Visa</option>
                            <option value="Flight">Flight</option>
                            <option value="Insurance">Insurance</option>
                          </select>
                        </div>
                        {/* </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  {QuotationCustomerDetailes ? (
                    <Quotation onClose={closeNewLeadForm} />
                  ) : (
                    ""
                  )}
                  {updateInternationalQuotation ? (
                    <UpdateIternationalQuotation
                      data={data1}
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                    />
                  ) : (
                    " "
                  )}

                  {showupdateDomesticQuotation ? (
                    <UpdateDomesticQuotation
                      data={data1}
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                    />
                  ) : (
                    " "
                  )}

                  {updateHotelbookQuot ? (
                    <UpdateHotelbookQuotation
                      data={data1}
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                    />
                  ) : (
                    " "
                  )}

                  {updateVisaQuot ? (
                    <UpdateVisaQuotation
                      data={data1}
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                    />
                  ) : (
                    " "
                  )}

                  {updateInsuranceQuot ? (
                    <UpdateInsuranceQuotation
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    " "
                  )}
                  {updateFlightOneWayQuot ? (
                    <UpdateFlightOneWayQuotation
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    " "
                  )}
                  {updateFlightRoundTripQuot ? (
                    <UpdateFilghtRoundWayQuotation
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    " "
                  )}
                  {updateFlightMultiCityQuot ? (
                    <UpdateFlightMultiCityQuotation
                      onUpdate={handleUpdate}
                      onClose={closeNewLeadForm}
                      data={data1}
                    />
                  ) : (
                    " "
                  )}

                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-400 dark:bg-gray-700">
                        <tr className="whitespace-nowrap">
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Sr No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Name
                          </th>
                          {/* <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Note
                          </th> */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Package Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Date Of Lead
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Quotation Number
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Print
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {record && record?.length >= 0 ? (
                          record?.map((data, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {index +
                                      1 +
                                      (currentPage - 1) * recordPerPage}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.cust_name}
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.itinerary}
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.package_type}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {new Date(
                                      data.date_time
                                    ).toLocaleDateString("en-IN")}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.quotation_number}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium">
                                    <button
                                      onClick={() => handleOptionPrint(data)}
                                      className="rounded-md bg-blue-500 px-3 py-1 text-white hover:bg-blue-700"
                                    >
                                      Print
                                    </button>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium">
                                    <button
                                      onClick={() => toggleDropdown(index)}
                                      className="text-black mt-1 items-center px-3 py-2 text-center sm:ml-3"
                                    >
                                      <PiDotsThreeBold />
                                    </button>
                                    {openIndex === index && (
                                      <div
                                        ref={menuRef}
                                        className="origin-top-center ring-black absolute right-0 mt-2 w-44 rounded-md bg-white shadow-lg ring-1 ring-opacity-5"
                                      >
                                        <div
                                          className="py-1"
                                          role="menu"
                                          aria-orientation="vertical"
                                          aria-labelledby="options-menu"
                                        >
                                          <button
                                            onClick={() =>
                                              handleOptionClick("Edit", data)
                                            }
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Edit
                                          </button>
                                          <button
                                            onClick={() => {
                                              handleConvertInvoice(
                                                data._id,
                                                data.package_type
                                              );
                                            }}
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Convert to Invoice
                                          </button>
                                          <button
                                            onClick={() => {
                                              handleAddFile(
                                                data._id,
                                                data.package_type
                                              );
                                            }}
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Add File
                                          </button>
                                          <button
                                            onClick={() => {
                                              hadnleDownloadDocument(
                                                data._id,
                                                data.package_type
                                              );
                                            }}
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                          >
                                            Download File
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" className="py-4 text-center">
                              No data found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      <nav className="mt-4 flex items-center justify-center -space-x-px rounded-md shadow-sm">
        <ul className="flex items-center justify-center border-t px-4 py-3 sm:px-6">
          {currentPage !== 1 && currentPage !== 2 && (
            <>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={goToFirstPage}
                  aria-label="First"
                >
                  First
                </Link>
              </li>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={prePage}
                  aria-label="Previous"
                >
                  Previous
                </Link>
              </li>
            </>
          )}
          {numbers.map(
            (n, i) =>
              (n === currentPage ||
                n === currentPage - 1 ||
                n === currentPage + 1) && (
                <li
                  className={`page-item ${currentPage === n ? "active" : ""}`}
                  key={i}
                >
                  <Link
                    href="#"
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                      currentPage === n ? "bg-gray-200" : ""
                    }`}
                    onClick={() => changeCPage(n)}
                  >
                    {n}
                  </Link>
                </li>
              )
          )}
          {currentPage !== nPages && (
            <>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={nextPage}
                  aria-label="Next"
                >
                  Next
                </Link>
              </li>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={goToLastPage}
                  aria-label="Last"
                >
                  Last
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      {isvisible && <AddFile data={adddocument} onClose={closePopup} />}
      {visibledocument && (
        <DownloadFile data={fetchdocument} onClose={closePopupForm} />
      )}
    </>
  );
};

export default QuotationTable;
