import Card from "components/card";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { useMemo, useRef } from "react";
import { useAdmin } from "../../../hooks/useAdmin";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

const CustomerReports = (props) => {
  const [selectedValue, setSelectedValue] = useState();
  const [showloading, setShowLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const {
    getLeadDetails,
    leadData,
    getCombinedQuotationData,
    quotationCombinedData,
    loading,
  } = useAdmin();
  const menuRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      getCombinedQuotationData();
      setShowLoading(false);
    }, 2000);
  }, [loading]);

  useEffect(() => {
    setTimeout(() => {
      getLeadDetails();
      setShowLoading(false);
    }, 2000);
  }, [loading]);

  //  useEffect(() => {
  //    setFilteredData(quotationCombinedData);
  // }, [quotationCombinedData]);

  //  console.log(quotationCombinedData);

  useEffect(() => {
    setFilteredData(leadData);
  }, [loading, leadData]);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    filterData(e.target.value, searchTerm);
  };

  const filterData = (selectedDate, searchTerm) => {
    const selectedDateObj = new Date(selectedDate);

    let updatedFilteredData = [...leadData]; // Copy the original leadData

    if (selectedDate) {
      updatedFilteredData = updatedFilteredData.filter((item) => {
        const itemDate = new Date(item.dateOfLead);
        const itemDateStr = `${itemDate.getFullYear()}-${String(
          itemDate.getMonth() + 1
        ).padStart(2, "0")}-${String(itemDate.getDate()).padStart(2, "0")}`;
        return itemDateStr === selectedDate;
      });
    }

    if (searchTerm) {
      const searchValue = searchTerm.toLowerCase();
      updatedFilteredData = updatedFilteredData.filter((item) => {
        const packageTypeMatch = item?.package_type
          .toLowerCase()
          .includes(searchValue);
        const customerNameMatch = item?.cust_name
          .toLowerCase()
          .includes(searchValue);
        return packageTypeMatch || customerNameMatch;
      });
    }

    setFilteredData(updatedFilteredData);
  };

  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [isCreateQuotationOpen, setIsCreateQuotationOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isNewLeadOpen, setIsNewLeadOpen] = useState(false);
  const [data1, setdata1] = useState();

  const [isOpen, setIsOpen] = useState();
  const [openIndex, setOpenIndex] = useState(null);
  const [formData, setFormData] = useState({});

  const initialFormData = {};

  const toggleDropdown = (index) => {
    setIsOpen(true);
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const filteredLeadData = useMemo(() => {
    const sortedData = filteredData
      ?.slice()
      .sort((a, b) => new Date(b.dateOfLead) - new Date(a.dateOfLead)); // Sorting in descending order
    return sortedData?.filter(
      (item) =>
        item?.client_type === "Customer" &&
        (item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [filteredData, searchTerm]);

  const record = filteredLeadData?.length
    ? filteredLeadData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredLeadData?.length
    ? Math.ceil(filteredLeadData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(nPages);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    filterData(selectedValue, e.target.value);
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredLeadData);
    XLSX.utils.book_append_sheet(wb, ws, "Customer Data");
    XLSX.writeFile(wb, "customer_data.xlsx");
  };

  return (
    <>
      {showloading ? (
        <Skeleton count={5} height={40} />
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between"></header>
          <div className="mt-4 flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="inline-block min-w-full p-1.5 align-middle">
                <div className="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                  <div className="px-4 py-3">
                    <div className="flex items-center justify-between">
                      <div className="relative w-1/2">
                        <label className="sr-only">Search</label>
                        <input
                          type="text"
                          name="hs-table-with-pagination-search"
                          id="hs-table-with-pagination-search"
                          className="dark:bg-slate-900 border-black block w-full rounded-lg border px-4 py-2 ps-10 text-sm"
                          placeholder="Search a Customer by Name/Email"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                          <svg
                            className="size-4 text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                          </svg>
                        </div>
                      </div>
                      <div ref={menuRef} className="relative">
                        <div className="dark:bg-slate-900 flex items-center space-x-2 text-nowrap px-4 py-2 ps-10 text-sm text-gray-400">
                          <label htmlFor="dateRange">Date :</label>
                          <input
                            type="date"
                            id="dateRange"
                            // value={startDate}
                            // onChange={(e) => setStartDate(e.target.value)}
                            value={selectedValue}
                            onChange={handleChange}
                            className="rounded border p-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overflow-hidden">
                    <table className="w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-400 dark:bg-gray-700">
                        <tr className="whitespace-nowrap">
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Sr No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Customer Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Email-ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Package Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Date Of Lead
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Client Type
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {record && record?.length >= 0 ? (
                          record?.map((data, index) => {
                            const temp_data = quotationCombinedData?.find(
                              (obj) => obj?.client_id === data?._id
                            );
                            return (
                              <>
                                <tr>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {index +
                                      1 +
                                      (currentPage - 1) * recordPerPage}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.email}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {temp_data?.package_type}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {new Date(
                                      data.dateOfLead
                                    ).toLocaleDateString("en-IN")}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.client_type}
                                  </td>
                                </tr>
                              </>
                            );
                            //  }
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" className="py-4 text-center">
                              No data found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="flex items-center justify-center -space-x-px rounded-md shadow-sm">
            <ul className="flex items-center justify-center border-t px-4 py-3 sm:px-6">
              {currentPage !== 1 && currentPage !== 2 && (
                <>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={goToFirstPage}
                      aria-label="First"
                    >
                      First
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={prePage}
                      aria-label="Previous"
                    >
                      Previous
                    </Link>
                  </li>
                </>
              )}
              {numbers.map(
                (n, i) =>
                  (n === currentPage ||
                    n === currentPage - 1 ||
                    n === currentPage + 1) && (
                    <li
                      className={`page-item ${
                        currentPage === n ? "active" : ""
                      }`}
                      key={i}
                    >
                      <Link
                        href="#"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                          currentPage === n ? "bg-gray-200" : ""
                        }`}
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </Link>
                    </li>
                  )
              )}
              {currentPage !== nPages && (
                <>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={nextPage}
                      aria-label="Next"
                    >
                      Next
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={goToLastPage}
                      aria-label="Last"
                    >
                      Last
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="flex justify-end">
            <button
              className="flex items-end justify-center rounded border-gray-600 bg-green-500 px-2 py-1 text-white hover:bg-green-700"
              onClick={exportToExcel}
            >
              Export To Excel
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

export default CustomerReports;
