import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Card from "components/card";
import logo from "./logo.png";
import { Link, useLocation } from "react-router-dom";
import { useAdmin } from "hooks/useAdmin";
import { FaTimes } from "react-icons/fa";

const MultiCityQuotPrint = () => {
  const {
    getCombinedQuotationData,
    quotationCombinedData,
    reqAccountData,
    accountData,
    reqSettingsData,
    settingdata,
    getLeadDetails,
    leadData,
    loading,
  } = useAdmin();

  const location = useLocation();
  // const [id, setId] = useState();
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [finalleaddata, setFinalleaddata] = useState("");

  // useEffect(() => {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  //   setId(id);
  // }, [loading]);

  useEffect(() => {
    getCombinedQuotationData();
    reqAccountData();
    reqSettingsData();
    getLeadDetails();
  }, [loading]);

  useEffect(() => {
    filterDataById();
  }, [quotationCombinedData || leadData]);

  const filterDataById = () => {
    if (id && quotationCombinedData && quotationCombinedData.length > 0) {
      const filtered = quotationCombinedData.filter((data) => data._id === id);
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }

    if (leadData && leadData.length > 0) {
      const filteredLead = leadData.filter(
        (data) => data._id === filteredData[0]?.client_id
      );
      setFinalleaddata(filteredLead);
    } else {
      setFinalleaddata([]);
    }
  };

  const [isPrinting, setIsPrinting] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Multicity Quotation",
    onAfterPrint: () => {
      setIsPrinting(false); // Reset print mode after printing
    },
  });

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting, handlePrint]);

  function numberToWordsWithCurrency(num) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const suffixes = ["", "Thousand", "Lakh", "Crore"];

    if (num === 0) return "Zero Rupees Only";

    let words = "";
    let group = 0;

    const numParts = String(num).split(".");
    const integerPart = parseInt(numParts[0], 10);
    const decimalPart = parseInt(numParts[1] || 0, 10);

    // Convert integer part
    if (integerPart > 0) {
      let integerWords = "";
      let remainingNum = integerPart;

      while (remainingNum > 0) {
        let chunk;
        if (group === 0) {
          chunk = remainingNum % 1000;
          remainingNum = Math.floor(remainingNum / 1000);
        } else {
          chunk = remainingNum % 100;
          remainingNum = Math.floor(remainingNum / 100);
        }

        if (chunk !== 0) {
          let chunkWords = "";
          if (chunk >= 100) {
            chunkWords += units[Math.floor(chunk / 100)] + " Hundred ";
            chunk %= 100;
          }
          if (chunk >= 11 && chunk <= 19) {
            chunkWords += teens[chunk - 11] + " ";
            chunk = 0; // Skip tens and units
          } else {
            chunkWords += tens[Math.floor(chunk / 10)] + " ";
            chunk %= 10;
            chunkWords += units[chunk] + " ";
          }

          integerWords = chunkWords + suffixes[group] + " " + integerWords;
        }

        group++;
      }

      words += integerWords.trim() + " Rupees ";
    }

    // Convert decimal part
    if (decimalPart > 0) {
      const decimalWords =
        units[Math.floor(decimalPart / 10)] +
        (decimalPart % 10 !== 0 ? "-" + units[decimalPart % 10] : "") +
        " Paise Only";
      words += "and " + decimalWords;
    } else {
      // If the decimal part is zero, add "Only" at the end
      words += "Only";
    }

    return words.trim();
  }

  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <>
      <Card extra={"w-full pb-10 p-4 h-full"}>
        <div className="flex flex-col items-end justify-end px-4 sm:flex-row sm:px-6 md:px-8">
          <Link
            to="/auth/quotation"
            className="ml-0 mt-2 text-red-600 hover:text-red-700 sm:ml-2 sm:mt-0"
            onClick={toggleShowAll}
            style={{ cursor: "pointer" }}
          >
            <FaTimes size={20} />{" "}
          </Link>
        </div>
        <div className="flex justify-start">
          <button
            type="button"
            className="mt-4 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700"
            onClick={() => setIsPrinting(true)}
          >
            Download PDF
          </button>
        </div>
        <div>
          {filteredData.map((data) => (
            <div
              ref={componentRef}
              className={`overflow border-gray m-auto h-[297mm] w-[210mm] border ${
                isPrinting ? "print-page" : ""
              }`}
              style={{ padding: "20px 20px" }}
            >
              <div
                className="flex items-center justify-between  p-3"
                style={{ backgroundColor: "#a1a5ad" }}
              >
                <img src={logo} alt="Company Logo" className="w-25 mr-2 h-20" />
                <div className="flex flex-col">
                  <h2 className="mr-4 text-lg font-bold text-white">
                    Quotation Package Type: {data.package_type}
                  </h2>
                  <h2 className="mr-4 text-lg font-bold text-white">
                    Quotation Number: {data.quotation_number}
                  </h2>
                  <h2 className="mr-4 text-lg font-bold text-white">
                    Quotation Date:{" "}
                    {new Date(data.date_time).toLocaleDateString("en-IN")}
                  </h2>
                </div>
              </div>
              <div className="mb-4">
                <table className="w-full border-collapse border shadow-sm">
                  <tbody>
                    <tr>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        Trip Type
                      </td>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        {data.trip_type}
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        Number of Adults
                      </td>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        {data.no_of_adult}
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        Number of Children
                      </td>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        {data.no_of_children}
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        Quotation Generate Date
                      </td>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        {new Date(data.start_date).toLocaleDateString("en-IN")}
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        Quotation Expiry Date
                      </td>
                      <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                        {new Date(data.end_date).toLocaleDateString("en-IN")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mb-4">
                <h2 className="mb-2 text-center text-lg font-bold underline">
                  Flight Details
                </h2>
                <table className="whitespace-no-wrap w-full border px-2 py-1">
                  <tbody>
                    {data.multi_city_route.map((route, index) => (
                      <tr key={index}>
                        <td className="whitespace-no-wrap w-1/6 border px-2 py-1 font-semibold">
                          From:
                        </td>
                        <td className="whitespace-no-wrap w-1/6 border px-2 py-1">
                          {route.diparture_city}
                        </td>
                        <td className="whitespace-no-wrap w-1/6 border px-2 py-1 font-bold">
                          To:
                        </td>
                        <td className="whitespace-no-wrap w-1/6 border px-2 py-1">
                          {route.destination_city}
                        </td>
                        <td className="whitespace-no-wrap w-1/6 border px-2 py-1 font-bold">
                          Travel Date:
                        </td>
                        <td className="whitespace-no-wrap w-1/6 border px-2 py-1">
                          {new Date(route.travel_date).toLocaleDateString(
                            "en-IN"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  {/* <tbody>
                    {data.multi_city_route.map((route, index) => (
                      <tr key={index}>
                        <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                          From:
                        </td>
                        <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                          {route.diparture_city}
                        </td>
                        <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                          To:
                        </td>
                        <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                          {route.destination_city}
                        </td>
                        <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                          Travel Date:
                        </td>
                        <td className="whitespace-no-wrap w-1/2 border px-2 py-1">
                          {new Date(route.travel_date).toLocaleDateString(
                            "en-IN"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody> */}
                </table>
              </div>
              <div>
                <table className="mb-4 w-full border-collapse border  shadow-sm">
                  <tbody>
                    <tr>
                      <td className="text-nowrap border border-gray-900 px-2 py-1 font-bold">
                        Total Amount in Numbers
                      </td>
                      <td className="border border-gray-900 px-2 py-1">
                        {parseFloat(data.deficit).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-900 px-2 py-1 font-bold">
                        Total Amount in Words
                      </td>
                      <td className="border border-gray-900 px-2 py-1">
                        {parseFloat(data.deficit) > 0
                          ? numberToWordsWithCurrency(parseFloat(data.deficit))
                          : "Zero Rupees"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <h2
                  className="mb-2 mt-5 text-start text-lg font-bold"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  Bank Details
                </h2>

                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse divide-y divide-gray-900 border  shadow-sm">
                    <thead>
                      <tr>
                        <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
                          Account Holder Name
                        </th>
                        <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
                          Bank Name
                        </th>
                        <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
                          Account Number
                        </th>
                        <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
                          IFSC Code
                        </th>

                        <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
                          Branch Name/Address
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200  shadow-sm">
                      <tr>
                        <td className="w-64 border border-gray-900 px-2 py-1">
                          {accountData[0]?.accountholderName}
                        </td>
                        <td className="w-64 border border-gray-900 px-2 py-1">
                          {accountData[0]?.banckname}
                        </td>
                        <td className="w-64 border border-gray-900 px-2 py-1">
                          {accountData[0]?.accountNumber}
                        </td>
                        <td className="w-64 border border-gray-900 px-2 py-1">
                          {accountData[0]?.ifsccode}
                        </td>
                        <td className="w-64 border border-gray-900 px-2 py-1">
                          {accountData[0]?.branchname}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-sm font-light">
                    * This is computer-generated quotation and does not require
                    a signature.
                  </div>
                </div>
              </div>

              <div className="mt-10 p-2">
                <div className="mb-5  md:flex-row md:justify-between">
                  <div>
                    <p className="font-bold">{settingdata[0]?.agencyName}</p>
                  </div>
                  <div className="mb-5  md:mb-0">
                    <p className="font-bold">
                      Address : {settingdata[0]?.address}
                    </p>
                  </div>
                  <div className="mb-5 flex flex-col md:mb-0">
                    <p className="font-bold">
                      Phone : {settingdata[0]?.phonenum}{" "}
                    </p>
                  </div>
                  <div className="mb-5 flex flex-col md:mb-0">
                    <p className="font-bold">
                      Email : {settingdata[0]?.company_person_email}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p className="mt-10 text-center">
                  Thank you for giving us an opportunity to serve you!
                </p>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default MultiCityQuotPrint;
