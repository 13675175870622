import Image from "./pic.png";
import { useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const { logIn } = useAuth();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    logIn(formData);

   
  };


  return (
    <>
      <ToastContainer />
      <section class="flex min-h-screen items-center justify-center bg-gray-50">
        <div class="flex max-w-3xl items-center rounded-2xl bg-gray-100 p-5 shadow-lg">
          <div class="hidden w-1/2 md:block">
            <img class="rounded-2xl" src={Image} />
          </div>
          <div class="px-8 md:w-1/2 md:px-16">
            <h2 class="text-2xl font-bold text-[#002D74]">Login</h2>
            <p class="mt-4 text-xs text-[#002D74]">
              Welcome back please enter your details
            </p>

            <form action="" onSubmit={handleSubmit} class="flex flex-col gap-4">
              <input
                class="mt-8 rounded-xl border p-2"
                type="text"
                name="username"
                placeholder="Username"
                onChange={handleInputChange}
                value={formData.username}
              />
              <div class="relative">
                <input
                  class="w-full rounded-xl border p-2"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  class="bi bi-eye absolute right-3 top-1/2 -translate-y-1/2"
                  viewBox="0 0 16 16"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? "Hide" : "Show"}

                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              </div>

              {/* <div class="ml-15 py-4 text-[#FF0000]">
                <Link to="/forget-pass">
                  <u>Forgot Password?</u>
                </Link>
              </div> */}
              <button class="rounded-xl bg-[#FF0000] py-2 text-white duration-300 hover:scale-105">
                Login
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
