import { useEffect, useState, useRef } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import Itinaray from "./Itinaray";
import { toast } from "react-toastify";

export default function HotelBook({ parentData, clientId, name, onClose }) {
  const [netCost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [fileData, setFileData] = useState([]);
  const [gstmarkup, setGstmarkup] = useState("");
  const { AddHotelQuotation } = useAdmin();
  const editor = useRef(null);
  const [disableSubmit, setdisableSubmit] = useState(false);

  const [formData, setFormData] = useState({
    hotel_name: "",
    check_in_date: "",
    check_out_date: "",
    no_of_rooms: "",
    booking_source: "",
    net_cost: "",
    mark_up: "",
    gst_on_markup: "",
    ideal_collection: "",
    actual_collection: "",
    cancellation_charges: "",
    deficit: "",
    refund_amount: "",
  });

  const [editorContents, setEditorContents] = useState({
    itinerary: "",
    overview: "",
    flight: "",
    hotel: "",
    inclusions: "",
    exclusions: "",
    photogallery: "",
    priceguideline: "",
    termscondition: "",
  });

  const handleEditorChangeParent = (editorName, newContent) => {
    setEditorContents((prevContents) => ({
      ...prevContents,
      [editorName]: newContent,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "gstmarkup") {
      setGstmarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };
  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;

    setFormData((prevFormData) => ({
      ...prevFormData,
      gst_on_markup: withgst,
      ideal_collection: idealcost,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  //Validation Code
  const { NetVisaCostValidation, MarkupValidation, NumberOfRoomValidation } =
    Validation();

  // Function to handle discarding changes
  const handleDiscard = () => {
    setFormData({
      hotel_name: "",
      check_in_date: "",
      check_out_date: "",
      no_of_rooms: "",
      booking_source: "",
      net_cost: "",
      mark_up: "",
      gst_on_markup: "",
      ideal_collection: "",
      actual_collection: "",
      deficit: "",
      cancel_charges: "",
      refund_amount: "",
    });
  };

  const [showForm, setShowForm] = useState(true);
  const handleClose = () => {
    onClose();
    setShowForm(false);
  };

  function validateForm() {
    if (
      formData.hotel_name !== "" &&
      formData.check_in_date !== "" &&
      formData.check_out_date !== "" &&
      formData.no_of_rooms !== "" &&
      formData.booking_source !== "" &&
      formData.net_cost !== "" &&
      formData.mark_up !== "" &&
      formData.gst_on_markup !== "" &&
      formData.ideal_collection !== "" &&
      formData.deficit !== ""
    ) {
      return true;
    }
    return false;
  }

  const handleSubmit = async (e) => {
    setdisableSubmit(true);

    e.preventDefault();
    const finalData = new FormData();
    finalData.append("formData", JSON.stringify(formData));
    finalData.append("parentData", JSON.stringify(parentData));
    finalData.append("clientId", JSON.stringify(clientId));
    finalData.append("editorContents", JSON.stringify(editorContents));
    finalData.append("name", JSON.stringify(name));
    fileData.forEach((file) => {
      finalData.append("document", file);
    });
    if (validateForm() === true) {
      await AddHotelQuotation(finalData);
      setInterval(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Please fill manadatory field");
      setdisableSubmit(false); // Enable the submit button
    }
  };

  return (
    <>
      {showForm ? (
        <div className="relative">
          {/* <div class="fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0"> */}
          <div>
            <div className="z-50 rounded-bl-lg rounded-br-lg bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                {" "}
                <FaTimes size={24} />{" "}
              </span>
              <h2 className="mb-4 text-center text-lg font-semibold underline">
                Hotel Booking
              </h2>

              <form onSubmit={handleSubmit}>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label
                      for="hotelname"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Hotel Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="hotel_name"
                        id="hotel_name"
                        autocomplete="hotel_name"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.hotel_name}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="checkIn"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Check In <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="date"
                        name="check_in_date"
                        id="check_in_date"
                        autocomplete="check_in_date"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.check_in_date}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="numofRooms"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Number of Rooms <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="no_of_rooms"
                        id="no_of_rooms"
                        autocomplete="no_of_rooms"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.no_of_rooms}
                        onKeyUp={NumberOfRoomValidation}
                      />
                      <span id="numberOfRoomValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="checkOut"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Check Out <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="date"
                        name="check_out_date"
                        id="check_out_date"
                        autocomplete="check_out_date"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.check_out_date}
                        min={formData.check_in_date}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="bookingsource"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Booking Source <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="booking_source"
                        id="booking_source"
                        autocomplete="bookingsource"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.booking_source}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="netcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="net_cost"
                        id="net_cost"
                        autocomplete="net_cost"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.net_cost}
                        onKeyUp={NetVisaCostValidation}
                      />
                      <span id="netVisaCostValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="markup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="mark_up"
                        id="mark_up"
                        autocomplete="mark_up"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.mark_up}
                        onKeyUp={MarkupValidation}
                      />
                      <span id="MarkupValidation"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gstmarkup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      GST 18% on Markup
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="gst_on_markup"
                        id="gst_on_markup"
                        autocomplete="gst_on_markup"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gst_on_markup}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="idealcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="idealcollection"
                        id="idealcollection"
                        autocomplete="idealcollection"
                        class="ring-grey-300  block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 
                                 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="deficit"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="deficit"
                        id="deficit"
                        autocomplete="deficit"
                        class="ring-grey-300  block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 
                          ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <Itinaray
                    ref={editor}
                    editorContents={editorContents}
                    onEditorChange={handleEditorChangeParent}
                    setFileData={setFileData}
                  />
                </div>

                <div className="mt-4 flex flex-wrap items-center justify-center">
                  <button
                    className="rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                  >
                    Save
                  </button>
                  <div className="mx-4 sm:mx-4"></div>
                  <button
                    onClick={handleDiscard}
                    className="rounded border-gray-500 bg-red-500 px-3 py-1 text-white hover:bg-red-700 "
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* </div> */}
        </div>
      ) : null}
    </>
  );
}
