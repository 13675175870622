import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Validation } from "./components/Validation";
import { useAdmin } from "../../../hooks/useAdmin";

const NewLeedForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    note: "",
    country: "",
    state: "",
    city: "",
    firm_name: "",
    cust_gst: "",
  });

  const [disableSubmit, setdisableSubmit] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [citites, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const { saveLeadDetails } = useAdmin();
  const [documentType, setDocumentType] = useState("");
  const [adharPhoto, setAdharPhoto] = useState(null);
  const [adharBackPhoto, setAdharBackPhoto] = useState(null);
  const [panPhoto, setPanPhoto] = useState(null);
  const [passportBackPhoto, setPassportBackPhoto] = useState(null);
  const [passportFrontPhoto, setPassportFrontPhoto] = useState(null);
  const [licencePhoto, setLicencePhoto] = useState(null);
  const [birthCerificate, setBirthCerificate] = useState(null);

  const [errorMsg, setErrorMsg] = useState({
    name: "",
  });

  const handleNameValidation = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const errorMsg = !condition.test(value) ? "Enter only alphabets" : "";
    setErrorMsg((prevState) => ({
      ...prevState,
      [name]: errorMsg,
    }));
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (["name"].includes(name)) {
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setErrorMsg((prevState) => ({
          ...prevState,
          [name]: "Only alphabetic characters are allowed.",
        }));
      }
    } else {
      setFormData((formData) => ({ ...formData, [name]: value }));
      if (files) {
        const filesArray = Array.from(files);
        const maxSize = 4 * 1024 * 1024;
        const filteredFiles = filesArray.filter((file) => file.size <= maxSize);
        const oversizedFiles = filesArray.filter((file) => file.size > maxSize);
        if (oversizedFiles.length > 0) {
          alert("Please add files less than 4 MB.");
          e.target.value = "";
          return;
        }

        if (value === "adhar" || value === "passport") {
          const adharDocuments = documents.filter(
            (doc) => doc.documentType === "adhar"
          );
          if (adharDocuments.length >= 1) {
            alert("Only one document is allowed for 'adhar' type.");
            return;
          }
        }
        setDocuments([...documents, ...filteredFiles]);
      }
    }
  };

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };
  const handleAdharPhotoChange = (event) => {
    setAdharPhoto(event.target.files[0]);
  };
  const handleAdharBackPhotoChange = (event) => {
    setAdharBackPhoto(event.target.files[0]);
  };
  const handlePanPhotoChange = (event) => {
    setPanPhoto(event.target.files[0]);
  };
  const handlePassportPhotoChange = (event) => {
    setPassportBackPhoto(event.target.files[0]);
  };
  const handleFrontPassportPhotoChange = (event) => {
    setPassportFrontPhoto(event.target.files[0]);
  };
  const handleLicencePhotoChange = (event) => {
    setLicencePhoto(event.target.files[0]);
  };
  const handleBirthPhotoChange = (event) => {
    setBirthCerificate(event.target.files[0]);
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  function getAllCountries() {
    setTimeout(() => {
      setStates([]);
      setCitites([]);
    }, 1000);
    axios
      .get("https://countriesnow.space/api/v0.1/countries/positions")
      .then((result) => {
        setCountries(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getStateFromCountries(country) {
    setCountry(country);
    axios
      .post("https://countriesnow.space/api/v0.1/countries/states", {
        country: country,
      })
      .then((result) => {
        setStates(result.data.data.states);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getCititiesFromState(state) {
    axios
      .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
        country: country,
        state: state,
      })
      .then((result) => {
        setCitites(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleSubmit = async (e) => {
    setdisableSubmit(true);
    e.preventDefault();
    if (submitting || !formDataHasValues()) return;
    setSubmitting(true);
    const data = new FormData();
    data.append("formData", JSON.stringify(formData));
    documents.forEach((file, index) => {
      data.append(`lead_documents`, file);
    });

    if (data !== null) {
      saveLeadDetails(data)
        .then(() => {
          setSubmitting(false);
          setInterval(() => {
            window.location.reload();
          }, 100);
        })
        .catch((error) => {
          console.error("Save lead error:", error);
          setSubmitting(false);
        });
    }
  };

  const formDataHasValues = () => {
    return Object.values(formData).some((value) => value !== "");
  };

  const {
    FullnameValidation,
    NumberValidation,
    EmailValidation,
    CustomerGSTnumberValidation,
    FirmNameValidation,
  } = Validation();

  return (
    <>
      <div className="relative">
        <div className="fixed inset-0 z-50 m-1 flex items-center justify-center overflow-scroll bg-gray-900 bg-opacity-50 pb-10 pt-20 backdrop-blur">
          <div
            className="z-50 w-full max-w-2xl rounded-xl bg-gray-200 p-6 shadow-xl"
            style={{ marginTop: "60px" }}
          >
            <h2 className="mb-2 text-center text-lg font-semibold underline">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                <FaTimes size={24} />
              </span>
              New Lead Form
            </h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label htmlFor="name" className="mb-1 block">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  onKeyUp={handleNameValidation}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer Name"
                  required
                />
              </div>
              <span style={{ color: "red" }}>{errorMsg.name}</span>
              <div className="mb-2">
                <label htmlFor="mobileNumber" className="mb-1 block">
                  Contact Number
                </label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer Contact Number"
                  required
                  maxLength={10}
                  minLength={10}
                  onKeyUp={NumberValidation}
                />
              </div>
              <span id="Number"></span>
              <div className="mb-2">
                <label htmlFor="email" className="mb-1 block">
                  Email ID
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onKeyUp={EmailValidation}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer Email"
                  required
                />
              </div>
              <span id="Email"></span>

              <div className="mb-2">
                <label htmlFor="city" className="mb-1 block">
                  City
                </label>
                <input
                  type="city"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer City"
                  required
                />
              </div>
              <span id="Email"></span>

              <div className="mb-2">
                <label htmlFor="firm_name" className="mb-1 block">
                  Firm Name
                </label>
                <input
                  type="firm_name"
                  id="firm_name"
                  name="firm_name"
                  value={formData.firm_name}
                  onChange={handleChange}
                  // onKeyUp={FirmNameValidation}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Firm Name"
                  // required
                />
              </div>
              <span id="FirmName"></span>

              <div className="mb-2">
                <label htmlFor="cust_gst" className="mb-1 block">
                  Customer GST Number
                </label>
                <input
                  type="cust_gst"
                  id="cust_gst"
                  name="cust_gst"
                  value={formData.cust_gst}
                  onChange={handleChange}
                  onKeyUp={CustomerGSTnumberValidation}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Enter Customer GST Number"
                  // required
                  // style={}
                />
              </div>
              <span id="custgst"></span>

              <div className="mt-2 flex flex-col items-start space-y-2">
                <h6>Select Document Type:</h6>
                <select
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  value={documentType}
                  onChange={handleDocumentTypeChange}
                >
                  <option value="">Select Document Type</option>
                  <option value="adhar">Aadhar Card</option>
                  <option value="pan">PAN Card</option>
                  <option value="passport">Passport</option>
                  <option value="licence">License</option>
                  <option value="birthcertificate">Birth Certificate</option>
                </select>

                {documentType === "adhar" && (
                  <div className="y-2 flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0">
                    <div className="flex flex-col items-center sm:items-start">
                      <h6 className="ml-4 sm:ml-0">Front Photo:</h6>
                      <input
                        type="file"
                        id="adhar_front_photo"
                        name="adhar_front_photo"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                        className="appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800"
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                    <div className="flex flex-col items-center sm:items-start">
                      <h6 className="ml-4 sm:ml-0">Back Photo:</h6>
                      <input
                        type="file"
                        id="adhar_back_photo"
                        name="adhar_back_photo"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                        className="appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800"
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                  </div>
                )}
                {documentType === "passport" && (
                  <div className="y-2 flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0">
                    <div className="flex flex-col items-center sm:items-start">
                      <h6 className="ml-4 sm:ml-0">Front Photo:</h6>
                      <input
                        type="file"
                        id="passport_front_photo"
                        name="passport_front_photo"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                        className="appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800"
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                    <div className="flex flex-col items-center sm:items-start">
                      <h6 className="ml-4 sm:ml-0">Back Photo:</h6>
                      <input
                        type="file"
                        id="passport_back_photo"
                        name="passport_back_photo"
                        onChange={handleChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                        className="appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800"
                        style={{ maxWidth: "200px" }}
                      />
                    </div>
                  </div>
                )}
                {documentType === "pan" && (
                  <div className="flex flex-col items-center space-y-2 sm:items-start sm:space-x-4 sm:space-y-0">
                    <h6 className="ml-4 sm:ml-0">PAN Card Photo:</h6>
                    <input
                      type="file"
                      className="w-full appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800 sm:w-auto"
                      id="pan_card_photo"
                      name="pan_card_photo"
                      onChange={handleChange}
                      accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    />
                  </div>
                )}
                {documentType === "licence" && (
                  <div className="flex flex-col items-center space-y-2 sm:items-start sm:space-x-4 sm:space-y-0">
                    <h6 className="ml-4 sm:ml-0">License Photo:</h6>
                    <input
                      type="file"
                      className="w-full appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800 sm:w-auto"
                      id="licence_photo"
                      name="licence_photo"
                      onChange={handleChange}
                      accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    />
                  </div>
                )}
                {documentType === "birthcertificate" && (
                  <div className="flex flex-col items-center space-y-2 sm:items-start sm:space-x-4 sm:space-y-0">
                    <h6 className="ml-4 sm:ml-0">Birth Certificate:</h6>
                    <input
                      type="file"
                      className="w-full appearance-none rounded-lg border-b border-gray-300 bg-gray-300 px-3 py-2 text-sm outline-none hover:border-gray-800 focus:border-gray-800 sm:w-auto"
                      id="birthcertificate"
                      name="birthcertificate"
                      onChange={handleChange}
                      accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    />
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label htmlFor="Note" className="mb-1 block">
                  Notes
                </label>
                <input
                  type="text"
                  id="note"
                  name="note"
                  maxLength={50}
                  minLength={5}
                  value={formData.note}
                  onChange={handleChange}
                  className="w-full rounded-lg border-gray-800 px-3 py-2"
                  placeholder="Add a Note "
                  required
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={disableSubmit}
                  className="mt-2 rounded-lg bg-blue-500 px-4 py-2 text-white"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLeedForm;

{
  /* <div className="mb-2 flex flex-wrap gap-2">
                <div className="mb-2">
                  <label htmlFor="country" className="mb-1 block">
                    Country
                  </label>
                  <select
                    className="mt-2 block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-indigo-500 sm:text-sm"
                    style={{ width: "150px" }}
                    id="country"
                    name="country"
                    onChange={(e) => {
                      const selectedCountry = e.target.value;
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        country: selectedCountry,
                      }));
                      getStateFromCountries(selectedCountry);
                    }}
                    value={formData.country}
                    required // Added required attribute
                  >
                    <option value="">Select Country</option>{" "}
                    {/* Added value attribute */
}
//     {countries.map((country, index) => {
//       return (
//         <option
//           key={index}
//           value={country.name}
//           style={{ width: "150px" }}
//         >
//           {" "}
//           {/* Added key and value attributes */}
//           {country.name}
//         </option>
//       );
//     })}
//   </select>
// </div>

// <div className="mb-2">
//   <label htmlFor="city" className="mb-1 block">
//     State
//   </label>
//   <select
//     className="mt-2 block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 sm:text-sm"
//     style={{ width: "150px" }}
//     onChange={(e) => {
//       const selectedState = e.target.value;
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         state: selectedState,
//       }));
//       getCititiesFromState(selectedState);
//     }}
//     value={formData.state}
//   >
//     <option>Select State</option>
//     {states.map((state, index) => {
//       return <option id={index}>{state.name}</option>;
//     })}
//   </select>
// </div>
// <div className="mb-2">
//   <label htmlFor="city" className="mb-1 block">
//     City
//   </label>
//   <select
//     className="mt-2 block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 sm:text-sm"
//     style={{ width: "150px" }}
//     onChange={(e) => {
//       const selectedCity = e.target.value;
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         city: selectedCity,
//       }));
//       getCititiesFromState(e);
//     }}
//     value={formData.city}
//   >
//     <option>Select City</option>

//     {citites.map((city, index) => {
//       return <option id={index}>{city}</option>;
//     })}
//   </select>
// </div>
// </div> */}
