export const Validation = () => {
  return {
    //Student Registration Validation

    // Validation for FirstName
    FirstnameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("first");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("first");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("first");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Validation for LastName
    // LastnameValidation: (e) => {
    //   const Name = e.target.value;
    //   var condition = /^[a-zA-Z\s]+$/;
    //   var result = Name.match(condition);
    //   // Check  the all conditon
    //   if (result === null) {
    //     var messege = document.getElementById("last");
    //     messege.innerHTML = "Enter only Alphabet";
    //     messege.style.color = "red";
    //   } else if (result != null) {
    //     var messege = document.getElementById("last");
    //     messege.innerHTML = "";
    //     messege.style.color = "";
    //   }
    //   if (e.target.value === "") {
    //     var messege = document.getElementById("last");
    //     messege.innerHTML = "";
    //     messege.style.color = "";
    //   }
    // },
    LastnameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/; // Updated regex pattern
      var result = Name.match(condition);

      // Check the all condition
      if (result === null) {
        var messege = document.getElementById("last");
        messege.innerHTML = "Enter only alphabetic characters";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("last");
        messege.innerHTML = "";
        messege.style.color = "";
      }

      if (e.target.value === "") {
        var messege = document.getElementById("last");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    FullnameValidation: (e) => {
      const fullName = e.target.value;
      const condition = /^[a-zA-Z\s]+$/;
      const result = fullName.match(condition);

      if (result === null) {
        var message = document.getElementById("fullnamevalidation");
        message.innerHTML = "Enter only alphabets and spaces";
        message.style.color = "red";
      } else {
        var message = document.getElementById("fullnamevalidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("fullnamevalidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    AmountValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("amountValidation");
        // console.log(message)
        message.innerHTML = "Enter only numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("amountValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("amountValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },
    // Validation for Mobile Number
    NumberValidation: (e) => {
      const Number = e.target.value;
      // Check  the all conditon
      const regex = /^\d{10}$/;

      if (!regex.test(Number)) {
        var messege = document.getElementById("Number");
        messege.innerHTML = "Invalid Number:Only 10 Digit";
        messege.style.color = "red";
      } else {
        var messege = document.getElementById("Number");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("Number");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    //Note Validation

    NoteValidation: (e) => {
      const fullName = e.target.value;
      const condition = /^[a-zA-Z0-9\s]+$/;
      const result = fullName.match(condition);

      if (result === null) {
        var message = document.getElementById("notevalidation");
        message.innerHTML = "Enter only alphabets and spaces";
        message.style.color = "red";
      } else {
        var message = document.getElementById("notevalidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("notevalidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //PackageName Validation

    PackageNameValidation: (e) => {
      const fullName = e.target.value;
      const condition = /^[a-zA-Z\s]+$/;
      const result = fullName.match(condition);

      if (result === null) {
        var message = document.getElementById("packagenamevalidation");
        message.innerHTML = "Enter only alphabets and spaces";
        message.style.color = "red";
      } else {
        var message = document.getElementById("packagenamevalidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("packagenamevalidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    // Validation for Aadharnumber

    AadharValidation: (e) => {
      const Number = e.target.value;
      // Check  the all conditon
      const regex = /^\d{12}$/;

      if (!regex.test(Number)) {
        var messege = document.getElementById("AadharNumber");
        messege.innerHTML = "Invalid  Aadhar Number";
        messege.style.color = "red";
      } else {
        var messege = document.getElementById("AadharNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("AadharNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    EmailValidation: (e) => {
      const data = e?.target?.value;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]/;
      const isValidEmail = emailRegex.test(data);
      var message = document.getElementById("Email");

      if (!message) {
        console.error("Element with ID 'Email' not found");
        return;
      }

      // Check the conditions
      if (!data) {
        message.innerHTML = "Please enter an email";
        message.style.color = "red";
      } else if (isValidEmail) {
        message.innerHTML = "";
        message.style.color = "";
      } else {
        message.innerHTML = "Invalid Email";
        message.style.color = "red";
      }
    },

    // Validation for password

    validatePassword: (e) => {
      const minLength = 8;
      var data = e.target.value;

      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const digitRegex = /\d/;
      const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
      // Check  the all conditon
      if (
        uppercaseRegex.test(data) === true &&
        lowercaseRegex.test(data) === true &&
        digitRegex.test(data) === true &&
        specialCharRegex.test(data) === true &&
        data.length >= minLength
      ) {
        var messege = document.getElementById("Password");
        messege.innerHTML = "";
        messege.style.color = "red";
      } else {
        var messege = document.getElementById("Password");
        messege.innerHTML =
          "Requires at least 8 characters, including  letters numbers and special character.";
        messege.style.color = "red";
      }

      if (e.target.value === "") {
        var messege = document.getElementById("Password");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Company Registration Validation

    // Validation for CompanyName
    CompanynameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z0-9\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("companyname");
        messege.innerHTML = "Enter only Alphabet and number";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("companyname");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("companyname");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    // bank name validation

    BankNameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("bbb");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("bbb");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("bbb");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Account number validation

    AccountNumberValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[0-9]{6,}$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("accountNumber");
        messege.innerHTML = "Enter only number";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("accountNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("accountNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    AccountHolderNameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("holderName");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("holderName");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("holderName");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // IFSC code validation

    IFSCCodeValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[A-Za-z]{4}[0][0-9A-Za-z]{6}$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("ifscCode");
        messege.innerHTML = "Not Valid IFSC code";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("ifscCode");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("ifscCode");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Validation for City
    CitynameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("City");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("City");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("City");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    FromValidation: (e) => {
      const Name = e.target.value.trim(); // Trim leading and trailing spaces
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check the condition
      if (result === null) {
        var message = document.getElementById("FromValidation");
        message.innerHTML = "Enter only letters and spaces";
        message.style.color = "red";
      } else {
        var message = document.getElementById("FromValidation");
        message.innerHTML = ""; // Clear previous error message
        message.style.color = "";
      }
      // Check if the input is empty
      if (Name === "") {
        var message = document.getElementById("FromValidation");
        message.innerHTML = "City name is required";
        message.style.color = "red";
      }
    },
    // Validation for StateName
    StatenameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("StateName");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("StateName");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("StateName");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    // Validation for ContryName
    ContrynameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("countri");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("countri");
        messege.innerHTML = "";
        messege.style.color = "red";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("countri");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Validation for Pancard Number

    PanCardValidation: (e) => {
      const Number = e.target.value;
      // Check  the all conditon
      const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

      // Check if the PAN number matches the pattern
      if (panPattern.test(Number) === true) {
        var messege = document.getElementById("Pancard");
        messege.innerHTML = "";
        messege.style.color = "";
      } else {
        var messege = document.getElementById("Pancard");
        messege.innerHTML = "Invalid PAN number. Please check the format.";
        messege.style.color = "red";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("Pancard");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    // Validation for GST Number

    GSTnumberValidation: (e) => {
      const Number = e.target.value;
      // Check  the all conditon
      const gstPattern =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

      // Check if the PAN number matches the pattern
      if (gstPattern.test(Number) === true) {
        var messege = document.getElementById("gstnumber");
        messege.innerHTML = "";
        messege.style.color = "";
      } else {
        var messege = document.getElementById("gstnumber");
        messege.innerHTML = "Invalid GST number. Please check the format.";
        messege.style.color = "red";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("gstnumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    PostalcodeValidation: (e) => {
      const postalCode = e.target.value;
      const postalCodePattern = /^\d{6}$/;

      // Check if the postal code matches the pattern
      if (postalCodePattern.test(postalCode)) {
        var messege = document.getElementById("postalcode");
        messege.innerHTML = "";
        messege.style.color = "";
      } else {
        var messege = document.getElementById("postalcode");
        messege.innerHTML = "Invalid .Please check the format.";
        messege.style.color = "red";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("postalcode");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    AddressValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z0-9\s]+$/; // Updated regular expression to allow spaces
      var result = Name.match(condition);

      // Check the condition
      if (result === null && Name !== "") {
        var message = document.getElementById("address");
        message.innerHTML = "Enter only alphabets, numbers, and spaces";
        message.style.color = "red";
      } else {
        var message = document.getElementById("address");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    WebValidation: (e) => {
      const link = e.target.value;
      var condition = /^(ftp|http|https):\/\/[^ "]+$/;
      var result = link.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("url");
        messege.innerHTML = "Invalid URL. Please enter a valid URL.";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("url");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("url");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    InstaValidation: (e) => {
      const link = e.target.value;
      var condition =
        /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+\/?$/;
      var result = link.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("instaurl");
        messege.innerHTML =
          "Invalid Instagram URL. Please enter a valid Instagram URL.";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("instaurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("instaurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    FacebookValidation: (e) => {
      const link = e.target.value;
      var condition = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_]+\/?$/;
      var result = link.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("faceurl");
        messege.innerHTML =
          "Invalid Facebook URL. Please enter a valid Facebook URL.";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("faceurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("faceurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    TwitterValidation: (e) => {
      const link = e.target.value;
      var condition = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/;
      var result = link.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("twitterurl");
        messege.innerHTML =
          "Invalid Twitter URL. Please enter a valid Twitter URL.";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("twitterurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("twitterurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    LinkdinValidation: (e) => {
      const link = e.target.value;
      var condition =
        /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
      var result = link.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("linkdinurl");
        messege.innerHTML =
          "Invalid LinkedIn URL. Please enter a valid LinkedIn URL.";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("linkdinurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("linkdinurl");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    ROCValidation: (e) => {
      const roc = e.target.value;

      var urlRegex = /^\d{8}$/;

      if (urlRegex.test(roc)) {
        var messege = document.getElementById("rocNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      } else {
        var messege = document.getElementById("rocNumber");
        messege.innerHTML = "Invalid ROC number: only 8 digit";
        messege.style.color = "red";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("rocNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    //time validation
    TimeValidation: (e) => {
      const TimeName = e.target.value;
      // const condition = /^[a-zA-Z\s]+$/;
      var timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
      const result = TimeName.match(timeRegex);

      if (result === null) {
        var message = document.getElementById("time");
        message.innerHTML = "Enter Valid Time In 00:00 Format";
        message.style.color = "red";
      } else {
        var message = document.getElementById("time");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("time");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //Child Number Validation

    ChildNumberValidation: (e) => {
      const child = e.target.value;
      // const condition = /^[a-zA-Z\s]+$/;
      var timeRegex = /^\d+$/;
      const result = child.match(timeRegex);

      if (result === null) {
        var message = document.getElementById("childnumber");
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else {
        var message = document.getElementById("childnumber");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("childnumber");
        message.innerHTML = "";
        message.style.color = "";
      }
    },
    //Adults Number Validation

    AdultNumberValidation: (e) => {
      const child = e.target.value;
      // const condition = /^[a-zA-Z\s]+$/;
      var timeRegex = /^\d+$/;
      const result = child.match(timeRegex);

      if (result === null) {
        var message = document.getElementById("adultnumber");
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else {
        var message = document.getElementById("adultnumber");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("adultnumber");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //Only Number Validation
    OnlyNumberValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("onlyNumber");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("onlyNumber");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("onlyNumber");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    // Validation for Priority
    PriorityValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("prioriti");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("prioriti");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("prioriti");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Validation for Status
    StatusValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("Status");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("Status");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("Status");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    //Duration Validation
    DurationValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("Duration");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("Duration");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("Duration");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //Netcost Validation
    NetCostValidation: (e) => {
      const child = e.target.value;
      const condition = /^[+-]?\d+(\.\d+)?$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("NetCostValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("NetCostValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("NetCostValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //ROE Validation
    ROEValidation: (e) => {
      const child = e.target.value;
      const condition = /^[+-]?\d+(\.\d+)?$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("ROE");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("ROE");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("ROE");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //NetVisaCostValidation
    NetVisaCostValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("netVisaCostValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("netVisaCostValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("netVisaCostValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //Amount IN INR Validation
    AmountININRValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("amountININRValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("amountININRValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("amountININRValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //VendorNameCost
    VendornameValidation: (e) => {
      const fullName = e.target.value;
      const condition = /^[a-zA-Z\s]+$/;
      const result = fullName.match(condition);

      if (result === null) {
        var message = document.getElementById("vendornameValidation");
        message.innerHTML = "Enter only alphabets";
        message.style.color = "red";
      } else {
        var message = document.getElementById("vendornameValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("vendornameValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //NetInsuranceCost Validation
    NetInsuranceCost: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("netInsuranceCost");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("netInsuranceCost");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("netInsuranceCost");
        message.innerHTML = "";
        message.style.color = "";
      }
    },
    //Pincode validation
    PinCodeValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("pincodeval");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("pincodeval");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("pincodeval");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //Account Number Validation
    AccountNumberValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("AccountNumberValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("AccountNumberValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("AccountNumberValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //MarkUp Validation
    //MarkUp Validation
    //MarkUp Validation
    MarkUpValidation: (e) => {
      const child = e.target.value;
      const condition = /^\d+(\.\d+)?(\s+\d+(\.\d+)?)*$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("markUpValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("markUpValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("markUpValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //ActualCollection Validation
    ActualCollectionValidation: (e) => {
      const child = e.target.value;
      const condition = /^\d+(\.\d+)?$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("actualCollectionValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("actualCollectionValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("actualCollectionValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    MarkupValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("MarkupValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("MarkupValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("MarkupValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },
    //Number Of Person
    NumberOfValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("NumberOfValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("NumberOfValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("MarkupValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //ActualCollection Validation
    ActualCollectionValidation: (e) => {
      const child = e.target.value;
      const condition = /^\d+(\.\d+)?$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("actualCollectionValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("actualCollectionValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("actualCollectionValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    PersonNumberValidation: (e) => {
      const child = e.target.value;
      const condition = /^\d+(\.\d+)?$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("PersonNumberValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("PersonNumberValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("PersonNumberValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //ActualCollection Validation
    MarkupValidation: (e) => {
      const child = e.target.value;
      const condition = /^\d+(\.\d+)?$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("MarkupValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("MarkupValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("MarkupValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //Discount Validation
    DiscountValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("discountValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("discountValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("discountValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //GrrosCost Validation
    GrrosCostValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("grrosCostValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("grrosCostValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("grrosCostValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //cancelationChargesValidation
    CancelationChargesValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("cancelationChargesValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("cancelationChargesValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("cancelationChargesValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //Refund Amount Validation
    RefundAmountValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("refundAmountValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("refundAmountValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("refundAmountValidatior");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //NumberOfRoom Validation
    NumberOfRoomValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("numberOfRoomValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("numberOfRoomValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("numberOfRoomValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    //NumberOfPerson Validation
    NumberOfPersonValidation: (e) => {
      const child = e.target.value;
      const condition = /^[0-9]+$/;
      //    const timeRegex =/^[0-9]+$/;
      const result = child.match(condition);

      if (result === null) {
        // console.log(result);
        var message = document.getElementById("numberOfPersonValidation");
        // console.log(message)
        message.innerHTML = "only enter numbers";
        message.style.color = "red";
      } else if (result != null) {
        var message = document.getElementById("numberOfPersonValidation");
        message.innerHTML = "";
        message.style.color = "";
      }

      if (e.target.value === "") {
        var message = document.getElementById("numberOfPersonValidation");
        message.innerHTML = "";
        message.style.color = "";
      }
    },

    // Validation for To
    ToValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("toV");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("toV");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("toV");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    // Validation for Airline
    AirlineValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("airlineV");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("airlineV");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("airlineV");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Validation for PRN
    PNRValidation: (e) => {
      const Number = e.target.value;
      const regex = /^[A-Za-z0-9]{10}$/;
      if (!regex.test(Number)) {
        var messege = document.getElementById("pnrV");
        messege.innerHTML = "Enter 10 Characters(Alphanumeric)";
        messege.style.color = "red";
      } else {
        var messege = document.getElementById("pnrV");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("pnrV");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    //Validation for phone number
    PhoneAlternateNumber: (e) => {
      const Number = e.target.value;
      // Check  the all conditon
      const regex = /^\d{10}$/;

      if (!regex.test(Number)) {
        var messege = document.getElementById("AltNumber");
        messege.innerHTML = "Invalid Number:Only 10 Digit";
        messege.style.color = "red";
      } else {
        var messege = document.getElementById("AltNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("AltNumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    AlternateNumberValidation: (e) => {
      const Number = e.target.value;
      // Check  the all conditon
      const regex = /^\d{10}$/;

      if (!regex.test(Number)) {
        var messege = document.getElementById("ANumber");
        messege.innerHTML = "Invalid Number:Only 10 Digit";
        messege.style.color = "red";
      } else {
        var messege = document.getElementById("ANumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("ANumber");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    FirmNameValidation: (e) => {
      const Name = e.target.value;
      var condition = /^[a-zA-Z\s]+$/;
      var result = Name.match(condition);
      // Check  the all conditon
      if (result === null) {
        var messege = document.getElementById("FirmName");
        messege.innerHTML = "Enter only Alphabet";
        messege.style.color = "red";
      } else if (result != null) {
        var messege = document.getElementById("FirmName");
        messege.innerHTML = "";
        messege.style.color = "";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("FirmName");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },
    CustomerGSTnumberValidation: (e) => {
      const Number = e.target.value;
      // Check  the all conditon
      const gstPattern =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

      // Check if the PAN number matches the pattern
      if (gstPattern.test(Number) === true) {
        var messege = document.getElementById("custgst");
        messege.innerHTML = "";
        messege.style.color = "";
      } else {
        var messege = document.getElementById("custgst");
        messege.innerHTML = "Invalid GST number. Please check the format.";
        messege.style.color = "red";
      }
      if (e.target.value === "") {
        var messege = document.getElementById("custgst");
        messege.innerHTML = "";
        messege.style.color = "";
      }
    },

    // Validation for HSN code 

    HSNCodeValidation: (e) => {
      const Number = e.target.value;
      // Check if the input is a 6-digit number
      const sixDigitPattern = /^[0-9]{6}$/;
  
      var message = document.getElementById("hsn");
      if (sixDigitPattern.test(Number)) {
          message.innerHTML = "";
          message.style.color = "";
      } else {
          message.innerHTML = "Invalid input. Please enter a 6-digit number.";
          message.style.color = "red";
      }
      if (e.target.value === "") {
          message.innerHTML = "";
          message.style.color = "";
      }
  }

  };
};
