import Card from "components/card";
import { useState, useEffect } from "react";
import "./styles.css";
import { useAdmin } from "../../../hooks/useAdmin";
import { Validation } from "views/admin/marketplace/components/Validation";
import { toast } from "react-toastify";

const ProfileSetting = () => {
  const [activetab, setActivetab] = useState("tab1");
  const [isFormValid, setIsFormValid] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const {
    saveSettingsData,
    reqSettingsData,
    settingdata,

    updateProfileDetails,
  } = useAdmin();
  const handleTab = (tab) => {
    setActivetab(tab);
  };
  const [formData, setFormData] = useState({
    id: "",
    agencyName: "",
    address: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    phonenum: "",
    alternatenum: "",
    company_person_name: "",
    company_person_last_name: "",
    company_person_email: "",
    company_person_mobile: "",
    company_website: "",
  });

  const [documents, setDocuments] = useState({
    companyLogo: null,
  });

  // const handleInputChange = (e) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Check if the input field is one of the specified fields
    if (
      [
        // "agencyName",
        "company_person_name",
        "company_person_last_name",
        "country",
        "state",
        "city",
      ].includes(name)
    ) {
      // Check if the entered value contains only alphabetic characters
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        // You can also set an error message state for this field
        setErrorMsg((prevState) => ({
          ...prevState,
          [name]: "Only alphabetic characters are allowed.",
        }));
      }
    } else {
      // For other fields, update the form data normally
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handlesettingdata = (e) => {
    const data = new FormData();
    data.append("formData", JSON.stringify(formData));
    data.append("logo", documents.companyLogo);
    // saveSettingsData(data);
    updateProfileDetails(formData);
    setEditMode(false);
    //  toast.success("Account details updated successfully!");
  };
  //FETCH SETTINGS DATA

  useEffect(() => {
    reqSettingsData();
  }, []);

  useEffect(() => {
    if (settingdata?.length > 0) {
      setFormData({
        id: settingdata[0]._id,
        agencyName: settingdata[0].agencyName,
        address: settingdata[0].address,
        pincode: settingdata[0].pincode,
        country: settingdata[0].country,
        state: settingdata[0].state,
        city: settingdata[0].city,
        phonenum: settingdata[0].phonenum,
        alternatenum: settingdata[0].alternatenum,
        company_person_name: settingdata[0].company_person_name,
        company_person_last_name: settingdata[0].company_person_last_name,
        company_person_email: settingdata[0].company_person_email,
        company_person_mobile: settingdata[0].company_person_mobile,
        company_website: settingdata[0].company_website,
      });
    }
  }, [settingdata]);

  //Validation Code
  const {
    NumberValidation,
    EmailValidation,
    PinCodeValidation,
    AlternateNumberValidation,
    WebValidation,
    PhoneAlternateNumber,
  } = Validation();

  const [errorMsg, setErrorMsg] = useState({
    agencyName: "",
    company_person_name: "",
    company_person_last_name: "",
    country: "",
    state: "",
    city: "",
  });

  const handleNameValidation = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const errorMsg = !condition.test(value) ? "Enter only alphabets" : "";
    setErrorMsg((prevState) => ({
      ...prevState,
      [name]: errorMsg,
    }));
  };
  // const checkFormValidity = () => {
  //   // Check if there are any errors in the form
  //   const hasErrors = Object.values(errorMsg).some((msg) => msg !== "");
  //   setIsFormValid(!hasErrors);
  // };
  return (
    <Card
      extra={"w-full pb-10 p-4 h-full"}
      style={{
        backgroundColor: "#f3f4f6",
        borderRadius: "1.5rem",
        boxShadow: "0px 4px 6px rgba(59, 130, 246, 0.3)",
      }}
    >
      <div class="mx-auto -mb-px flex max-w-screen-xl flex-wrap items-center justify-center "></div>

      <div id="default-styled-tab-content">
        <div
          id="styled-dashboard"
          role="tabpanel"
          aria-labelledby="dashboard-tab"
        >
          <form onSubmit={handlesettingdata}>
            <div className="pb-12">
              <h2 className="text-start text-base font-semibold leading-7 text-red-500">
                Agency Details
              </h2>
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="agencyName"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Agency Name <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="agencyName"
                      id="agencyName"
                      // pattern="[A-Za-z]"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={formData.agencyName}
                      onChange={handleInputChange}
                      disabled={!editMode}
                      // onKeyUp={handleNameValidation}
                    />
                    {/* <span id="last"></span> */}
                    <span style={{ color: "red" }}>{errorMsg.agencyName}</span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="address"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Address <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.address}
                      // onKeyUp={handleNameValidation}
                      disabled={!editMode}
                    />
                    {/* <span id="Address"></span> */}
                    {/* <span style={{ color: "red" }}>{errorMsg.address}</span> */}
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="pincode"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Pin Code <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="pincode"
                      id="pincode"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.pincode}
                      onKeyUp={PinCodeValidation}
                      disabled={!editMode}
                    />
                    <span id="pincodeval"></span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="country"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Country <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.country}
                      onKeyUp={handleNameValidation}
                      disabled={!editMode}
                    />
                    <span style={{ color: "red" }}>{errorMsg.country}</span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="state"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    State <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="state"
                      id="state"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.state}
                      onKeyUp={handleNameValidation}
                      disabled={!editMode}
                    />
                    <span style={{ color: "red" }}>{errorMsg.state}</span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="city"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    City <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.city}
                      onKeyUp={handleNameValidation}
                      disabled={!editMode}
                    />
                    <span style={{ color: "red" }}>{errorMsg.city}</span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="phonenum"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Phone Number <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="phonenum"
                      id="phonenum"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.phonenum}
                      onKeyUp={NumberValidation}
                      disabled={!editMode}
                      maxLength={10}
                      minLength={10}
                    />
                    <span id="Number"></span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="alternatenum"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Alternate Phone Number{" "}
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="alternatenum"
                      id="alternatenum"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.alternatenum}
                      onKeyUp={PhoneAlternateNumber}
                      disabled={!editMode}
                      maxLength={10}
                      minLength={10}
                    />
                    <span id="AltNumber"></span>
                  </div>
                </div>
              </div>
              <div className=" py-6 ">
                <h2 className="text-start text-base font-semibold leading-7 text-red-500">
                  Representative Details
                </h2>
              </div>

              <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company_person_name"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    First Name <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="company_person_name"
                      id="company_person_name"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.company_person_name}
                      // onKeyUp={FirstnameValidation}
                      onKeyUp={handleNameValidation}
                      disabled={!editMode}
                    />
                    {/* <span id="first"></span> */}
                    <span style={{ color: "red" }}>
                      {errorMsg.company_person_name}
                    </span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="company_person_last_name"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Last Name <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="company_person_last_name"
                      id="company_person_last_name"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.company_person_last_name}
                      onKeyUp={handleNameValidation}
                      disabled={!editMode}
                    />
                    <span style={{ color: "red" }}>
                      {errorMsg.company_person_last_name}
                    </span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="company_person_email"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Email Id <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="company_person_email"
                      id="company_person_email"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.company_person_email}
                      onKeyUp={EmailValidation}
                      disabled={!editMode}
                    />
                    <span id="Email"></span>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company_person_mobile"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Mobile Number <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="company_person_mobile"
                      id="company_person_mobile"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.company_person_mobile}
                      onKeyUp={AlternateNumberValidation}
                      disabled={!editMode}
                    />
                    <span id="ANumber"></span>
                  </div>
                </div>
              </div>
              <div className=" py-6 ">
                <h2 className="text-start text-base font-semibold leading-7 text-red-500">
                  Agency Preferences
                </h2>
              </div>

              <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company_website"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Website URL <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="company_website"
                      id="company_website"
                      className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.company_website}
                      onKeyUp={WebValidation}
                      disabled={!editMode}
                    />
                    <span id="url"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              {!editMode && (
                <button
                  type="button"
                  onClick={() => setEditMode(true)}
                  className="rounded bg-yellow-500 px-4 py-2 font-bold text-white hover:bg-yellow-700"
                >
                  Edit
                </button>
              )}
              {editMode && (
                <button
                  type="submit"
                  className="rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700"
                  disabled={!isFormValid}
                >
                  Save
                </button>
              )}
            </div>
          </form>
          {/*)} */}
        </div>
      </div>
    </Card>
  );
};

export default ProfileSetting;
