// import React, { useState } from "react";
// import LeadsData from "./LeadsData";
// import InvoiceData from "./InvoiceData";
// import QuotationData from "./QuotationData";
// import CustomerData from "./CustomerData";
// import Card from "components/card";

// const DashboardTables = () => {
//   const [activetab, setActivetab] = useState("tab1");

//   const handleTab = (tab) => {
//     setActivetab(tab);
//   };

//   return (
//     <>
//       <Card>
//         <div className="mx-auto w-full justify-center px-4 sm:px-6 lg:px-8 ">
//           <ul
//             className="mb-12 mt-12 flex flex-wrap items-center justify-center bg-gray-400 px-2 py-2 text-center text-lg font-semibold dark:bg-gray-700 sm:flex-nowrap"
//             style={{ borderRadius: "8px" }}
//           >
//             <li className="mb-4 sm:mb-0 sm:me-4">
//               <button
//                 type="button"
//                 className={`tab-item ${activetab === "tab1" ? "active" : ""}`}
//                 onClick={() => handleTab("tab1")}
//               >
//                 Recent Leads
//               </button>
//             </li>
//             <li className="mb-2 sm:mb-0 sm:me-4">
//               <button
//                 type="button"
//                 className={`tab-item ${activetab === "tab2" ? "active" : ""}`}
//                 onClick={() => handleTab("tab2")}
//               >
//                 Recent Invoices
//               </button>
//             </li>
//             <li className="mb-2 sm:mb-0 sm:me-4">
//               <button
//                 type="button"
//                 className={`tab-item ${activetab === "tab3" ? "active" : ""}`}
//                 onClick={() => handleTab("tab3")}
//               >
//                 Recent Quotations
//               </button>
//             </li>
//             <li className="mb-2 sm:mb-0">
//               <button
//                 type="button"
//                 className={`tab-item ${activetab === "tab4" ? "active" : ""}`}
//                 onClick={() => handleTab("tab4")}
//               >
//                 Recent Customers
//               </button>
//             </li>
//           </ul>

//           {/* Conditionally render the corresponding component based on the active tab */}
//           <div className="w-full">
//             {activetab === "tab1" && <LeadsData />}
//             {activetab === "tab2" && <InvoiceData />}
//             {activetab === "tab3" && <QuotationData />}
//             {activetab === "tab4" && <CustomerData />}
//           </div>
//         </div>
//       </Card>
//     </>
//   );
// };

// export default DashboardTables;


import React, { useState } from "react";
import LeadsData from "./LeadsData";
import InvoiceData from "./InvoiceData";
import QuotationData from "./QuotationData";
import CustomerData from "./CustomerData";
import "./style.css";
import Card from "components/card";
import { color } from "@chakra-ui/system";

const DashboardTables = () => {
  const [activetab, setActivetab] = useState("tab1");

  const handleTab = (tab) => {
    setActivetab(tab);
  };

  return (
    <>
      <Card>
        <div className="mx-auto w-full justify-center px-4 sm:px-6 lg:px-8 ">
          <ul
            className="mt-10 flex flex-wrap items-center justify-center text-white bg-gray-400 border-2 border-solid border-gray-400  px-2 py-2 text-center text-lg font-semibold dark:bg-gray-700 sm:flex-nowrap"
            style={{ borderRadius: "8px"}}
          >
            <li className="mb-4 sm:mb-0 sm:me-4" >
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab1" ? "purple" : "inherit",
                  borderBottom: activetab === "tab1" ? "2px solid purple" : "none"
                }}
                onClick={() => handleTab("tab1") }
              >
                Recent Leads
              </button>
            </li>
            <li className="mb-2 sm:mb-0 sm:me-4">
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab2" ? "purple" : "inherit",
                  borderBottom: activetab === "tab2" ? "2px solid purple" : "none"
                }}
                onClick={() => handleTab("tab2")}
              >
                Recent Invoices
              </button>
            </li>
            <li className="mb-2 sm:mb-0 sm:me-4">
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab3" ? "purple" : "inherit",
                  borderBottom: activetab === "tab3" ? "2px solid purple" : "none"
                }}
                onClick={() => handleTab("tab3")}
              >
                Recent Quotations
              </button>
            </li>
            <li className="mb-2 sm:mb-0">
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab4" ? "purple" : "inherit",
                  borderBottom: activetab === "tab4" ? "2px solid purple" : "none"
                }}
                onClick={() => handleTab("tab4")}
              >
                Recent Customers
              </button>
            </li>
          </ul>

          {/* Conditionally render the corresponding component based on the active tab */}
          <div className="w-full">
            {activetab === "tab1" && <LeadsData />}
            {activetab === "tab2" && <InvoiceData />}
            {activetab === "tab3" && <QuotationData />}
            {activetab === "tab4" && <CustomerData />}
          </div>
        </div>
      </Card>
    </>
  );
};

export default DashboardTables;

