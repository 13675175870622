import React, { useState } from "react"; // Make sure to import React
import { Link } from "react-router-dom"; // Correct import statement for Link
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "hooks/useAuth"; // Assuming this import is correct

import Image from "./pic.png"; // Assuming this import is correct

export default function ResetPass() {
  const [showPassword, setShowPassword] = useState(false);
  const [shownewPassword, setShowNewPassword] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    newpassword: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!shownewPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // logIn(formData);
    // axios.post('http://localhost:3001/auth/saveAdminForm', formData).then((res) => {
    //   if (res.status === 200) {
    //     toast.success(res.data.message);
    //   }
    //   else {
    //     toast.warning(res.data.message);
    //   }
    // })
  };

  return (
    <>
      <ToastContainer />
      <section className="flex min-h-screen items-center justify-center bg-gray-50">
        <div className="flex max-w-3xl items-center rounded-2xl bg-gray-100 p-5 shadow-lg">
          <div className="hidden w-1/2 md:block">
            <img className="rounded-2xl" src={Image} alt="Login" />
          </div>
          <div className="px-8 md:w-1/2 md:px-16">
            <h2 className="text-2xl font-bold text-[#002D74]">
              Reset Password
            </h2>
            <p className="mt-4 text-xs text-[#002D74]">
              Welcome back Reset Your Password
            </p>

            <form onSubmit={handleSubmit} className="mt-4 flex flex-col gap-4">
              {/* <input
                className="p-2 mt-8 rounded-xl border"
                type="text"
                name="username"
                placeholder="username"
                onChange={handleInputChange}
                value={formData.username}
              /> */}
              <div className="relative">
                <input
                  className="w-full rounded-xl border p-2"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="New Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  className="bi bi-eye absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                  viewBox="0 0 16 16"
                  onClick={togglePasswordVisibility}
                >
                  <title>{showPassword ? "Hide" : "Show"}</title>
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              </div>
              <div className="relative">
                <input
                  className="w-full rounded-xl border p-2"
                  type={shownewPassword ? "text" : "password"}
                  name="newpassword"
                  placeholder="Confirm Password"
                  value={formData.newpassword}
                  onChange={handleInputChange}
                  required
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  className="bi bi-eye absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                  viewBox="0 0 16 16"
                  onClick={toggleNewPasswordVisibility}
                >
                  <title>{shownewPassword ? "Hide" : "Show"}</title>
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              </div>

              <button className="rounded-xl bg-[#FF0000] py-2 text-white duration-300 hover:scale-105">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
