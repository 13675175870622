import { useAdmin } from "hooks/useAdmin";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";

export default function Remark({ onClose, data }) {
  const [formData, setFormData] = useState({ remark: "", id: data._id });
  const [submittedData, setSubmittedData] = useState([]);
  const [remarkCount, setRemarkCount] = useState(0);
  const { saveRemarkDetails } = useAdmin();
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (e) => {
    setButtonClicked(true);
    e.preventDefault();
    saveRemarkDetails(formData);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur">
      <div className="w-full max-w-md px-4 py-6 bg-gray-200 rounded-xl shadow-xl">
        <div className="flex justify-end mb-2">
          <span
            className="cursor-pointer text-red-600"
            onClick={handleClose}
            style={{ zIndex: 1100 }}
          >
            <FaTimes size={24} />
          </span>
        </div>
        {submittedData.length > 0 && (
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Submitted Data</h2>
            {submittedData.map((data, index) => (
              <div key={index}>
                <p>
                  Remark {index + 1}: {data.remark}
                </p>
              </div>
            ))}
          </div>
        )}
        {remarkCount < 2 && (
          <form onSubmit={handleSubmit}>
            <h2 className="text-xl font-semibold text-center mb-4 underline">Remark</h2>
            <div className="mb-4  text-center">
              <input
                type="text"
                id="remark"
                name="remark"
                value={formData.remark}
                onChange={handleChange}
                className="w-11/12 px-4 py-2 border rounded "
                placeholder="Enter Your Remark"
                required
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={buttonClicked}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
