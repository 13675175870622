import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { Validation } from "./Validation";
import { toast } from "react-toastify";
export default function RoundTrip({ triptype, parentData, clientId, name, handleCloseTripType
}) {
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [disableSubmit, setdisableSubmit] = useState(false)

  const { AddFlightQuotation } = useAdmin();

  const [formData, setFormData] = useState({
    triptype,
    from: "",
    to: "",
    departuredate: "",
    returndate: "",
    airlines: "",
    pnr: "",
    bookingsource: "",
    netcost: "",
    markup: "",
    gstcost: "",
    idealcollection: "",
    actualcollection: "",
    cancellationCharges: "",
    refundAmount: "",
  });

  // console.log(formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = name === 'pnr' ? value.toUpperCase() : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: uppercaseValue,
    }));

    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };

  useEffect(() => {
    const amount = parseFloat(netcost) || 0;
    const markupCost = parseFloat(markup) || 0;
    const actualcost = parseFloat(actualcollection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      idealcollection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [netcost, markup, actualcollection]);

  const swapValues = () => {
    setFormData((prevData) => ({
      from: prevData.to,
      to: prevData.from
     
    }));
  };

 const swapValues1=()=>{
  setFormData((prevData)=>({
    departuredate:prevData.returndate,
    returndate:prevData.departuredate,
  }))
 }

  const [showForm, setShowForm] = useState(true);
  const handleClose = () => {
    setShowForm(false);
    handleCloseTripType(false);

  };
  const handleSubmit = async(e) =>  {
    setdisableSubmit(true)

    e.preventDefault();
    if(validateForm()===true){
   await   AddFlightQuotation({ formData, parentData, clientId, name });
      setInterval(() => {
        window.location.reload();
      }, 1000);
    }else{
      toast.error("Please fill manadatory field")
    }
  };

 

  const handleDiscard = () => {
    setFormData({
      from: "",
      to: "",
      departuredate: "",
      returndate: "",
      airlines: "",
      pnr: "",
      bookingsource: "",
      netcost: "",
      markup: "",
      gstcost: "",
      idealcollection: "",
      actualcollection: "",
      cancellationCharges: "",
      refundAmount: "",
      deficit: "",
    });
  };

  function validateForm(){
    // console.log(formData);
    if( 
      formData.triptype!==""&&
      formData.from!== ""&&
      formData.to!== ""&&
      formData.departuredate!== ""&&
      formData.returndate!== ""&&
      formData.airlines!== ""&&
      formData.pnr!== ""&&
      formData.bookingsource!== ""&&
      formData.netcost!== ""&&
      formData.markup!== ""&&
      formData.gstcost!== ""&&
      formData.idealcollection!== ""){
     return true;
      }
    return false;
  }

  //Validation Code
  const {
    AlphaValidation,
    ToValidation,
    error1,
    errorMessage,
    PnrValidation,
    pnrError,
    AirlineValidation,
    airlineError,
    BookingSourceValidation,
    bookingError,
    NetCostValidation,
    netcostError,
    MarkupValidation,
    markupError,
    ActualCollectionValidation,
    actuallcollectionError,
    CancellationValidation,
    cancellationError,
    RefundValidation,
    refundError,
  } = Validation();

  // console.log("this is data",formData);


  useEffect(() => {
    // Clear form data when triptype changes to 'Round Trip'
    if (triptype === 'Round Trip') {
      setFormData({
        triptype,
        from: "",
        to: "",
        departuredate: "",
        returndate: "",
        airlines: "",
        pnr: "",
        bookingsource: "",
        netcost: "",
        markup: "",
        gstcost: "",
        idealcollection: "",
        actualcollection: "",
        cancellationCharges: "",
        refundAmount: "",
        deficit: "",
      });
    }
  }, [triptype]);

  return (
    <>
      {showForm ? (
        <div className="relative">
          <div class="fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0">
            <div>
              <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
                {/* <div className="flex items-center justify-between">
                  <span
                    className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-blue-600"
                    onClick={() => handleCloseTripType(false)}
                    style={{
                      marginRight: "8px",
                      cursor: "pointer",
                      zIndex: "1100px",
                    }}
                  >
                    {" "}
                    <IoIosArrowBack size={24} />{" "}
                  </span> */}
                <span
                  className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    zIndex: "1100px",
                  }}
                >
                  {" "}
                  <FaTimes size={24} />{" "}
                </span>
                <h2 className="mb-4 text-center text-lg font-semibold underline">
                  Round Trip
                </h2>

                {/* </div> */}

                <form onSubmit={handleSubmit}>
                  <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-2">
                      <label
                        for="from"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        From<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="from"
                          name="from"
                          type="text"
                          autoComplete="from"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.from}
                          onKeyUp={AlphaValidation}
                          required
                        />
                        {errorMessage && (
                          <span style={{ color: "red" }}>{errorMessage}</span>
                        )}
                      </div>
                    </div>
                    <FontAwesomeIcon
                    icon={faArrowRightArrowLeft}
                    className="mb-4 text-red-500 mx-auto sm:ml-[80px] sm:mt-0"
                    style={{ marginTop: "40px" }}
                    onClick={swapValues}
                  /> 
                  
                    <div class="sm:col-span-2">
                      <label
                        for="to"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        To<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="to"
                          name="to"
                          type="text"
                          autoComplete="to"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.to}
                          onKeyUp={ToValidation}
                          required
                        />
                        {error1 && (
                          <span style={{ color: "red" }}>{error1}</span>
                        )}
                      </div>
                    </div>

                    <div class="sm:col-span-2">
                      <label
                        for="departuredate"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Departure Date<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="departuredate"
                          name="departuredate"
                          type="date"
                          autoComplete="departuredate"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.departuredate}
                          required
                        />
                      </div>
                    </div>
                    <FontAwesomeIcon
                    icon={faArrowRightArrowLeft}
                    className="mb-4 text-red-500 mx-auto sm:ml-[80px] sm:mt-0"
                    style={{ marginTop: "40px" }}
                    onClick={swapValues1}
                  />
                    <div class="sm:col-span-2">
                      <label
                        for="returndate"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Return Date<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="returndate"
                          name="returndate"
                          type="date"
                          autoComplete="returndate"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.returndate}
                          required
                        />
                      </div>
                    </div>


                    <div class="sm:col-span-3">
                      <label
                        for="pnr"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        PNR<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="pnr"
                          name="pnr"
                          type="text"
                          autoComplete="pnr"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.pnr}
                          // onKeyUp={PnrValidation}
                          required
                        />
                        {pnrError && (
                          <span style={{ color: "red" }}>{pnrError}</span>
                        )}
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="airlines"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Airlines<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="airlines"
                          name="airlines"
                          type="text"
                          autocomplete="airlines"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.airlines}
                          onKeyUp={AirlineValidation}
                          required
                        />
                        {airlineError && (
                          <span style={{ color: "red" }}>{airlineError}</span>
                        )}
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="bookingsource"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Booking Source<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="bookingsource"
                          name="bookingsource"
                          type="text"
                          autocomplete="bookingsource"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.bookingsource}
                          onKeyUp={BookingSourceValidation}
                          required
                        />
                        {bookingError && (
                          <span style={{ color: "red" }}>{bookingError}</span>
                        )}
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="netcost"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Net Cost<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="netcost"
                          name="netcost"
                          type="text"
                          autocomplete="netcost"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.netcost}
                          onKeyUp={NetCostValidation}
                          required
                        />
                        {netcostError && (
                          <span style={{ color: "red" }}>{netcostError}</span>
                        )}
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="markup"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Mark Up<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="markup"
                          name="markup"
                          type="text"
                          autocomplete="markup"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.markup}
                          onKeyUp={MarkupValidation}
                          required
                        />

                        {markupError && (
                          <span style={{ color: "red" }}>{markupError}</span>
                        )}
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="gstcost"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        GST 18% on Mark Up
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="gstcost"
                          name="gstcost"
                          type="text"
                          autocomplete="gstcost"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.gstcost}
                          readOnly
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="idealcollection"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Ideal Collection
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="idealcollection"
                          name="idealcollection"
                          type="text"
                          autocomplete="idealcollection"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.idealcollection}
                          readOnly
                        />
                      </div>
                    </div>
                    {/* <div class="sm:col-span-3">
                      <label
                        for="actualcollection"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Actual Collection
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="actualcollection"
                          name="actualcollection"
                          type="text"
                          autocomplete="actualcollection"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                        focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.actualcollection}
                          onKeyUp={ActualCollectionValidation}
                          required
                          readOnly
                        />
                        {actuallcollectionError && (
                          <span style={{ color: "red" }}>
                            {actuallcollectionError}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div class="sm:col-span-3">
                      <label
                        for="deficit"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Deficit <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="deficit"
                          name="deficit"
                          type="text"
                          autocomplete="deficit"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.deficit}
                          readOnly
                        />
                      </div>
                    </div>

                    {/* <div class="sm:col-span-3">
                      <label
                        for="cancellationCharges"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Cancellation Charges
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="cancellationCharges"
                          name="cancellationCharges"
                          type="text"
                          autocomplete="cancellationCharges"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.cancellationCharges}
                          onKeyUp={CancellationValidation}
                          required
                        />
                        {cancellationError && (
                          <span style={{ color: "red" }}>
                            {cancellationError}
                          </span>
                        )}
                      </div>
                    </div> */}

                    {/* <div class="sm:col-span-3">
                      <label
                        for="refundAmount"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Refund Amount<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="refundAmount"
                          name="refundAmount"
                          type="text"
                          autocomplete="refundAmount"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.refundAmount}
                          onKeyUp={RefundValidation}
                          required
                        />
                        {refundError && (
                          <span style={{ color: "red" }}>{refundError}</span>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="mt-4 flex place-content-center">
                    <button
                      onClick={handleSubmit}
                      // disabled={disableSubmit}

                      className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleDiscard}
                      className="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                    >
                      Discard
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
