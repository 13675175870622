import { useRef, useEffect, useState } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import Itinaray from "./Itinaray";
import { toast } from "react-toastify";

let reloadTimeout = null;
export default function International({ parentData, clientId, name, onClose }) {
  const [amountininr, setAmountininr] = useState("");
  const [netinsurancecost, setNetinsurancecost] = useState("");
  const [netvisacost, setNetvisacost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [showamount, setShowAmount] = useState("");
  const [showroe, setShowRoe] = useState("");
  const { AddInternationalQuotation } = useAdmin();
  const [fileData, setFileData] = useState([]);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [itineraryData, setItineraryData] = useState("");
  const [disableSubmit, setdisableSubmit] = useState(false);
  const [applygst, setApplygst] = useState("");
  const [editorContents, setEditorContents] = useState({
    itinerary: "",
    overview: "",
    flight: "",
    hotel: "",
    inclusions: "",
    exclusions: "",
    // photogallery: "",
    priceguideline: "",
    termscondition: "",
  });

  const handleEditorChangeParent = (editorName, newContent) => {
    setEditorContents((prevContents) => ({
      ...prevContents,
      [editorName]: newContent,
    }));
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setItineraryData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState({
    destination: "",
    traveldate: "",
    duration: "",
    currency: "",
    amount: "",
    roe: "",
    amountininr: "",
    gst: "",
    netvisacost: "",
    vendorname: "",
    netinsurancecost: "",
    totalpackagecost: "",
    markup: "",
    grosscost: "",
    gstcost: "",
    gstamount: "",
    tlscost: "",
    idealcollection: "",
    actualcollection: "",
    deficit: "",
    totalprice:"",
    itinerary: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "amountininr") {
      setAmountininr(value);
    } else if (name === "netinsurancecost") {
      setNetinsurancecost(value);
    } else if (name === "netvisacost") {
      setNetvisacost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    } else if (name === "amount") {
      setShowAmount(value);
    } else if (name === "roe") {
      setShowRoe(value);
    } else if (name === "gst") {
      setApplygst(value);
    }
  };
  useEffect(() => {
    const insuranceCost = parseFloat(netinsurancecost) || 0;
    const visaCost = parseFloat(netvisacost) || 0;
    const grossCost = parseFloat(markup) || 0;
    const actualcoll = parseFloat(actualcollection) || 0;
    const dolleramount = parseFloat(showamount) || 0;
    const actualroe = parseFloat(showroe) || 0;
    const actualdolleramount = dolleramount * actualroe;
    const total = actualdolleramount + insuranceCost + visaCost;
    const gst = parseFloat(applygst) || 0;
    if (gst === 18) {
      var gst18 = (grossCost * 18) / 100;
      var withgstmarkup = grossCost + gst18 || 0;
      var totalamount = total + withgstmarkup;
      var totalamount = total + grossCost + gst18;
      var totalprice = total + grossCost;
    }
    if (gst === 5) {
      var withgstmarkup = grossCost || 0;
      var gst5 = total + withgstmarkup;
      var totalamount = gst5 + (gst5 * 5) / 100;
      var gst6 = (gst5 * 5) / 100;
      var totalprice = total + grossCost;
    }
    const GrossCost = totalamount || 0;
    const grossWithGST = GrossCost || 0;
    const tlscost = 0.05 * GrossCost || 0;
    const idealcollection = tlscost + grossWithGST || 0;
    const newdeficit = idealcollection - actualcoll || 0;
    const gst_amount = gst6 || gst18 || 0;

    setFormData((prevFormData) => ({
      ...prevFormData,
      totalpackagecost: total,
      grosscost: GrossCost,
      gstcost: grossWithGST,
      tlscost: tlscost,
      amountininr: actualdolleramount,
      idealcollection: idealcollection,
      deficit: newdeficit,
      gstamount: gst_amount,
      totalprice: totalprice,
    }));
  }, [
    amountininr,
    netinsurancecost,
    netvisacost,
    markup,
    actualcollection,
    showroe,
    showamount,
    content,
    applygst,
  ]);

  function validateForm() {
    if (
      formData.destination !== "" &&
      formData.traveldate !== "" &&
      formData.duration !== "" &&
      formData.currency !== "" &&
      formData.amount !== "" &&
      formData.roe !== "" &&
      formData.amountininr !== "" &&
      formData.netvisacost !== "" &&
      formData.vendorname !== "" &&
      formData.netinsurancecost !== "" &&
      formData.totalpackagecost !== "" &&
      formData.markup !== "" &&
      formData.grosscost !== "" &&
      formData.gstcost !== "" &&
      formData.tlscost !== "" &&
      formData.idealcollection !== "" &&
      formData.deficit !== ""
    ) {
      return true;
    }
    return false;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disableSubmit) return;
    setdisableSubmit(true);
    const finalData = new FormData();
    finalData.append("formData", JSON.stringify(formData));
    finalData.append("parentData", JSON.stringify(parentData));
    finalData.append("clientId", JSON.stringify(clientId));
    finalData.append("editorContents", JSON.stringify(editorContents));
    finalData.append("name", JSON.stringify(name));
    fileData.forEach((file) => {
      finalData.append("document", file);
    });

    if (validateForm()) {
      await AddInternationalQuotation(finalData);
      if (reloadTimeout) clearTimeout(reloadTimeout);
      reloadTimeout = setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Please fill mandatory fields");
      setdisableSubmit(false);
    }
  };

  const handleDiscard = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      destination: "",
      traveldate: "",
      duration: "",
      currency: "",
      amount: "",
      roe: "",
      amountininr: "",
      gst: "",
      netvisacost: "",
      vendorname: "",
      netinsurancecost: "",
      totalpackagecost: "",
      markup: "",
      grosscost: "",
      gstcost: "",
      tlscost: "",
      idealcollection: "",
      actualcollection: "",
      deficit: "",
      itinerary: "",
    }));
  };

  //Validation Code
  const {
    LastnameValidation,

    ROEValidation,
    NetVisaCostValidation,
    AmountININRValidation,
    VendornameValidation,
    NetInsuranceCost,
    MarkupValidation,
    AmountValidation,
  } = Validation();

  const handleClose = (e) => {
    onClose();
  };

  return (
    <>
      <div className="relative mt-0">
        <div>
          <div className="z-50 rounded-bl-lg rounded-br-lg bg-gray-200 p-5 shadow-xl">
            <span
              className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
              onClick={handleClose}
              style={{
                marginRight: "8px",
                cursor: "pointer",
                zIndex: "1100px",
              }}
            >
              {" "}
              <FaTimes size={24} />{" "}
            </span>

            <h2 className="mb-10 text-center text-lg font-semibold underline">
              International Package
            </h2>

            <div className="border-b border-gray-900/10 pb-12">
              <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    for="destination"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Destination <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="destination"
                      id="destination"
                      autocomplete="given-name"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.destination}
                      onKeyUp={LastnameValidation}
                      required
                    />
                    <span id="last"></span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="traveldate"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Travel Date <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="traveldate"
                      id="traveldate"
                      autoComplete="traveldate"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={handleInputChange}
                      value={formData.traveldate}
                      required
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    for="duration"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Duration (In Days){" "}
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="duration"
                      name="duration"
                      type="text"
                      autoComplete="duration"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.duration}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <h2 className="text-black text-start text-base font-semibold leading-7">
              Net Land Package cost & Vendor name
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  for="currency"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Currency
                </label>
                <div className="mt-2">
                  <select
                    id="currency"
                    name="currency"
                    autoComplete="currency"
                    value={formData.currency}
                    onChange={handleInputChange}
                    required
                    className="text-black block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="">Select</option>
                    <option value="INR">INR</option>
                    <option value="SGD">SGD</option>
                    <option value="USD">USD</option>
                    <option value="Euro">Euro</option>
                    <option value="AED">AED</option>
                    <option value="Thai baht">Thai baht</option>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  for="amount"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Amount <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="amount"
                    id="amount"
                    autoComplete="roe"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.amount}
                    onKeyUp={AmountValidation}
                    required
                  />
                  <span id="AmountValidation"></span>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  for="roe"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  ROE <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="roe"
                    id="roe"
                    autoComplete="roe"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.roe}
                    onKeyUp={ROEValidation}
                    required
                  />
                  <span id="ROE"></span>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="amount-in-inr"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Amount in INR <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="amountininr"
                    id="amountininr"
                    autocomplete="amount-in-inr"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.amountininr}
                    onKeyUp={AmountININRValidation}
                    required
                  />
                  <span id="amountININRValidation"></span>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="gst"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  GST
                  <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <select
                    id="gst"
                    name="gst"
                    autoComplete="gst"
                    value={formData.gst}
                    onChange={handleInputChange}
                    required
                    className="text-black block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="">Select</option>
                    <option value="5">5%</option>
                    <option value="18">18%</option>
                  </select>
                </div>
                {/* <div className="mt-2">
                  <input
                    type="text"
                    name="gstcost"
                    id="gstcost"
                    autocomplete="gstcost"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.gstcost}
                    readOnly
                    required
                  />
                </div> */}
              </div>

              <div className="sm:col-span-3">
                <label
                  for="net-visa-cost"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Net Visa Cost <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="netvisacost"
                    id="netvisacost"
                    autocomplete="netvisacost"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.netvisacost}
                    onKeyUp={NetVisaCostValidation}
                    required
                  />
                  <span id="netVisaCostValidation"></span>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="vendor-name"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Vendor Name <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="vendorname"
                    id="vendorname"
                    autocomplete="vendorname"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.vendorname}
                    // onKeyUp={VendornameValidation}
                    required
                  />
                  <span id="vendornameValidation"></span>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="net-income-cost"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Net Insurance Cost <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="netinsurancecost"
                    id="netinsurancecost"
                    autocomplete="net-income-cost"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                             placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.netinsurancecost}
                    onKeyUp={NetInsuranceCost}
                    required
                  />
                  <span id="netInsuranceCost"></span>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  for="net-income-cost"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Total Package Cost{" "}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="totalpackagecost"
                    id="totalpackagecost"
                    autocomplete="net-income-cost"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                           placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.totalpackagecost}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="mark-up"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Mark Up <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="markup"
                    id="markup"
                    autocomplete="mark-up"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.markup}
                    onKeyUp={MarkupValidation}
                    required
                  />
                  <span id="MarkupValidation"></span>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="totalprice"
                  className="text-black block text-sm font-semibold leading-6"
                >
                Total Price <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="totalprice"
                    id="totalprice"
                    autocomplete="totalprice"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.totalprice}
                    readOnly
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="net-income-cost"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Gross Cost
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="grosscost"
                    id="grosscost"
                    autocomplete="net-income-cost"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.grosscost}
                    readOnly
                    required
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  for="tls-cost"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  TCS 5% on Gross Cost{" "}
                  <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="tlscost"
                    id="tlscost"
                    autocomplete="tlscost"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.tlscost}
                    readOnly
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  for="tls-cost"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Ideal Collection
                  <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="idealcollection"
                    id="idealcollection"
                    autocomplete="idealcollection"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.idealcollection}
                    required
                    readOnly
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  for="tls-cost"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Deficit
                  <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="deficit"
                    id="deficit"
                    autocomplete="deficit"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.deficit}
                    readOnly
                    required
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Itinaray
                ref={editor}
                editorContents={editorContents}
                onEditorChange={handleEditorChangeParent}
                setFileData={setFileData}
              />
            </div>

            <div className="mt-4 flex flex-wrap items-center justify-center">
              <button
                className="rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                onClick={handleSubmit}
                // disabled={disableSubmit}
              >
                Save
              </button>
              <div className="mx-4 sm:mx-4"></div>
              <button
                onClick={handleDiscard}
                className="rounded border-gray-600 bg-red-500 px-3 py-1 text-white hover:bg-red-700 "
              >
                Discard
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
