import { useEffect, useState } from "react";
import RoundTrip from "./RoundTrip";
import MultiCiti from "./MultiCiti";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";
import { Validation } from "views/admin/marketplace/components/Validation";
import { useAdmin } from "../../../hooks/useAdmin";
import { toast } from "react-toastify";

export default function Flight({ parentData, clientId, name, onClose }) {
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [showMultiCiti, setShowMultiCiti] = useState(false);

  const [showRoundTrip, setShowRoundTrip] = useState(false);
  const [disableSubmit, setdisableSubmit] = useState(false);

  const [tripType, setTripType] = useState("One Way");

  const { AddFlightQuotation } = useAdmin();

  const [data, setData] = useState([
    { from: "", to: "", traveldate: "", pnr: "" },
  ]);

  // console.log(data);

  const [formData, setFormData] = useState({
    triptype: "",
    from: "",
    to: "",
    traveldate: "",
    departuredate: "",
    airlines: "",
    pnr: "",
    bookingsource: "",
    netcost: "",
    markup: "",
    gstcost: "",
    idealcollection: "",
    actualcollection: "",
    cancellationCharges: "",
    deficit: "",
    refundAmount: "",
  });
  // console.log(formData);

  useEffect(() => {
    setFormData({
      triptype: tripType,
      from: "",
      to: "",
      departuredate: "",
      returndate: "",
      pnr: "",
      bookingsource: "",
      airlines: "",
      netcost: "",
      markup: "",
      gstcost: "",
      idealcollection: "",
      actualcollection: "",
      cancellationCharges: "",
      deficit: "",
      refundAmount: "",
    });
  }, [tripType]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = name === "pnr" ? value.toUpperCase() : value;

    setShowMultiCiti(false);
    if (name === "triptype") {
      if (value === "MultiCity") {
        setShowTripTypePopUp(true);
        setShowMultiCiti(true);
      }
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: uppercaseValue,
    }));

    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };

  const handleTripTypeChange = (e) => {
    setTripType(e.target.value);
  };

  useEffect(() => {
    const amount = parseFloat(netcost) || 0;
    const markupCost = parseFloat(markup) || 0;
    const actualcost = parseFloat(actualcollection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      idealcollection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [netcost, markup, actualcollection]);

  function validateForm() {
    if (
      formData.triptype !== "" &&
      formData.from !== "" &&
      formData.to !== "" &&
      formData.traveldate !== "" &&
      formData.airlines !== "" &&
      formData.pnr !== "" &&
      formData.bookingsource !== "" &&
      formData.netcost !== "" &&
      formData.markup !== "" &&
      formData.gstcost !== "" &&
      formData.idealcollection !== "" &&
      formData.deficit !== "" &&
      formData.departuredate !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  function validationmulticity() {
    if (
      formData.airlines !== "" &&
      formData.bookingsource !== "" &&
      formData.deficit !== "" &&
      formData.gstcost !== "" &&
      formData.gstmarkup !== "" &&
      formData.idealcollection !== "" &&
      formData.markup !== "" &&
      formData.netcost !== "" &&
      formData.triptype !== "" &&
      data.from !== "" &&
      data.to !== "" &&
      data.traveldate !== "" &&
      data.pnr !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  const handleSubmit = (e) => {
    setdisableSubmit(true);
    e.preventDefault();

    // Required fields for One Way trip
    const oneWayFields = [
        "from",
        "to",
        "departuredate",
        "pnr",
        "airlines",
        "bookingsource",
        "netcost",
        "markup",
    ];

    // Required fields for Round Trip
    const roundTripFields = [
        "from",
        "to",
        "departuredate",
        "returndate",
        "pnr",
        "airlines",
        "bookingsource",
        "netcost",
        "markup",
    ];

    // Required fields for MultiCity
    const multiCityFields = [
        "airlines",
        "bookingsource",
        "netcost",
        "markup",
    ];

    const validateOneWay = () => {
        return oneWayFields.every(field => formData[field]?.trim() !== "");
    };

    const validateRoundTrip = () => {
        return roundTripFields.every(field => formData[field]?.trim() !== "");
    };

    const validateMultiCity = () => {
        // Check if formData contains required fields
        const formDataValid = multiCityFields.every(field => formData[field]?.trim() !== "");
        
        // Check if all routes have required fields
        const routesValid = data.every(route => {
            return (
                route.from?.trim() !== "" &&
                route.to?.trim() !== "" &&
                route.traveldate?.trim() !== "" &&
                route.pnr?.trim() !== "" &&
                route.airlines?.trim() !== "" &&
                route.bookingsource?.trim() !== "" &&
                route.netcost?.trim() !== "" &&
                route.markup?.trim() !== ""
            );
        });

        return formDataValid && routesValid;
    };

    let isValid = false;

    if (formData.triptype === "One Way") {
        isValid = validateOneWay();
    } else if (formData.triptype === "Round Trip") {
        isValid = validateRoundTrip();
    } else if (formData.triptype === "MultiCity") {
        isValid = validateMultiCity();
    }

    if (!isValid) {
        toast.error("Please fill all mandatory fields ");
        setdisableSubmit(false);
        return;
    }

    if (validateForm() || validationmulticity() || isValid) {
        AddFlightQuotation({ formData, parentData, clientId, name, data });
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    } else {
        toast.error("Please fill mandatory fields");
        setdisableSubmit(false); // Enable the submit button
    }
};




  const [showForm, setShowForm] = useState(true);
  const handleClose = () => {
    onClose();
    setShowForm(false);
  };

  const [showTripTypePopUp, setShowTripTypePopUp] = useState(false);

  const updateTripTypePopUpState = (flag) => {
    setShowTripTypePopUp(flag);
  };

  //Validation Code
  const {
    PNRValidation,
    LastnameValidation,
    NetVisaCostValidation,
    MarkupValidation,
    ActualCollectionValidation,
    CancelationChargesValidation,
    RefundAmountValidation,
    ToValidation,
    AirlineValidation,
  } = Validation();

  // Reset form data to initial state

  const handleClick = () => {
    setData([
      ...data,
      { triptype: tripType, from: "", to: "", traveldate: "", pnr: "" },
    ]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "pnr") {
      // Convert all characters to uppercase
      newValue = value.toUpperCase();
    }

    const onchangeVal = [...data];
    onchangeVal[i][name] = newValue;
    setData(onchangeVal);
  };

  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  const handleDiscard = () => {
    setFormData({
      triptype: "",
      departuredate: "",
      from: "",
      to: "",
      traveldate: "",
      airlines: "",
      pnr: "",
      returndate: "",
      bookingsource: "",
      netcost: "",
      markup: "",
      gstcost: "",
      idealcollection: "",
      actualcollection: "",
      cancellationCharges: "",
      refundAmount: "",
      deficit: "",
    });
    setData([{ from: "", to: "", traveldate: "", pnr: "" }]);
  };

  const swapValuesMultiCity = (index) => {
    const updatedData = [...data];
    const tempTo = updatedData[index].to;
    updatedData[index].to = updatedData[index].from;
    updatedData[index].from = tempTo;
    setData(updatedData);
  };

  const swapValues = () => {
    setFormData({
      from: formData.to,
      to: formData.from,
    });
    setSelectedFrom(formData.to);
  };

  const swapValues1 = () => {
    setFormData((prevData) => ({
      departuredate: prevData.returndate,
      returndate: prevData.departuredate,
    }));
  };
  const [selectedFrom, setSelectedFrom] = useState("");
  const handleFromChange = (e) => {
    setSelectedFrom(e.target.value);
  };

  const destinations = [
    "Mumbai",
    "Delhi",
    "Bengaluru",
    "Hyderabad",
    "Chennai",
    "Kolkata",
    "Ahmedabad",
    "Pune",
    "Jaipur",
    "Chandigarh",
    "Goa",
    "Guwahati",
    "Indore",
    "Lucknow",
    "Nagpur",
    "Patna",
    "Ranchi",
    "Surat",
    "Varanasi",
    "Visakhapatnam",
  ];

  const filteredDestinations = destinations.filter(
    (dest) => dest !== selectedFrom
  );

  return (
    <>
      {showForm ? (
        <div className={`relative ${showTripTypePopUp ? "hidden" : "block"}`}>
          {/* <div className="backdrop-blu fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-20 pr-20 pt-10"> */}
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                {" "}
                <FaTimes size={24} />{" "}
              </span>
              <h2 className="mb-4 text-center text-lg font-semibold underline">
                Flight Booking
              </h2>
              <div className=" pb-12">
                <label
                  for="triptype"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Trip Types<sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <select
                    id="triptype"
                    name="triptype"
                    className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={tripType}
                    onChange={handleTripTypeChange}
                  >
                    <option disabled>Select </option>
                    <option value="One Way">One Way</option>
                    <option value="Round Trip">Round Trip</option>
                    <option value="MultiCity">Multi City</option>
                  </select>
                </div>
              </div>

              {tripType === "Round Trip" && (
                <div>
                  <h2 className="mb-4 text-center text-lg font-semibold underline">
                    Round Trip
                  </h2>
                  <div className="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-2 lg:ml-[50px]">
                      <label
                        for="from"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        From<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="from"
                          name="from"
                          type="text"
                          autoComplete="from"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.from}
                          onKeyUp={LastnameValidation}
                          required
                        />
                        <span id="last"></span>
                      </div>
                    </div>

                    <div className="flex items-center justify-center sm:col-span-2">
                      <FontAwesomeIcon
                        icon={faArrowRightArrowLeft}
                        className="mx-auto mb-4 text-red-500"
                        style={{ marginTop: "40px" }}
                        onClick={swapValues}
                      />
                    </div>

                    <div class="sm:col-span-2 lg:mr-[50px]">
                      <label
                        for="to"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        To<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="to"
                          name="to"
                          type="text"
                          autoComplete="to"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.to}
                          onKeyUp={ToValidation}
                          required
                        />
                        <span id="toV"></span>
                      </div>
                    </div>

                    <div className="sm:col-span-2 lg:ml-[50px]">
                      <label
                        for="departuredate"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        Departure Date<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div className="mt-2">
                        <input
                          id="departuredate"
                          name="departuredate"
                          type="date"
                          autoComplete="departuredate"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.departuredate}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-center sm:col-span-2">
                      <FontAwesomeIcon
                        icon={faArrowRightArrowLeft}
                        className="mx-auto mb-4 text-red-500"
                        style={{ marginTop: "40px" }}
                        onClick={swapValues1}
                      />
                    </div>
                    <div className="sm:col-span-2 lg:mr-[50px]">
                      <label
                        for="returndate"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        Return Date<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div className="mt-2">
                        <input
                          id="returndate"
                          name="returndate"
                          type="date"
                          autoComplete="returndate"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.returndate}
                          min={formData.departuredate}
                          required
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        for="pnr"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        PNR<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div className="mt-2">
                        <input
                          id="pnr"
                          name="pnr"
                          type="text"
                          autoComplete="pnr"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.pnr}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tripType === "One Way" && (
                <div>
                  <div className="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-2 lg:ml-[50px]">
                      <label
                        htmlFor="from"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        From <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div>
                        <input
                          id="from"
                          name="from"
                          type="text"
                          autoComplete="from"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                        focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          onKeyUp={LastnameValidation}
                          value={formData.from}
                          required
                        />
                        <span id="last"></span>
                      </div>
                    </div>

                    <div className="flex items-center justify-center sm:col-span-2">
                      <FontAwesomeIcon
                        icon={faArrowRightArrowLeft}
                        className="mx-auto mb-4 text-red-500"
                        style={{ marginTop: "40px" }}
                        onClick={swapValues}
                      />
                    </div>

                    <div className="sm:col-span-2 lg:mr-[50px]">
                      <label
                        htmlFor="to"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        To<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div>
                        <input
                          id="to"
                          name="to"
                          type="text"
                          autoComplete="from"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                        focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.to}
                          onKeyUp={ToValidation}
                          required
                        />
                        <span id="toV"></span>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        for="departuredate"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        Departure Date<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div className="mt-2">
                        <input
                          id="departuredate"
                          name="departuredate"
                          type="date"
                          autoComplete="departuredate"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.departuredate}
                          required
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        for="pnr"
                        className="text-black block text-sm font-semibold leading-6"
                      >
                        PNR<sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div className="mt-2">
                        <input
                          id="pnr"
                          name="pnr"
                          type="text"
                          autocomplete="pnr"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          // onKeyUp={PNRValidation}
                          value={formData.pnr}
                          required
                        />
                        <span id="pnrV"></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tripType === "MultiCity" && (
                <div>
                  <h2 className="mb-4 text-center text-lg font-semibold underline">
                    Multi City
                  </h2>
                  <div className="mt-4">
                    {/* <div className="mb-4 flex flex-wrap"> */}
                    {data.map((val, i) => (
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2 lg:ml-[50px] ">
                          <label
                            for="from2"
                            className="text-black block text-sm font-semibold leading-6"
                          >
                            From <sup className="mb-2 text-red-500">*</sup>
                          </label>
                          <div className="mt-2">
                            <input
                              id="from"
                              name="from"
                              type="text"
                              autoComplete="from"
                              className="block w-full  rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => handleChange(e, i)}
                              value={val.from}
                              required
                            />
                          </div>
                        </div>

                        <div className="mt-4 flex items-center justify-center sm:col-span-2 sm:mt-0">
                          <FontAwesomeIcon
                            icon={faArrowRightArrowLeft}
                            className="text-red-500"
                            style={{ marginTop: "40px" }}
                            onClick={() => swapValuesMultiCity(i)}
                          />
                        </div>
                        <div className="sm:col-span-2 lg:mr-[50px]">
                          <label
                            for="to2"
                            className="text-black block text-sm font-semibold leading-6"
                          >
                            To<sup className="mb-2 text-red-500">*</sup>
                          </label>
                          <div className="mt-2">
                            <input
                              id="to"
                              name="to"
                              type="text"
                              autoComplete="to"
                              className="block w-full  rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => handleChange(e, i)}
                              value={val.to}
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            for="pnr"
                            className="text-black block text-sm font-semibold leading-6"
                          >
                            PNR<sup className="mb-2 text-red-500">*</sup>
                          </label>
                          <div className="mt-2">
                            <input
                              id="pnr"
                              name="pnr"
                              type="text"
                              autocomplete="pnr"
                              className="block w-full  rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => handleChange(e, i)}
                              // onKeyUp={PNRValidation}
                              value={val.pnr}
                              required
                            />
                            <span id="pnrV"></span>
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            for="traveldate"
                            className="text-black mr-4 block text-sm font-semibold leading-6"
                          >
                            Travel Date{" "}
                            <sup className="mb-2 text-red-500">*</sup>
                          </label>
                          <div className="mt-2">
                            <input
                              id="traveldate"
                              name="traveldate"
                              type="date"
                              autoComplete="traveldate"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => handleChange(e, i)}
                              value={val.traveldate}
                              reuired
                            />
                          </div>
                        </div>

                        <button
                          type="button"
                          className="mt-7  rounded border-gray-600 bg-red-500 px-1 py-2 text-white hover:bg-red-700 "
                          onClick={() => handleDelete(i)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                    {/* </div> */}
                    <button
                      type="button"
                      className="mb-6 mt-7 rounded border-gray-600 bg-green-500 px-5 py-2 text-white hover:bg-green-600"
                      onClick={handleClick}
                    >
                      Add +
                    </button>
                  </div>
                </div>
              )}
              <div className=" grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    for="airlines"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Airlines<sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="airlines"
                      name="airlines"
                      type="text"
                      autocomplete="airlines"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      onKeyUp={AirlineValidation}
                      value={formData.airlines}
                      required
                    />
                    <span id="airlineV"></span>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    for="bookingsource"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Booking Source<sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="bookingsource"
                      name="bookingsource"
                      type="text"
                      autocomplete="bookingsource"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.bookingsource}
                      required
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="netcost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Net Cost<sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="netcost"
                      name="netcost"
                      type="text"
                      autocomplete="netcost"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      onKeyUp={NetVisaCostValidation}
                      value={formData.netcost}
                    />
                    <span id="netVisaCostValidation"></span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="markup"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Mark Up<sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="markup"
                      name="markup"
                      type="text"
                      autocomplete="markup"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      onKeyUp={MarkupValidation}
                      value={formData.markup}
                    />
                    <span id="MarkupValidation"></span>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    for="gstcost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    GST 18% on Mark Up
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="gstcost"
                      name="gstcost"
                      type="text"
                      autocomplete="gstcost"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.gstcost}
                      readOnly
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="idealcollection"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Ideal Collection
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="idealcollection"
                      name="idealcollection"
                      type="text"
                      autocomplete="idealcollection"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.idealcollection}
                      readOnly
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="deficit"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Deficit <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      id="deficit"
                      name="deficit"
                      type="text"
                      autocomplete="deficit"
                      className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.deficit}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 flex flex-wrap items-center justify-center">
                <button
                  type="submit"
                  className="rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  Save
                </button>
                <div className="mx-4 sm:mx-4"></div>{" "}
                {/* Space between buttons */}
                <button
                  className="rounded border-gray-600 bg-red-500 px-3 py-1 text-white hover:bg-red-700"
                  onClick={handleDiscard}
                >
                  Discard
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
          {/* </div> */}
        </div>
      ) : null}
      <div className={showTripTypePopUp ? "block" : "hidden"}>
        {showRoundTrip ? (
          <RoundTrip
            parentData={parentData}
            clientId={clientId}
            name={name}
            handleCloseTripType={updateTripTypePopUpState}
            triptype={formData.triptype}
          />
        ) : null}
        {showMultiCiti ? (
          <MultiCiti
            parentData={parentData}
            clientId={clientId}
            name={name}
            handleCloseTripType={updateTripTypePopUpState}
            tripType={formData.triptype}
          />
        ) : null}
      </div>
    </>
  );
}
