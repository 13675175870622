import Card from "components/card";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import NewLeedForm from "./NewLeedForm";
import { useMemo, useRef } from "react";
import { PiDotsThreeBold } from "react-icons/pi";
import UpdateForm from "./UpdateForm";
import { useAdmin } from "../../../hooks/useAdmin";
import Quotation from "views/auth/quotation/Quotation";
import { Link } from "react-router-dom";

const Marketplace = (props) => {
  const [selectedValue, setSelectedValue] = useState();
  const [showloading, setShowLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { getLeadDetails, leadData, loading } = useAdmin();
  const menuRef = useRef();

  // useEffect(() => {
  //   getLeadDetails();
  // }, [loading]);

  useEffect(() => {
    setTimeout(() => {
      getLeadDetails();
      setShowLoading(false);
    }, 2000);
  }, [loading]);

  useEffect(() => {
    setFilteredData(leadData);
  }, [loading, leadData]);

  // console.log(leadData);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    filterData(e.target.value);
  };

  const filterData = (selectedDate) => {
    const selectedDateObj = new Date(selectedDate);

    let updatedFilteredData = [];

    if (selectedDate) {
      updatedFilteredData = leadData.filter((item) => {
        const itemDate = new Date(item.dateOfLead);
        const itemDateStr = `${itemDate.getFullYear()}-${String(
          itemDate.getMonth() + 1
        ).padStart(2, "0")}-${String(itemDate.getDate()).padStart(2, "0")}`;
        return itemDateStr === selectedDate;
      });
    } else {
      updatedFilteredData = leadData;
    }

    setFilteredData(updatedFilteredData);
  };

  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [isCreateQuotationOpen, setIsCreateQuotationOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isNewLeadOpen, setIsNewLeadOpen] = useState(false);
  const [data1, setdata1] = useState();

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const openNewLeadForm = () => {
    setIsNewLeadOpen(true);
  };

  const closeNewLeadForm = () => {
    setIsNewLeadOpen(false);
    setIsUpdateForm(false);
    setIsCreateQuotationOpen(false);
  };

  const [isOpen, setIsOpen] = useState();
  const [openIndex, setOpenIndex] = useState(null);
  const [formData, setFormData] = useState({});

  const initialFormData = {};

  const toggleDropdown = (index) => {
    setIsOpen(true);
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // const toggleDropdown = (index) => {
  //   setIsOpen((prevOpen) => {
  //     const newState = prevOpen ? null : index;
  //     if (newState !== null) {
  //       document.body.addEventListener('click', handleOutsideClick);
  //     } else {
  //       document.body.removeEventListener('click', handleOutsideClick);
  //     }
  //     return newState;
  //   });
  // };

  // const handleOutsideClick = (event) => {
  //   if (!dropdownRef.current.contains(event.target)) {
  //     setIsOpen(false);
  //   }
  // };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (e, data) => {
    if (e === "Edit") {
      setdata1(data);
      setIsUpdateForm(true);
      setIsCreateQuotationOpen(false); // Close the Create Quotation form if it's open
      setFormData(initialFormData);
    } else if (e === "Create Quotation") {
      setdata1(data);
      setIsCreateQuotationOpen(true);
      setIsUpdateForm(false); // Close the Edit form if it's open
      setFormData(initialFormData);
    }
  };
  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 when data changes
  }, [filteredData, searchTerm]);

  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const filteredLeadData = useMemo(() => {
    const sortedData = filteredData
      ?.slice()
      .sort((a, b) => new Date(b.dateOfLead) - new Date(a.dateOfLead)); // Sorting in descending order
    return sortedData?.filter(
      (item) =>
        item?.client_type === "Lead" &&
        (item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [filteredData, searchTerm]);

  //       (item?.package_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         item?.name.toLowerCase().includes(searchTerm.toLowerCase()))
  //   );

  //   // .slice(firstIndex, lastIndex);
  // },[filteredData, searchTerm]);

  const record = filteredLeadData?.length
    ? filteredLeadData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredLeadData?.length
    ? Math.ceil(filteredLeadData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(nPages);
  };

  return (
    <>
      {showloading ? (
        <Skeleton count={5} height={40} />
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between"></header>
          <div className="flex justify-end">
          <div className="flex items-center">
                        <button
                          type="submit"
                          className="flex items-center justify-center rounded-lg bg-blue-500 px-3 py-2 text-white hover:bg-blue-700"
                          onClick={openNewLeadForm}
                        >
                          <IoMdAdd className="mr-1" />
                          Lead
                        </button>
                      </div>
          </div>
          <div class="mt-4 flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
              <div class="inline-block min-w-full p-1.5 align-middle">
                <div class="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                  <div className="px-4 py-3">
                    <div className="flex items-center justify-between">
                      <div className="relative max-w-xs">
                        <label className="sr-only">Search</label>
                        <input
                          type="text"
                          name="hs-table-with-pagination-search"
                          id="hs-table-with-pagination-search"
                          className="dark:bg-slate-900 border-black block w-full rounded-lg border px-4 py-2 ps-10 text-sm"
                          placeholder="Search By Name/Email"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                          <svg
                            className="size-4 text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                          </svg>
                        </div>
                      </div>
                      <div className="relative">
                        <div className="dark:bg-slate-900 flex items-center space-x-2 text-nowrap px-4 py-2 ps-10 text-sm text-gray-400">
                          <label htmlFor="dateRange">Date :</label>
                          <input
                            type="date"
                            id="dateRange"
                            // value={startDate}
                            // onChange={(e) => setStartDate(e.target.value)}
                            value={selectedValue}
                            onChange={handleChange}
                            className="rounded border p-2"
                          />
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  {isNewLeadOpen ? (
                    <NewLeedForm onClose={closeNewLeadForm} />
                  ) : (
                    ""
                  )}
                  {isUpdateForm ? (
                    <UpdateForm data={data1} onClose={closeNewLeadForm} />
                  ) : (
                    " "
                  )}
                  {isCreateQuotationOpen ? (
                    <Quotation data={data1} onClose={closeNewLeadForm} />
                  ) : (
                    " "
                  )}
                  <div class="overflow-hidden">
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead class="bg-gray-400 dark:bg-gray-700">
                        <tr className="whitespace-nowrap">
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Sr No
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Contact
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Note
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Email Id
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Date Of Lead
                          </th>

                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                        {record && record.length > 0 ? (
                          record
                            .slice()
                            .sort(
                              (a, b) =>
                                new Date(b.dateOfLead) - new Date(a.dateOfLead)
                            )
                            .map((data, index) => {
                              let note = data.note + "";
                              note =
                                note.length > 20
                                  ? note.substring(0, 20) + "..."
                                  : note;
                              return (
                                <tr key={index}>
                                  <td class="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {index +
                                      1 +
                                      (currentPage - 1) * recordPerPage}
                                  </td>
                                  <td class="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.name}
                                  </td>
                                  <td class="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.mobile_no}
                                  </td>
                                  <td class="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {note}
                                  </td>
                                  <td class="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.email}
                                  </td>
                                  <td class="whitespace-nowrap px-3 py-1 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {new Date(
                                      data.dateOfLead
                                    ).toLocaleDateString("en-IN")}
                                  </td>

                                  <td class="whitespace-nowrap px-3 py-1 text-center text-sm font-medium">
                                    <button
                                      onClick={() => toggleDropdown(index)}
                                      class="text-black mt-1 items-center px-2 py-1 text-center sm:ml-3" // Adjusted padding here
                                    >
                                      <PiDotsThreeBold />
                                    </button>
                                    {openIndex === index && (
                                      <div
                                        ref={menuRef}
                                        className="origin-top-center ring-black absolute right-0 mt-2 w-44 rounded-md bg-white shadow-lg ring-1 ring-opacity-5" // Adjusted width here
                                      >
                                        <div
                                          className="py-1"
                                          role="menu"
                                          aria-orientation="vertical"
                                          aria-labelledby="options-menu"
                                        >
                                          <button
                                            onClick={() =>
                                              handleOptionClick("Edit", data)
                                            }
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" // Adjusted padding here
                                            role="menuitem"
                                          >
                                            Edit
                                          </button>
                                          <button
                                            className="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" // Adjusted padding here
                                            role="menuitem"
                                            onClick={() =>
                                              handleOptionClick(
                                                "Create Quotation",
                                                data
                                              )
                                            }
                                          >
                                            Create Quotation
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="7" className="py-4 text-center">
                              No data found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      <nav className="mt-4 flex items-center justify-center -space-x-px rounded-md shadow-sm">
        <ul className="flex items-center justify-center border-t px-4 py-3 sm:px-6">
          {currentPage !== 1 && currentPage !== 2 && (
            <>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={goToFirstPage}
                  aria-label="First"
                >
                  First
                </Link>
              </li>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={prePage}
                  aria-label="Previous"
                >
                  Previous
                </Link>
              </li>
            </>
          )}

          {numbers.map(
            (n, i) =>
              (n === currentPage ||
                n === currentPage - 1 ||
                n === currentPage + 1) && (
                <li
                  className={`page-item ${currentPage === n ? "active" : ""}`}
                  key={i}
                >
                  <Link
                    href="#"
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                      currentPage === n ? "bg-gray-200" : ""
                    }`}
                    onClick={() => changeCPage(n)}
                  >
                    {n}
                  </Link>
                </li>
              )
          )}
          {currentPage < nPages && (
            <>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={nextPage}
                  aria-label="Next"
                >
                  Next
                </Link>
              </li>
              <li className="page-item">
                <Link
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  onClick={goToLastPage}
                  aria-label="Last"
                >
                  Last
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Marketplace;
