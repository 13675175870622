import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    loading: false,
    isAuthenticated: false
}

const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state) => {
            state.isAuthenticated = true
        },
        logOut: (state) => {
            state.isAuthenticated = false
        }
    }
  
})

export const { login, logOut } = authReducer.actions
export default authReducer.reducer