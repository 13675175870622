import Card from "components/card";

const Widget = ({ icon, title, subtitle, progress }) => {
  return (
    <Card extra=" items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span
            className="flex items-center dark:text-white"
            style={{ color: "purple" }}
          >
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col items-center justify-center">
        <p className="font-dm text-xl font-medium" style={{ color: "purple" }}>
          {title}
        </p>
        {/* <div className="mb-4 h-4 w-full rounded-full bg-gray-200 dark:bg-gray-700">
          <div
            className="h-4 rounded-full bg-blue-600 dark:bg-blue-500"
            style={{ width: `${progress}%` }}
          ></div>
        </div> */}
        <h4 className="mb-4flex justify-center text-2xl font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
