/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import finalLogo from "../../components/sidebar/Final_Logo.png";
import routes from "routes.js";
import { IoLogOutSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className="mx-4 mt-4 flex items-center">
        <div className="ml-1 mt-1 h-10 font-poppins text-lg font-bold uppercase text-navy-700 dark:text-white sm:h-12 sm:text-xl md:h-14 md:text-2xl lg:h-16 lg:text-3xl xl:h-20 xl:text-4xl">
          <img
            src={finalLogo}
            alt="Final Logo"
            style={{ width: "200px", height: "80px" }}
          />
        </div>
      </div>

      <ul className="mt-7 pt-3">
        {/* {routes.map((route) => (
          <li key={route.name}>
            <Links routes={routes} />
          </li>
        ))} */}
        <Links routes={routes} />
        
      </ul>
 
      <div className="flex justify-center">{/* <SidebarCard /> */}</div>
      <div className="flex justify-center">{/* <SidebarCard /> */}</div>
    </div>
  );
};

export default Sidebar;
