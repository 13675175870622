import { useEffect, useState } from "react";

import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { Validation } from "views/admin/marketplace/components/Validation";
import { toast } from "react-toastify";
export default function UpdateFlightRoundTrip({ data, onClose }) {
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [disablesubmit,setdisableSubmit]=useState("");
  const [id, setId] = useState(data._id);

  const { updateFlightInvoice } = useAdmin();

  const [formData, setFormData] = useState({
    trip_type: "",
    cust_name: "",
    from: "",
    to: "",
    departure_date: "",
    travel_date_return: "",
    airline: "",
    pnr: "",
    booking_source: "",
    net_cost: "",
    mark_up: "",
    gstcost: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
    cancellation_charges: "",
    refund_amount: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        trip_type: data.trip_type,
        cust_name: data.cust_name,
        from: data.from,
        to: data.to,
        departure_date: data.departure_date,
        travel_date_return: data.travel_date_return,
        airline: data.airline,
        pnr: data.pnr,
        booking_source: data.booking_source,
        net_cost: data.net_cost,
        mark_up: data.mark_up,
        gstcost: data.gstcost,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        cancellation_charges: data.cancellation_charges,
        deficit: data.deficit,
        refund_amount: data.refund_amount,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = name === "pnr" ? value.toUpperCase() : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: uppercaseValue,
    }));

    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };

  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      idealcollection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setdisableSubmit(true);
  
    const requiredFields = [
      "from",
      "to",
      "departure_date",
      "travel_date_return",
      "net_cost",
      "mark_up",
      "airline",
      "booking_source",
    ];
  
    const isValid = requiredFields.every((field) => formData[field] && formData[field].trim() !== "");
  
    if (!isValid) {
      toast.error("Please fill all mandatory fields");
      setdisableSubmit(false);
      return;
    }
  
    updateFlightInvoice({ id, formData })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error("An error occurred while updating the invoice");
        setdisableSubmit(false);
      });
  };
  
  //Validation Code
  const {
    AlphaValidation,
    ToValidation,
    error1,
    errorMessage,
    PnrValidation,
    pnrError,
    AirlineValidation,
    airlineError,
    BookingSourceValidation,
    bookingError,
    NetCostValidation,
    netcostError,
    MarkupValidation,
    markupError,
    ActualCollectionValidation,
    actuallcollectionError,
    CancellationValidation,
    cancellationError,
    RefundValidation,
    refundError,
  } = Validation();

  return (
    <>
      <div className="relative">
        <div class="fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                {" "}
                <FaTimes size={24} />{" "}
              </span>
              <h2 className="mb-4 text-center text-lg font-semibold underline">
                Update Flight Round Trip Invoice
              </h2>

              {/* </div> */}

              <form onSubmit={handleSubmit}>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Trip Type <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="trip_type"
                        id="trip_type"
                        autocomplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.trip_type}
                        readOnly
                      />
                      <span id="last"></span>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="cust_name"
                        id="cust_name"
                        autocomplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        readOnly
                      />
                      <span id="last"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-2 lg:ml-[50px]">
                    <label
                      for="from"
                      class="text-black text-lm block font-semibold leading-6"
                    >
                      From <sup className="mb-2 text-red-500">*</sup>
                    </label>

                    <div class="mt-2">
                      <input
                        id="from"
                        name="from"
                        type="text"
                        autoComplete="from"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.from}
                        onKeyUp={AlphaValidation}
                        required
                      />
                      {errorMessage && (
                        <span style={{ color: "red" }}>{errorMessage}</span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-center sm:col-span-2">
                    <FontAwesomeIcon
                      icon={faArrowRightArrowLeft}
                      className="mx-auto mb-4 text-red-500"
                      style={{ marginTop: "40px" }}
                      // onClick={swapValues}
                    />
                  </div>

                  <div class="sm:col-span-2 lg:mr-[50px]">
                    <label
                      for="to"
                      class="text-black text-lm block font-semibold leading-6"
                    >
                      To<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="to"
                        name="to"
                        type="text"
                        autoComplete="from"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.to}
                        onKeyUp={ToValidation}
                        required
                      />
                      {error1 && <span style={{ color: "red" }}>{error1}</span>}
                    </div>
                  </div>

                  <div class="sm:col-span-2 lg:ml-[50px]">
                    <label
                      for="departure_date"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Departure Date <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="departure_date"
                        name="departure_date"
                        type="date"
                        autoComplete="departure_date"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={
                          formData.departure_date
                            ? formData.departure_date.split("T")[0]
                            : ""
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-center sm:col-span-2">
                    <FontAwesomeIcon
                      icon={faArrowRightArrowLeft}
                      className="mx-auto mb-4 text-red-500"
                      style={{ marginTop: "40px" }}
                      // onClick={swapValues}
                    />
                  </div>
                  <div class="sm:col-span-2 lg:mr-[50px]">
                    <label
                      for="travel_date_return"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Return Date <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="travel_date_return"
                        name="travel_date_return"
                        type="date"
                        autoComplete="travel_date_return"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={
                          formData.travel_date_return
                            ? formData.travel_date_return.split("T")[0]
                            : ""
                        }
                        min={formData.departuredate}
                        required
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="pnr"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      PNR<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="pnr"
                        name="pnr"
                        type="text"
                        autoComplete="pnr"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.pnr}
                        onKeyUp={PnrValidation}
                        required
                      />
                      {pnrError && (
                        <span style={{ color: "red" }}>{pnrError}</span>
                      )}
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="airlines"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Airlines<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="airline"
                        name="airline"
                        type="text"
                        autoComplete="airline"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.airline}
                        onKeyUp={AirlineValidation}
                        required
                      />
                      {airlineError && (
                        <span style={{ color: "red" }}>{airlineError}</span>
                      )}
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="bookingsource"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Booking Source<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="booking_source"
                        name="booking_source"
                        type="text"
                        autoComplete="bookingsource"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.booking_source}
                        onKeyUp={BookingSourceValidation}
                        required
                      />
                      {bookingError && (
                        <span style={{ color: "red" }}>{bookingError}</span>
                      )}
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="netcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="net_cost"
                        name="net_cost"
                        type="text"
                        autoComplete="netcost"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.net_cost}
                        onKeyUp={NetCostValidation}
                        required
                      />
                      {netcostError && (
                        <span style={{ color: "red" }}>{netcostError}</span>
                      )}
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="markup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="mark_up"
                        name="mark_up"
                        type="text"
                        autoComplete="markup"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.mark_up}
                        onKeyUp={MarkupValidation}
                        required
                      />

                      {markupError && (
                        <span style={{ color: "red" }}>{markupError}</span>
                      )}
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gstcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      GST 18% on Mark Up
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="gstcost"
                        name="gstcost"
                        type="text"
                        autoComplete="gstcost"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gstcost}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="idealcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="ideal_collection"
                        name="ideal_collection"
                        type="text"
                        autoComplete="idealcollection"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="actualcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Actual Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="actual_collection"
                        name="actual_collection"
                        type="text"
                        autoComplete="actualcollection"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.actual_collection}
                        onKeyUp={ActualCollectionValidation}
                        required
                      />
                      {actuallcollectionError && (
                        <span style={{ color: "red" }}>
                          {actuallcollectionError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="deficit"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="deficit"
                        name="deficit"
                        type="text"
                        autoComplete="deficit"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="cancellationCharges"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Cancellation Charges
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="cancellation_charges"
                        name="cancellation_charges"
                        type="text"
                        autoComplete="cancellationCharges"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cancellation_charges}
                        onKeyUp={CancellationValidation}
                        required
                      />
                      {cancellationError && (
                        <span style={{ color: "red" }}>
                          {cancellationError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="refundAmount"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Refund Amount<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="refund_amount"
                        name="refund_amount"
                        type="text"
                        autoComplete="refundAmount"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.refund_amount}
                        onKeyUp={RefundValidation}
                        required
                      />
                      {refundError && (
                        <span style={{ color: "red" }}>{refundError}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex place-content-center">
                  <button
                    onClick={handleSubmit}
                    disabled={disablesubmit}
                    className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  >
                    Update
                  </button>
                  {/* <button
                    class="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                    onClick={handleClose}
                  >
                    Back
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
