// // import CreateQuotation from "./CreateQuotation";
// import Card from "components/card";
// import { useEffect, useState } from "react";
// import { IoFilter } from "react-icons/io5";
// import { IoMdAdd } from "react-icons/io";
// import { useMemo } from "react";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
// import UpdateForm from "views/admin/marketplace/UpdateForm";
// import Quotation from "views/auth/quotation/Quotation";
// import { useAdmin } from "hooks/useAdmin";
// import { Link } from "react-router-dom";

// const Reports = (props) => {
//   const {
//     getCombinedQuotationData,
//     saveInvoiceDetails,
//     quotationCombinedData,
//     getCombinedInvoiceData,
//     invoiceCombinedData,
//     getLeadDetails,
//     leadData,
//     loading,
//   } = useAdmin();

//   const [searchTerm, setSearchTerm] = useState("");
//   const [filteredData, setFilteredData] = useState([]);
//   const [showloading, setShowLoading] = useState(true);

//   useEffect(() => {
//     getCombinedQuotationData();
//     setShowLoading(false);
//   }, [loading]);
//   useEffect(() => {
//     setFilteredData(quotationCombinedData);
//     setShowLoading(false);
//   }, [quotationCombinedData]);

//   useEffect(() => {
//     getCombinedInvoiceData();
//     setShowLoading(false);
//   }, [loading]);

//   useEffect(() => {
//     getLeadDetails();
//     setShowLoading(false);
//   }, [loading]);

//   useEffect(() => {
//     setFilteredData(leadData);
//     setShowLoading(false);
//   }, [loading]);

//   const handleChange = (e) => {
//     setSelectedValue(e.target.value);

//     switch (e.target.value) {
//       case "customerdetails":
//         setFilteredData(
//           leadData.filter((item) => item.client_type === "Customer")
//         );
//         break;
//       case "quotation":
//         setFilteredData(quotationCombinedData);
//         break;
//       case "leads":
//         setFilteredData(leadData);
//         break;
//       case "invoice":
//         setFilteredData(invoiceCombinedData);
//         break;
//       default:
//         setFilteredData([]);
//     }
//   };

//   const { columnsData, tableData } = props;
//   const columns = useMemo(() => columnsData, [columnsData]);
//   const data = useMemo(() => tableData, [tableData]);
//   const [isUpdateForm, setIsUpdateForm] = useState(false);
//   const [selectedValue, setSelectedValue] = useState();
//   const [isFilterOpen, setIsFilterOpen] = useState(false);
//   const [QuotationCustomerDetailes, showQuotationCustomerDetailes] =
//     useState(false);
//   const [data1, setdata1] = useState();

//   const toggleFilterDropdown = () => {
//     setIsFilterOpen(!isFilterOpen);
//   };

//   const openNewLeadForm = () => {
//     showQuotationCustomerDetailes(true);
//   };

//   const closeNewLeadForm = () => {
//     showQuotationCustomerDetailes(false);
//   };

//   const [isOpen, setIsOpen] = useState();

//   const [selectedOption, setSelectedOption] = useState(null);
//   const [openIndex, setOpenIndex] = useState(null);
//   const [formData, setFormData] = useState({});

//   const initialFormData = {};

//   const handleUpdate = (updatedData) => {
//     setFormData(updatedData);
//   };

//   const toggleDropdown = (index) => {
//     setIsOpen(true);
//     setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
//   };
//   const handleOptionClick = (e, data) => {
//     setIsOpen(false);
//     if (e === "Edit") {
//       setdata1(data);
//       setIsUpdateForm(true);
//       setFormData(initialFormData);
//     } else {
//       setFormData({});
//     }
//   };

//   const handleConvertInvoice = (id, quotationType) => {
//     saveInvoiceDetails({ id, quotationType });
//   };

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);

//     const searchValue = e.target.value.toLowerCase();
//     const filteredResults = quotationCombinedData.filter((item) => {
//       const packageTypeMatch = item?.package_type
//         .toLowerCase()
//         .includes(searchValue);
//       const customerNameMatch = item?.cust_name
//         .toLowerCase()
//         .includes(searchValue);
//       return packageTypeMatch || customerNameMatch;
//     });

//     setFilteredData(filteredResults);
//   };

//   // pagination

//   let length = filteredData?.length;
//   const [currentPage, setCurrentPage] = useState(1);
//   const recordPerPage = 10;
//   const lastIndex = currentPage * recordPerPage;
//   const firstIndex = lastIndex - recordPerPage;

//   const record = filteredData?.length
//     ? filteredData.slice(firstIndex, lastIndex)
//     : 0;
//   const nPages = filteredData?.length
//     ? Math.ceil(filteredData?.length / recordPerPage)
//     : 0;
//   const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

//   const prePage = () => {
//     if (currentPage !== 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const nextPage = () => {
//     if (currentPage !== nPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const changeCPage = (id) => {
//     setCurrentPage(id);
//   };

//   return (
//     <>
//       {showloading ? (
//         <Skeleton count={10} height={40} />
//       ) : (
//       <Card extra={"w-full pb-10 p-4 h-full"}>
//         <header className="relative flex items-center justify-between">
//           <div className="text-xl font-bold text-navy-700 underline dark:text-white">
//             Reports
//           </div>
//         </header>
//         <div class="mt-4 flex flex-col">
//           <div class="-m-1.5 overflow-x-auto">
//             <div class="inline-block min-w-full p-1.5 align-middle">
//               <div class="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
//                 <div className="px-4 py-3">
//                   <div className="flex items-center justify-between">
//                     <div className="relative max-w-xs">
//                       <label className="sr-only">Search</label>
//                       <input
//                         type="text"
//                         name="hs-table-with-pagination-search"
//                         id="hs-table-with-pagination-search"
//                         className="dark:bg-slate-900 border-black block w-full rounded-lg border px-4 py-2 ps-10 text-sm"
//                         placeholder="Search by Name/Package"
//                         value={searchTerm}
//                         onChange={handleSearch}
//                       />
//                       <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
//                         <svg
//                           className="size-4 text-blue-500"
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="20"
//                           height="20"
//                           viewBox="0 0 24 24"
//                           fill="none"
//                           stroke="currentColor"
//                           strokeWidth="2"
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                         >
//                           <circle cx="11" cy="11" r="8" />
//                           <path d="m21 21-4.3-4.3" />
//                         </svg>
//                       </div>
//                     </div>
//                     <div className="relative">
//                       {/* <button
//                         type="button"
//                         className="flex items-center justify-center rounded-lg bg-red-500 px-5 py-2 text-white"
//                         onClick={toggleFilterDropdown}
//                       >
//                         <IoFilter className="mb-1 mr-1" /> Filter
//                       </button>
//                       {isFilterOpen && (
//                         <div className="absolute right-0 z-10 mt-2 flex w-48 justify-center"> */}
//                           <div className="-right-1/2 mx-auto translate-x-1/2 transform rounded-lg bg-white py-1 shadow-lg">
//                             <select
//                               id="exampleSelect"
//                               value={selectedValue}
//                               onChange={handleChange}
//                               className="mt-1 rounded-md border border-gray-300 p-2 focus:border-blue-300 focus:outline-none focus:ring"
//                             >
//                               <option value="">Filter</option>
//                               <option value="customerdetails">
//                                 Customer Details
//                               </option>
//                               <option value="invoice">Invoices</option>
//                               <option value="leads">Leads</option>
//                               <option value="quotation">Quotation</option>
//                               {/* <option value="Flight">Flight</option>
//                               <option value="Insurance">Insurance</option>*/}
//                             </select>
//                           </div>
//                         {/* </div>
//                       )} */}
//                     </div>
//                     <div className="flex items-center"></div>
//                   </div>
//                 </div>
//                 {QuotationCustomerDetailes ? (
//                   <Quotation onClose={closeNewLeadForm} />
//                 ) : (
//                   ""
//                 )}
//                 {isUpdateForm ? (
//                   <UpdateForm
//                     data={data1}
//                     onUpdate={handleUpdate}
//                     onClose={closeNewLeadForm}
//                   />
//                 ) : (
//                   " "
//                 )}

//                 <div class="overflow-hidden">
//                   <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
//                     <thead class="bg-gray-400 dark:bg-gray-700">
//                       <tr className="whitespace-nowrap">
//                         <th
//                           scope="col"
//                           class="px-4 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
//                         >
//                           Sr No
//                         </th>
//                         <th
//                           scope="col"
//                           class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
//                         >
//                           Customer Name
//                         </th>
//                         <th
//                           scope="col"
//                           class="px-4 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
//                         >
//                           Package Type
//                         </th>
//                         <th
//                           scope="col"
//                           class="px-4 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
//                         >
//                           Start Date
//                         </th>
//                         <th
//                           scope="col"
//                           class="px-4 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
//                         >
//                           End Date
//                         </th>
//                         <th
//                           scope="col"
//                           class="px-4 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
//                         >
//                           Status
//                         </th>
//                         <th
//                           scope="col"
//                           class="px-6 py-3 text-end text-sm font-extrabold uppercase text-gray-900"
//                         >
//                           Client Type
//                         </th>
//                       </tr>
//                     </thead>

//                     <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
//                       {record &&
//                         invoiceCombinedData &&
//                         record.map((data, index) => {
//                           return (
//                             <>
//                               <tr>
//                                 <td class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
//                                   {index + 1}
//                                 </td>
//                                 <td class="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
//                                   {data.cust_name}
//                                 </td>
//                                 <td class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
//                                   <a href="#"> {data.package_type}</a>
//                                 </td>
//                                 <td class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-800 dark:text-gray-200">
//                                   {new Date(
//                                     data.start_date
//                                   ).toLocaleDateString()}
//                                 </td>
//                                 <td class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-800 dark:text-gray-200">
//                                   {new Date(data.end_date).toLocaleDateString()}
//                                 </td>

//                                 <td class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-800 dark:text-gray-200">
//                                   {data.status}
//                                 </td>

//                                 <td class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-800 dark:text-gray-200">
//                                   {data.status}
//                                 </td>
//                                 <td class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium">
//                                   {openIndex === index && (
//                                     <div className="origin-top-center ring-black absolute right-0 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-opacity-5">
//                                       <div
//                                         className="py-1"
//                                         role="menu"
//                                         aria-orientation="vertical"
//                                         aria-labelledby="options-menu"
//                                       ></div>
//                                     </div>
//                                   )}
//                                 </td>
//                               </tr>
//                             </>
//                           );
//                         })}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <nav className="flex items-center justify-center -space-x-px rounded-md shadow-sm">
//             <ul className="flex items-center justify-center border-t px-4 py-3 sm:px-6 ">
//               <li className="page-item">
//                 <Link
//                   href="#"
//                   className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
//                   onClick={prePage}
//                   aria-label="Previous"
//                 >
//                   Previous
//                 </Link>
//               </li>
//               {numbers.map(
//                 (n, i) =>
//                   (n === currentPage ||
//                     n === currentPage - 1 ||
//                     n === currentPage + 1) && (
//                     <li
//                       className={`page-item ${
//                         currentPage === n ? "active" : ""
//                       }`}
//                       key={i}
//                     >
//                       <Link
//                         href="#"
//                         className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
//                           currentPage === n ? "bg-gray-200" : ""
//                         }`}
//                         onClick={() => changeCPage(n)}
//                       >
//                         {n}
//                       </Link>
//                     </li>
//                   )
//               )}
//               <li className="page-item">
//                 <Link
//                   href="#"
//                   className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
//                   onClick={nextPage}
//                   aria-label="Next"
//                 >
//                   Next
//                 </Link>
//               </li>
//             </ul>
//           </nav>
//         </Card>
//       )}
//     </>
//   );
// };

// export default Reports;

import React, { useState } from "react";
import LeadReports from "./LeadReports";
import QuotationReports from "./QuotationReports";
import InvoiceReports from "./InvoiceReports";
import Card from "components/card";
import CustomerReports from "./CustomerReports";

const Reports = () => {
  const [activetab, setActivetab] = useState("tab1");

  const handleTab = (tab) => {
    setActivetab(tab);
  };

  return (
    <>
      <Card>
        <div className="mx-auto w-full justify-center px-4 sm:px-6 lg:px-8 ">
          <ul
            className="mb-3 mt-6 flex flex-wrap items-center justify-center bg-gray-400 px-2 py-2 text-center text-lg font-semibold text-blue-900 dark:bg-gray-700 sm:flex-nowrap"
            style={{ borderRadius: "8px" }}
          >
            <li className="mb-2 mr-4 sm:mb-0 sm:me-4">
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab1" ? "purple" : "inherit",
                  borderBottom:
                    activetab === "tab1" ? "2px solid purple" : "none",
                }}
                onClick={() => handleTab("tab1")}
              >
                Lead Report
              </button>
            </li>
            <li className="mb-2 sm:mb-0 sm:me-4">
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab2" ? "purple" : "inherit",
                  borderBottom:
                    activetab === "tab2" ? "2px solid purple" : "none",
                }}
                onClick={() => handleTab("tab2")}
              >
                Quotation Report
              </button>
            </li>
            <li className="mb-2 sm:mb-0 sm:me-4">
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab3" ? "purple" : "inherit",
                  borderBottom:
                    activetab === "tab3" ? "2px solid purple" : "none",
                }}
                onClick={() => handleTab("tab3")}
              >
                Invoice Report
              </button>
            </li>
            <li className="mb-2 sm:mb-0">
              <button
                type="button"
                className="tab-item"
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  alignItems: "center",
                  textAlign: "center",
                  color: activetab === "tab4" ? "purple" : "inherit",
                  borderBottom:
                    activetab === "tab4" ? "2px solid purple" : "none",
                }}
                onClick={() => handleTab("tab4")}
              >
                Customer Report
              </button>
            </li>
          </ul>

          {/* Conditionally render the corresponding component based on the active tab */}
          <div className="w-full">
            {activetab === "tab1" && <LeadReports />}
            {activetab === "tab2" && <QuotationReports />}
            {activetab === "tab3" && <InvoiceReports />}
            {activetab === "tab4" && <CustomerReports />}
          </div>
        </div>
      </Card>
    </>
  );
};

export default Reports;
