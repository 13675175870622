import { useEffect, useState } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
export default function UpdateHotelBook({ data, onClose }) {
  const [netCost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [gstmarkup, setGstmarkup] = useState("");
  const { updateHotelbookInvoice } = useAdmin();
  const [disableSubmit, setdisableSubmit] = useState('');
  const [id, setId] = useState(data._id);

  const [formData, setFormData] = useState({
    quotation_type: "",
    cust_name: "",
    start_date: "",
    end_date: "",
    proposal_title: "",
    budget: "",
    follow_up_date: "",
    no_of_adult: "",
    no_of_children: "",
    package_type: "",
    hotel_name: "",
    check_in_date: "",
    check_out_date: "",
    no_of_rooms: "",
    booking_source: "",
    net_cost: "",
    mark_up: "",
    gst_on_markup: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
    cancellation_charges: "",
    refund_amount: "",
    date_time: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        quotation_type: data.quotation_type,
        cust_name: data.cust_name,
        start_date: data.start_date,
        end_date: data.end_date,
        proposal_title: data.proposal_title,
        budget: data.budget,
        follow_up_date: data.follow_up_date,
        no_of_adult: data.no_of_adult,
        no_of_children: data.no_of_children,
        package_type: data.package_type,
        hotel_name: data.hotel_name,
        check_in_date: data.check_in_date,
        check_out_date: data.check_out_date,
        no_of_rooms: data.no_of_rooms,
        booking_source: data.booking_source,
        net_cost: data.net_cost,
        mark_up: data.mark_up,
        gst_on_markup: data.gst_on_markup,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        deficit: data.deficit,
        cancellation_charges: data.cancellation_charges,
        refund_amount: data.refund_amount,
        date_time: data.date_time,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "gstmarkup") {
      setGstmarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };
  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;

    setFormData((prevFormData) => ({
      ...prevFormData,
      gst_on_markup: withgst,
      ideal_collection: idealcost,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  //Validation Code
  const {
    NetVisaCostValidation,
    MarkUpValidation,
    ActualCollectionValidation,
    CancelationChargesValidation,
    RefundAmountValidation,
    NumberOfRoomValidation,
  } = Validation();

  //form close
  const handleClose = () => {
    onClose();
  };
  const handleSubmit = (e) => {
     setdisableSubmit(true);
    e.preventDefault();
   

    // Check if all mandatory fields are filled
    const requiredFields = [
   
      "hotel_name",
      "check_in_date",
      "check_out_date",
      "no_of_rooms",
      "booking_source",
        "net_cost",
        "mark_up",
      //  "ideal_collection",
      //  "actual_collection",
      //  "gst_on_markup",
      //  "deficit",
       "cancellation_charges",
        "refund_amount",
       "date_time",
    ];
    const isValid = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (!isValid) {
      toast.error("Please fill all mandatory fields");
         setdisableSubmit(false)
      return;
    }

    updateHotelbookInvoice({ id, formData });
    setInterval(() => {
      window.location.reload();
    }, 1000);
  };

  // console.log(data);

  return (
    <>
      <div className="relative">
        <div class="fixed inset-0 z-50 m-1  items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 pb-10 lg:pl-10 lg:pr-10 pl-5 pr-5 pt-10 backdrop-blur-0">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                {" "}
                <FaTimes size={24} />{" "}
              </span>
              <h2 className="mb-4 text-center text-lg font-semibold underline ">
                Update Hotel Booking Invoice
              </h2>
              <form onSubmit={handleSubmit}>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label
                      for="hotelname"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="custname"
                        id="custname"
                        autocomplete="hotel_name"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        required
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="hotelname"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Hotel Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="hotel_name"
                        id="hotel_name"
                        autocomplete="hotel_name"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.hotel_name}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="checkIn"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Check In <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="date"
                        name="check_in_date"
                        id="check_in_date"
                        autocomplete="check_in_date"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={
                          formData.check_in_date
                            ? formData.check_in_date.split("T")[0]
                            : ""
                        }
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="numofRooms"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Number of Rooms <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="no_of_rooms"
                        id="no_of_rooms"
                        autocomplete="no_of_rooms"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.no_of_rooms}
                        onKeyUp={NumberOfRoomValidation}
                      />
                      <span id="numberOfRoomValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="checkOut"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Check Out <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="date"
                        name="check_out_date"
                        id="check_out_date"
                        autocomplete="check_out_date"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={
                          formData.check_out_date
                            ? formData.check_out_date.split("T")[0]
                            : ""
                        }
                        onChange={handleInputChange}
                        min={formData.check_in_date}
                        // dateFormat="EEE MMM dd yyyy HH:mm:ss 'GMT'Z (z)"
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="bookingsource"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Booking Source <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="booking_source"
                        id="booking_source"
                        autocomplete="bookingsource"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.booking_source}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="netcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="net_cost"
                        id="net_cost"
                        autocomplete="net_cost"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.net_cost}
                        onKeyUp={NetVisaCostValidation}
                      />
                      <span id="netVisaCostValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="markup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="mark_up"
                        id="mark_up"
                        autocomplete="mark_up"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.mark_up}
                        onKeyUp={MarkUpValidation}
                      />
                      <span id="markUpValidation"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gstmarkup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      GST 18% on Markup
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="gst_on_markup"
                        id="gst_on_markup"
                        autocomplete="gst_on_markup"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gst_on_markup}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="idealcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="idealcollection"
                        id="idealcollection"
                        autocomplete="idealcollection"
                        class="ring-grey-300  block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 
                ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="actualcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Actual Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="actual_collection"
                        id="actual_collection"
                        autocomplete="actual_collection"
                        class="ring-grey-300  block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 
                ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.actual_collection}
                        onKeyUp={ActualCollectionValidation}
                      />
                      <span id="actualCollectionValidation"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="deficit"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="deficit"
                        id="deficit"
                        autocomplete="deficit"
                        class="ring-grey-300  block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 
                ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="cancelcharges"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Cancellation Charges{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="cancellation_charges"
                        id="cancellation_charges"
                        autoComplete="cancellation_charges"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cancellation_charges}
                        onKeyUp={CancelationChargesValidation}
                      />
                      <span id="cancelationChargesValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="refundamt"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Refund Amount <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="refund_amount"
                        id="refund_amount"
                        autocomplete="refundamt"
                        class="ring-grey-300 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 
                ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.refund_amount}
                        onKeyUp={RefundAmountValidation}
                      />
                      <span id="refundAmountValidation"></span>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex place-content-center">
                  <button
                    className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                    onClick={handleSubmit}
                     disabled={disableSubmit}
                  >
                    Update
                  </button>
                  {/* <button
                    class="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                    onClick={handleClose}
                  >
                    Back
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
