import Card from "components/card";
import React, { useRef, useMemo, useState, useEffect } from "react";
import { IoFilter } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UpdateCustomer from "views/admin/marketplace/components/UpdateCustomer";
import { useAdmin } from "hooks/useAdmin";
import { Link } from "react-router-dom";

const ColumnsTable = (props) => {
  const componentRef = useRef();

  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { initialState } = tableInstance;
  initialState.pageSize = 5;
  const [showloading, setShowLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const { getLeadDetails, leadData,getCombinedQuotationData,quotationCombinedData, loading } = useAdmin();


  useEffect(() => {
    setTimeout(() => {
      getCombinedQuotationData();
      setShowLoading(false);
    }, 2000);
  }, [loading]);



  useEffect(() => {
    setTimeout(() => {
      getLeadDetails();
      setShowLoading(false);
    }, 2000);
  }, [loading]);


  useEffect(() => {
    setFilteredData(leadData);
  }, [leadData]);

  // console.log("---->",quotationCombinedData);

  // filteredData
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    // const filteredResults = leadData.filter((item) =>
    //   item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
    // );
    filterData(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const filteredResults = leadData.filter((item) =>
      item?.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item?.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const filterData = (range) => {
    const currentDate = new Date();
    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1);

    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(currentDate.getDate() - 7);

    const oneMonthAgo = new Date(currentDate);
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    switch (range) {
      case "day":
        const filteredOneDayAgo = leadData?.filter((item) => {
          const itemDate = new Date(item.dateOfLead);
          return itemDate >= oneDayAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneDayAgo);
        break;

      case "week":
        const filteredOneWeekAgo = leadData.filter((item) => {
          const itemDate = new Date(item.dateOfLead);
          return itemDate >= oneWeekAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneWeekAgo);
        break;
      case "month":
        const filteredOneMonthAgo = leadData.filter((item) => {
          const itemDate = new Date(item.dateOfLead);
          return itemDate >= oneMonthAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneMonthAgo);
        break;
      case "year":
        const filteredOneYearAgo = leadData?.filter((item) => {
          const itemDate = new Date(item.dateOfLead);
          return itemDate >= oneYearAgo && itemDate <= currentDate;
        });
        setFilteredData(filteredOneYearAgo);
        break;

      default:
        setFilteredData(leadData);
        return;
    }
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isNewLeadOpen, setIsNewLeadOpen] = useState(false);
  const [NewUpdateCustomer, setNewUpdateCustomer] = useState(false);

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const openNewForm = () => {
    setIsNewLeadOpen(true);
  };
  const openUpdateForm = () => {
    setNewUpdateCustomer(true);
  };

  const closeUpdateForm = () => {
    setNewUpdateCustomer(false);
  };
  const closeNewForm = () => {
    setIsNewLeadOpen(false);
  };

  // pagination

  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const filteredLeadData = useMemo(() => {
    const sortedData = filteredData
      ?.slice()
      .sort((a, b) => new Date(b.dateOfLead) - new Date(a.dateOfLead));
    return sortedData?.filter(
      (item) =>
        item?.client_type === "Customer" &&
        item?.name.toLowerCase().includes(searchTerm.toLowerCase()) || item?.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [filteredData, searchTerm]);
  const record = filteredLeadData?.length
    ? filteredLeadData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredLeadData?.length
    ? Math.ceil(filteredLeadData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(nPages);
  };

  return (
    <>
      {showloading ? (
        <Skeleton count={5} height={40} />
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between"></header>
          <div class="mt-4 flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
              <div class="inline-block min-w-full p-1.5 align-middle">
                <div class="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                  <div className="px-4 py-3">
                    <div className="flex items-center justify-between">
                      <div className="relative max-w-xs">
                        <label className="sr-only">Search</label>
                        <input
                          type="text"
                          name="hs-table-with-pagination-search"
                          id="hs-table-with-pagination-search"
                          className="dark:bg-slate-900 border-black block w-full rounded-lg border px-4 py-2 ps-10 text-sm"
                          placeholder="Search By Name/Email"
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                          <svg
                            className="size-4 text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                          </svg>
                        </div>
                      </div>
                      <div className="relative mr-20">
                        <div className="-right-1/2 mx-auto translate-x-1/2 transform rounded-lg bg-white py-1 ">
                          <select
                            id="exampleSelect"
                            value={selectedValue}
                            onChange={handleChange}
                            className="mt-1 rounded-md border border-gray-300 p-2 focus:border-blue-300 focus:outline-none focus:ring"
                          >
                            <option value="">Filter</option>
                            <option value="day">One day ago</option>
                            <option value="week">One week ago</option>
                            <option value="month">One month ago</option>
                            <option value="year">One year ago</option>
                          </select>
                        </div>
                        {/* </div>
                    )} */}
                      </div>
                    </div>
                  </div>

                  {NewUpdateCustomer ? (
                    <UpdateCustomer onClose={closeUpdateForm} />
                  ) : (
                    ""
                  )}
                  <div class="overflow-hidden">
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead class="bg-gray-400 dark:bg-gray-700">
                        <tr className="whitespace-nowrap">
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Sr No
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Mail ID
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            City
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Package Type
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                        {record && record?.length >= 0 ? (
                          record?.map((data, index) => {
                            const temp_data=quotationCombinedData?.find((obj)=>obj?.client_id===data?._id);
                            return (
                              <>
                                <tr key={index}>
                                  <td className="whitespace-nowrap px-3 py-2 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {index + 1 + (currentPage - 1) * recordPerPage}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data?.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data?.email}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-center text-sm text-gray-800 dark:text-gray-200">
                                    {data.city}
                                  </td>
                                  <td className="dark:text-gray-20 whitespace-nowrap px-3 py-2 text-center text-sm text-gray-800">
                                    {/* Render package_type */}
                                    {temp_data?.package_type}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center py-4">
                              No data found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="flex items-center justify-center -space-x-px rounded-md shadow-sm">
            <ul className="flex items-center justify-center border-t px-4 py-3 sm:px-6">
              {currentPage !== 1 && currentPage !== 2 && (
                <>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={goToFirstPage}
                      aria-label="First"
                    >
                      First
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={prePage}
                      aria-label="Previous"
                    >
                      Previous
                    </Link>
                  </li>
                </>
              )}
              {numbers.map(
                (n, i) =>
                  (n === currentPage ||
                    n === currentPage - 1 ||
                    n === currentPage + 1) && (
                    <li
                      className={`page-item ${currentPage === n ? "active" : ""}`}
                      key={i}
                    >
                      <Link
                        href="#"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${currentPage === n ? "bg-gray-200" : ""
                          }`}
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </Link>
                    </li>
                  )
              )}
              {currentPage !== nPages && (
                <>
                  {/* <li className="page-item">
                      <Link
                        href="#"
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        Next
                      </Link>
                    </li> */}
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={goToLastPage}
                      aria-label="Last"
                    >
                      Last
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </Card >
      )}
    </>
  );
};

export default ColumnsTable;
