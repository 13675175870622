import React, { useState } from "react";

export const Validation = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [error1, seterror1] = useState("");
  const [pnrError, setPnrErrorMessage] = useState("");
  const [airlineError, setairlineError] = useState("");
  const [bookingError, setBookingError] = useState("");
  const [netcostError, setnetcostError] = useState("");
  const [markupError, setMarkupError] = useState("");
  const [actuallcollectionError, setActualCollectionError] = useState("");
  const [cancellationError, setCancellationError] = useState("");
  const [refundError, setRefundError] = useState("");
  const [HotelNumberError,setHotelNumberVallidation]=useState("")
 const [netcosterror,setnetcostVallidation]=useState("");
  const [vendorError, setVendorError] = useState("");

  const AlphaValidation = (e) => {
    const name = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const result = name.match(condition);

    if (!result) {
      setErrorMessage("Enter only alphabets");
    } else {
      setErrorMessage("");
    }
  };
  const ToValidation = (e) => {
    const name = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const result = name.match(condition);

    if (!result) {
      seterror1("Enter only alphabets");
    } else {
      seterror1("");
    }
  };
  // const PnrValidation = (e) => {
  //   const pnr = e.target.value;
  //   // Adjust the condition as per your PNR validation requirements
  //   const condition = /^[A-Z0-9]{10}$/;
  //   const result = pnr.match(condition);

  //   if (!result) {
  //     setPnrErrorMessage("Enter a valid PNR (10 number)");
  //   } else {
  //     setPnrErrorMessage("");
  //   }
  // };

  const PnrValidation = (e) => {
    const pnr = e.target.value;
    const pnrPattern = /^[A-Za-z0-9]{10}$/;
    if (!pnrPattern.test(pnr)) {
      setPnrErrorMessage("PNR must be 10 alphanumeric characters");
    } else {
      setPnrErrorMessage("");
    }
  };

  const AirlineValidation = (e) => {
    const name = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const result = name.match(condition);

    if (!result) {
      setairlineError("Enter only alphabets");
    } else {
      setairlineError("");
    }
  };

  const BookingSourceValidation = (e) => {
    const name = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const result = name.match(condition);

    if (!result) {
      setBookingError("Enter only alphabets");
    } else {
      setBookingError("");
    }
  };
  const NetCostValidation = (e) => {
    const pnr = e.target.value;
    // Adjust the condition as per your PNR validation requirements
    const condition = /^[0-9]+$/;
    const result = pnr.match(condition);

    if (!result) {
      setnetcostError("Enter Only Digits");
    } else {
      setnetcostError("");
    }
  };

  const MarkupValidation = (e) => {
    const pnr = e.target.value;
    // Adjust the condition as per your PNR validation requirements
    const condition = /^[0-9]+$/;
    const result = pnr.match(condition);

    if (!result) {
      setMarkupError("Enter Only Digits");
    } else {
      setMarkupError("");
    }
  };

  const HotelNumberValidation = (e) => {
    const pnr = e.target.value;
    // Adjust the condition as per your PNR validation requirements
    const condition = /^[0-9]+$/;
    const result = pnr.match(condition);

    if (!result) {
      setHotelNumberVallidation("Enter Only Number");
    } else {
      setHotelNumberVallidation("");
    }
  };

  const NetCosttValidation = (e) => {
    const pnr = e.target.value;
    // Adjust the condition as per your PNR validation requirements
    const condition = /^[0-9]+$/;
    const result = pnr.match(condition);

    if (!result) {
      setnetcostVallidation("Enter Only Number");
    } else {
      setnetcostVallidation("");
    }
  };


  const ActualCollectionValidation = (e) => {
    const pnr = e.target.value;
    // Adjust the condition as per your PNR validation requirements
    const condition = /^[0-9]+$/;
    const result = pnr.match(condition);

    if (!result) {
      setActualCollectionError("Enter Only Digits");
    } else {
      setActualCollectionError("");
    }
  };

  const CancellationValidation = (e) => {
    const num = e.target.value;
    // Adjust the condition as per your PNR validation requirements
    const condition = /^[0-9]+$/;
    const result = num.match(condition);

    if (!result) {
      setCancellationError("Enter Only Digits");
    } else {
      setCancellationError("");
    }
  };

  const RefundValidation = (e) => {
    const num = e.target.value;
    // Adjust the condition as per your PNR validation requirements
    const condition = /^[0-9]+$/;
    const result = num.match(condition);

    if (!result) {
      setRefundError("Enter Only Digits");
    } else {
      setRefundError("");
    }
  };

  const VendorNameValidation = (e) => {
    const name = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const result = name.match(condition);

    if (!result) {
      setVendorError("Enter only alphabets");
    } else {
      setVendorError("");
    }
  };

  return {
    AlphaValidation,
    errorMessage,
    ToValidation,
    error1,
    PnrValidation,
    pnrError,
    AirlineValidation,
    airlineError,
    BookingSourceValidation,
    bookingError,
    NetCostValidation,
    netcostError,
    MarkupValidation,
    markupError,
    ActualCollectionValidation,
    actuallcollectionError,
    CancellationValidation,
    cancellationError,
    RefundValidation,
    refundError,
    VendorNameValidation,
    vendorError,
    HotelNumberValidation,
    HotelNumberError,
    NetCosttValidation,
    netcosterror
  };
};
