import { useRef, useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { toast } from "react-toastify";

export default function UpdateInternational({ data, onClose }) {
  const { updateInternationalInvoice } = useAdmin();
  const [id, setId] = useState(data._id);
  const [disableSubmit, setdisableSubmit] = useState();

  const editor = useRef(null);
  const [content, setContent] = useState(data.itinerary);

  const [formData, setFormData] = useState({
    cust_name: "",
    start_date: "",
    end_date: "",
    proposal_title: "",
    budget: "",
    follow_up_date: "",
    no_of_adult: "",
    no_of_children: "",
    package_type: "",
    destination: "",
    traveldate: "",
    duration: "",
    currency: "",
    roe: "",
    gstamount: "",
    totalprice: "",
    amountininr: "",
    amount: "",
    netvisacost: "",
    vendorname: "",
    netinsurancecost: "",
    totalpackagecost: "",
    markup: "",
    grosscost: "",
    gstcost: "",
    tlscost: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
    itinerary: "",
    date_time: "",
    gst: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        cust_name: data.cust_name,
        start_date: data.start_date,
        end_date: data.end_date,
        proposal_title: data.proposal_title,
        budget: data.budget,
        gst: data.gst,
        follow_up_date: data.follow_up_date,
        no_of_adult: data.no_of_adult,
        no_of_children: data.no_of_children,
        package_type: data.package_type,
        destination: data.destination,
        traveldate: data.traveldate,
        duration: data.duration,
        currency: data.currency,
        roe: data.roe,
        amountininr: data.amountininr,
        amount: data.amount,
        netvisacost: data.netvisacost,
        vendorname: data.vendorname,
        netinsurancecost: data.netinsurancecost,
        totalpackagecost: data.totalpackagecost,
        markup: data.markup,
        grosscost: data.grosscost,
        totalprice: data.totalprice,
        gstcost: data.gstcost,
        tlscost: data.tlscost,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        deficit: data.deficit,
        // itinerary: data.itinerary,
        date_time: data.date_time,
      });
    }
  }, [data]);
  // console.log(data.gst, formData.gst);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Convert the values to floats, and if they are not valid, default to 0
    const roe = parseFloat(formData.roe) || 0;
    const amount = parseFloat(formData.amount) || 0;
    const calculation = roe * amount;
    const amountininr = calculation || 0;
    const insuranceCost = parseFloat(formData.netinsurancecost) || 0;
    const visaCost = parseFloat(formData.netvisacost) || 0;
    const grossCost = parseFloat(formData.markup) || 0;
    const actualcoll = parseFloat(formData.actual_collection) || 0;
    const total = amountininr + insuranceCost + visaCost;
    const gst = parseFloat(formData.gst) || 0;
    if (gst === 18) {
      var gst18 = (grossCost * 18) / 100;
      var withgstmarkup = grossCost + gst18 || 0;
      var totalamount = total + withgstmarkup;
      var totalamount = total + grossCost + gst18;
      var totalprice = total + grossCost;
    }
    if (gst === 5) {
      var withgstmarkup = grossCost || 0;
      var gst5 = total + withgstmarkup;
      var totalamount = gst5 + (gst5 * 5) / 100;
      var tax = (gst5 * 5) / 100;
      var totalprice = total + grossCost;
    }
    const GrossCost = totalamount || 0;
    const grossWithGST = GrossCost || 0;
    const tlscost = 0.05 * GrossCost || 0;
    const idealcollection = tlscost + grossWithGST || 0;
    const newdeficit = idealcollection - actualcoll;
    const gst_amount = tax || gst18 || 0;

    setFormData((prevFormData) => ({
      ...prevFormData,
      totalpackagecost: total,
      grosscost: GrossCost,
      gstcost: grossWithGST,
      tlscost: tlscost,
      ideal_collection: idealcollection,
      deficit: newdeficit,
      amountininr: calculation,
      gstamount: gst_amount,
      totalprice: totalprice,
    }));
  }, [
    formData.amountininr,
    formData.netinsurancecost,
    formData.netvisacost,
    formData.markup,
    formData.actual_collection,
    formData.gst,
    formData.roe,
    formData.amount,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all mandatory fields are filled
    const requiredFields = [
      "cust_name",
      "destination",
      "traveldate",
      "duration",
      "budget",
      "follow_up_date",
      "no_of_adult",
      "no_of_children",
      "package_type",
      "currency",
      "roe",
      "amountininr",
      "amount",
      "netvisacost",
      "vendorname",
      "netinsurancecost",
      "markup",
      "actual_collection",
    ];
    // const isValid = requiredFields.every(
    //   (field) => formData[field].trim() !== ""
    // );

    // if (!isValid) {
    //   toast.error("Please fill all mandatory fields");
    //   return;
    // }

    try {
      await updateInternationalInvoice({ id, formData });
      window.location.reload();
    } catch (error) {
      console.error("Error updating quotation:", error);
      toast.error("Failed to update quotation");
    }
  };

  //Validation Code
  const {
    LastnameValidation,
    DurationValidation,
    ROEValidation,
    NetVisaCostValidation,
    AmountININRValidation,
    VendornameValidation,
    NetInsuranceCost,
    MarkUpValidation,
    ActualCollectionValidation,
    AmountValidation,
  } = Validation();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <div className="relative">
        <div class="fixed inset-0 z-50 m-1  items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 pb-10 pl-5 pr-5 pt-10 backdrop-blur-0 lg:pl-10 lg:pr-10">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <div>
                <span
                  className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    zIndex: "1100px",
                  }}
                >
                  {" "}
                  <FaTimes size={24} />{" "}
                </span>
                <h2 className="mb-4 text-center text-lg font-semibold underline">
                  Update International Package Invoice
                </h2>
              </div>

              <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="cust_name"
                        id="cust_name"
                        autocomplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Destination <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="destination"
                        id="destinationname"
                        autocomplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.destination}
                        onKeyUp={LastnameValidation}
                        required
                      />
                      <span id="last"></span>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      for="traveldate"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Travel Date <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        name="traveldate"
                        id="traveldate"
                        autoComplete="travel_date"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        onChange={handleInputChange}
                        value={formData.traveldate.slice(0, 10)}
                        required
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      for="duration"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Duration (In Days){" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        id="duration"
                        name="duration"
                        type="text"
                        autoComplete="duration"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.duration}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="text-black text-start text-base font-semibold leading-7">
                Net Land Package cost & Vendor name
              </h2>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    for="currency"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Currency
                  </label>
                  <div className="mt-2">
                    <select
                      id="currency"
                      name="currency"
                      autoComplete="currency"
                      value={formData.currency}
                      onChange={handleInputChange}
                      required
                      className="text-black block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="">Select</option>
                      <option value="INR">INR</option>
                      <option value="SGD">SGD</option>
                      <option value="USD">USD</option>
                      <option value="Euro">Euro</option>
                      <option value="AED">AED</option>
                      <option value="Thai baht">Thai baht</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    for="amount"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Amount <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      autoComplete="amount"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.amount}
                      onKeyUp={AmountValidation}
                      required
                    />
                    <span id="AmountValidation"></span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    for="roe"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    ROE <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="roe"
                      id="roe"
                      autoComplete="roe"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.roe}
                      onKeyUp={ROEValidation}
                      required
                    />
                    <span id="ROE"></span>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    for="amount-in-inr"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Amount in INR <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="amountininr"
                      id="amountininr"
                      autoComplete="amount-in-inr"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                     placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.amountininr}
                      onKeyUp={AmountININRValidation}
                      required
                    />
                    <span id="amountININRValidation"></span>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="gst"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    GST <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <select
                      id="gst"
                      name="gst"
                      value={formData.gst}
                      onChange={handleInputChange}
                      required
                      className="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option>Select Package</option>
                      <option value="5">With 5% GST</option>
                      <option value="18">With 18% GST</option>
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    for="net-visa-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Net Visa Cost <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="netvisacost"
                      id="netvisacost"
                      autocomplete="netvisacost"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.netvisacost}
                      onKeyUp={NetVisaCostValidation}
                      required
                    />
                    <span id="netVisaCostValidation"></span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="vendor-name"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Vendor Name <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="vendorname"
                      id="vendorname"
                      autocomplete="vendorname"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.vendorname}
                      // onKeyUp={VendornameValidation}
                      required
                    />
                    {/* <span id="vendornameValidation"></span> */}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="net-income-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Net Insurance Cost{" "}
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="netinsurancecost"
                      id="netinsurancecost"
                      autocomplete="net-income-cost"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.netinsurancecost}
                      onKeyUp={NetInsuranceCost}
                      required
                    />
                    <span id="netInsuranceCost"></span>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    for="net-income-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Total Package Cost{" "}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="totalpackagecost"
                      id="totalpackagecost"
                      autocomplete="net-income-cost"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.totalpackagecost}
                      required
                      readOnly
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="mark-up"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Mark Up <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="markup"
                      id="markup"
                      autocomplete="mark-up"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.markup}
                      onKeyUp={MarkUpValidation}
                      required
                    />
                    <span id="markUpValidation"></span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="totalprice"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Total Price <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="totalprice"
                      id="totalprice"
                      autocomplete="totalprice"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.totalprice}
                      // onKeyUp={MarkupValidation}
                      readOnly
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="net-income-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Gross Cost
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="grosscost"
                      id="grosscost"
                      autocomplete="net-income-cost"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.grosscost}
                      readOnly
                      required
                    />
                  </div>
                </div>

                {/* <div className="sm:col-span-3">
                  <label
                    for="gst-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Gst 5% on Gross Cost{" "}
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="gstcost"
                      id="gstcost"
                      autocomplete="gstcost"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.gstcost}
                      readOnly
                      required
                    />
                  </div>
                </div> */}

                <div className="sm:col-span-3">
                  <label
                    for="tls-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    TCS 5% on Gross Cost
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="tlscost"
                      id="tlscost"
                      autocomplete="tlscost"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.tlscost}
                      readOnly
                      required
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    for="tls-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Ideal Collection
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="ideal_collection"
                      id="ideal_collection"
                      autocomplete="idealcollection"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.ideal_collection}
                      readOnly
                      required
                    />
                  </div>
                </div>
                {/* <div className="sm:col-span-3">
                  <label
                    for="tls-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Actual Collection
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="actual_collection"
                      id="actual_collection"
                      autocomplete="actualcollection"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.actual_collection}
                      onKeyUp={ActualCollectionValidation}
                      required
                    />
                    <span id="actualCollectionValidation"></span>
                  </div>
                </div> */}
                <div className="sm:col-span-3">
                  <label
                    for="tls-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Deficit
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="deficit"
                      id="deficit"
                      autocomplete="deficit"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={formData.deficit}
                      onChange={handleInputChange}
                      readOnly
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex place-content-center">
                <button
                  className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
