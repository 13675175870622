import Card from "components/card";
import { useState, useEffect } from "react";
import "./styles.css";
import { useAdmin } from "../../../hooks/useAdmin";
import { Validation } from "views/admin/marketplace/components/Validation";
import { toast } from "react-toastify";

const AccountSetting = () => {
  const [activetab, setActivetab] = useState("tab2");
  const { saveAccountData, reqAccountData, accountData, updateAccountDetails } =
    useAdmin();
  const [editMode, setEditMode] = useState(false); // State variable for edit mode

  const handleTab = (tab) => {
    setActivetab(tab);
  };

  useEffect(() => {
    reqAccountData();
  }, []);

  const [formData, setFormData] = useState({
    officialName: "",
    GstNo: "",
    CinNo: "",
    panNo: "",
    accountholderName: "",
    accountNumber: "",
    ifsccode: "",
    banckname: "",
    branchname: "",
    swiftcode: "",
    hsncode: "",
  });

  const [errorMsg, setErrorMsg] = useState({
    accountholderName: "",
    branchname: "",
    banckname: "",
  });

  const handleNameValidation = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const condition = /^[a-zA-Z\s]+$/;
    const errorMsg = !condition.test(value) ? "Enter only alphabets" : "";
    setErrorMsg((prevState) => ({
      ...prevState,
      [name]: errorMsg,
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Check if the input field is one of the specified fields
    if (["accountholderName", "branchname", "banckname"].includes(name)) {
      // Check if the entered value contains only alphabetic characters
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        // You can also set an error message state for this field
        setErrorMsg((prevState) => ({
          ...prevState,
          [name]: "Only alphabetic characters are allowed.",
        }));
      }
    } else {
      // For other fields, update the form data normally
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handlesettingdata = (e) => {
    e.preventDefault();
    updateAccountDetails(formData);
    setEditMode(false); // Disable edit mode after updating
    // toast.success("Account details updated successfully!");
  };

  useEffect(() => {
    setFormData({
      id: accountData?.[0]._id,
      officialName: accountData?.[0].officialName,
      GstNo: accountData?.[0].GstNo,
      CinNo: accountData?.[0].CinNo,
      panNo: accountData?.[0].panNo,
      accountholderName: accountData?.[0].accountholderName,
      accountNumber: accountData?.[0].accountNumber,
      ifsccode: accountData?.[0].ifsccode,
      banckname: accountData?.[0].banckname,
      branchname: accountData?.[0].branchname,
      swiftcode: accountData?.[0].swiftcode,
      hsncode: accountData?.[0].hsncode,
    });
  }, [accountData]);

  // Validation Code
  const {
    GSTnumberValidation,
    AccountNumberValidation,
    IFSCCodeValidation,

    PanCardValidation,
    HSNCodeValidation,
  } = Validation();

  return (
    <Card
      extra={"w-full pb-10 p-4 h-full"}
      style={{
        backgroundColor: "#f3f4f6",
        borderRadius: "1.5rem",
        boxShadow: "0px 4px 6px rgba(59, 130, 246, 0.3)",
      }}
    >
      <div id="styled-settings" role="tabpanel" aria-labelledby="settings-tab">
        <form onSubmit={handlesettingdata}>
          <div className=" pb-6">
            <div className="border-gray-900 pb-3">
              <h2 className="text-start text-base font-semibold leading-7 text-red-500">
                Registration Details
              </h2>
            </div>
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="officialName"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Company Name <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="officialName"
                    id="officialName"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.officialName}
                    // onKeyUp={FullnameValidation}
                    disabled={!editMode} // Disable conditionally based on editMode
                  />
                  <span id="fullnamevalidation"></span>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="GstNo"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  GST Number <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="GstNo"
                    id="GstNo"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.GstNo}
                    onKeyUp={GSTnumberValidation}
                    disabled={!editMode}
                  />
                  <span id="gstnumber"></span>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="CinNo"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  CIN Number <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="CinNo"
                    id="CinNo"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.CinNo}
                    disabled={!editMode}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="panNo"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  PAN Number <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="panNo"
                    id="panNo"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    onKeyUp={PanCardValidation}
                    value={formData.panNo}
                    disabled={!editMode}
                  />
                  <span id="Pancard"></span>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="hsncode"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  HSN Code <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="hsncode"
                    id="hsncode"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    onKeyUp={HSNCodeValidation}
                    value={formData.hsncode}
                    disabled={!editMode}
                  />
                  <span id="hsn"></span>
                </div>
              </div>
            </div>
            <div className=" py-6 pb-3">
              <h2 className="text-start text-base font-semibold leading-7 text-red-500">
                Bank Details
              </h2>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="accountholderName"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Account Holder Name <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="accountholderName"
                    id="accountholderName"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.accountholderName}
                    onKeyUp={handleNameValidation}
                    disabled={!editMode}
                  />
                  <span style={{ color: "red" }}>
                    {errorMsg.accountholderName}
                  </span>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="accountNumber"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Account Number <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="accountNumber"
                    id="accountNumber"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.accountNumber}
                    onKeyUp={AccountNumberValidation}
                    disabled={!editMode}
                  />
                  <span id="AccountNumberValidation"></span>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="ifsccode"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  IFSC Code <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="ifsccode"
                    id="ifsccode"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.ifsccode}
                    onKeyUp={IFSCCodeValidation}
                    disabled={!editMode}
                  />
                  <span id="ifscCode"></span>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="banckname"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Bank Name <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="banckname"
                    id="banckname"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.banckname}
                    onKeyUp={handleNameValidation}
                    disabled={!editMode}
                  />
                  <span style={{ color: "red" }}>{errorMsg.banckname}</span>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="branchname"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Branch Name <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="branchname"
                    id="branchname"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.branchname}
                    onKeyUp={handleNameValidation}
                    disabled={!editMode}
                  />
                  <span style={{ color: "red" }}>{errorMsg.branchname}</span>
                </div>
              </div>

              {/* <div className="sm:col-span-2">
                <label
                  htmlFor="swiftcode"
                  className="text-black block text-sm font-semibold leading-6"
                >
                  Swift Code <sup className="mb-2 text-red-500">*</sup>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="swiftcode"
                    id="swiftcode"
                    className="text-black block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.swiftcode}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 sm:flex-row sm:space-x-4 sm:space-y-0">
            {!editMode && (
              <button
                type="button"
                onClick={() => setEditMode(true)}
                className="rounded bg-yellow-500 px-4 py-2 font-bold text-white hover:bg-yellow-700"
              >
                Edit
              </button>
            )}
            {editMode && (
              <button
                type="submit"
                className="rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700"
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
      {/*   )}*/}
    </Card>
  );
};
export default AccountSetting;
