import { useAdmin } from "../../../../hooks/useAdmin";
import React, { useState } from "react";

const AddFile = ({ data, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { reqAddNewFile ,reqAddNewInvFile} = useAdmin();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id", data.id);
    formData.append("package_type", data.quotationType);
    reqAddNewFile(formData);
    reqAddNewInvFile(formData)
    setInterval(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
    {data &&

 
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      <div className="relative max-w-md rounded-lg bg-gray-200 p-8 shadow-lg">
        <h2 className="mb-4 text-lg font-semibold">Upload Files</h2>
        <form>
          <div className="mb-4">
            <label
              htmlFor="file-upload"
              className="block text-sm font-medium text-gray-700"
            >
            Add file
            </label>
            <input
              id="file-upload"
              name="file-upload"
              onChange={handleFileChange}
              type="file"
              className="mt-1 block cursor-pointer rounded-md bg-blue-500 px-1 py-2 text-white shadow-md hover:bg-blue-600"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="border-transparent mr-2 inline-flex justify-center rounded-md border bg-gray-400 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="border-transparent inline-flex justify-center rounded-md border bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
    }
    </>
  );
};

export default AddFile;
