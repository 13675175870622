import { useAdmin } from "hooks/useAdmin";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export default function RemarkPopup({ onClose, data }) {
  const handleClose = () => {
    onClose();
  };

  const { reqRemarkDetails, loading, remarkData } = useAdmin();
  const location = useLocation();
  const [filterData, setFilteredData] = useState([]);

  useEffect(() => {
    reqRemarkDetails();
  }, [loading]);

  useEffect(() => {
    filterDataById();
  }, [remarkData]);

  const filterDataById = () => {
    if (data._id && remarkData && remarkData.length > 0) {
      const filteredData = remarkData.filter(
        (d) => d.invoiceid === data._id
      );

      setFilteredData(filteredData);
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 backdrop-blur">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <span
            className="absolute right-0 top-0 mr-2 mt-2 cursor-pointer text-red-600"
            onClick={handleClose}
          >
            <FaTimes size={24} />
          </span>
          <h2 className="mb-4 text-center text-lg font-semibold underline">
            View Remark
          </h2>
          {filterData && filterData.length > 0 ? (
            <div>
              {filterData.map((dataItem, index) => (
                <p key={index} className="mb-4">
                  <strong>Remark {index + 1}: </strong>
                  {dataItem.remark} -{" "}
                  {new Date(dataItem.dateOfRemark).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </p>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-600">No Remark Added.</p>
          )}
        </div>
      </div>
    </>
  );
}
