import Card from "components/card";
import React, { useEffect, useState } from "react";
import { useAdmin } from "../../../hooks/useAdmin";
import { IoIosArrowBack } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import { Validation } from "../../../views/auth/quotation/Validation";
import { toast } from "react-toastify";

const Insurance = ({ parentData, clientId, name, onClose }) => {
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [disableSubmit, setdisableSubmit] = useState(false);

  const { AddInsuranceQuotation } = useAdmin();

  const [formData, setFormData] = useState({
    type_of_insurance: "",
    numofperson: "",
    netcost: "",
    markup: "",
    // gstcost: "",
    idealcollection: "",
    actualcollection: "",
    deficit: "",
    vendername: "",
  });

  // Reset form data to initial state

  const handleDiscard = () => {
    setFormData({
      type_of_insurance: "",
      numofperson: "",
      netcost: "",
      markup: "",
      gstcost: "",
      idealcollection: "",
      actualcollection: "",
      deficit: "",
      vendername: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };
  useEffect(() => {
    const amount = parseFloat(netcost) || 0;
    const markupCost = parseFloat(markup) || 0;
    const actualcost = parseFloat(actualcollection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      idealcollection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [netcost, markup, actualcollection]);

  function validateForm() {
    if (
      formData.type_of_insurance !== "" &&
      formData.numofperson !== "" &&
      formData.netcost !== "" &&
      formData.markup !== "" &&
      formData.gstcost !== "" &&
      formData.idealcollection !== "" &&
      formData.vendername !== "" &&
      formData.deficit !== ""
    ) {
      return true;
    }
    return false;
  }

  const handleSubmit = async (e) => {
    setdisableSubmit(true);

    e.preventDefault();
    if (validateForm() === true) {
      AddInsuranceQuotation({ formData, parentData, clientId, name });
      setInterval(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Please fill mandatory fields");
      setdisableSubmit(false); // Enable the submit button
    }
  };

  const [showForm, setShowForm] = useState(true);
  const handleClose = () => {
    onClose();
    setShowForm(false);
  };

  //Validation Code
  const {
    NetCostValidation,
    netcostError,
    MarkupValidation,
    markupError,
    ActualCollectionValidation,
    actuallcollectionError,
    CancellationValidation,
    cancellationError,
    RefundValidation,
    refundError,
    vendorError,
    VendorNameValidation,
    HotelNumberValidation,
    HotelNumberError,
    NetCosttValidation,
    netcosterror,
  } = Validation();

  return (
    <>
      {showForm ? (
        <div className="relative">
          {/* <div className="fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0"> */}
          <div>
            <div className="z-50 rounded-bl-lg rounded-br-lg bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                {" "}
                <FaTimes size={24} />{" "}
              </span>
              <h2 className="mb-4 text-center text-lg font-semibold underline">
                Insurance Package
              </h2>

              {/* </div> */}
              {/* <div className="border-b border-gray-900/10 pb-12"></div> */}

              <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label
                    for="typeofinsurance"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Type of Insurance
                  </label>
                  <div class="mt-2">
                    <select
                      id="typeofinsurance"
                      name="type_of_insurance"
                      value={formData.type_of_insurance}
                      onChange={handleInputChange}
                      autoComplete="typeofvisa"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option>Select</option>
                      <option>Single Trip Insurance</option>
                      <option>Multi Trip Insurance</option>
                      <option>Educational Travel Insurance</option>
                      <option>Group Policy Insurance</option>
                    </select>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="vendername"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Vendor Name <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="vendername"
                      id="vendername"
                      autoComplete="vendername"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      onKeyUp={(e) => {
                        // Only validate if the input field is focused
                        if (document.activeElement === e.target) {
                          VendorNameValidation();
                        }
                      }}
                      value={formData.vendername}
                      required
                    />
                    {/* {vendorError && (
                        <span style={{ color: "red" }}>{vendorError}</span>
                      )} */}
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="numofperson"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Number of Person <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="numofperson"
                      id="numofperson"
                      autocomplete="numofperson"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.numofperson}
                      onKeyUp={HotelNumberValidation}
                    />
                    {HotelNumberError && (
                      <span style={{ color: "red" }}>{HotelNumberError}</span>
                    )}
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="netcost"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Net Cost <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="netcost"
                      id="netcost"
                      autocomplete="netcost"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.netcost}
                      onKeyUp={NetCosttValidation}
                      required
                    />
                    {netcosterror && (
                      <span style={{ color: "red" }}>{netcosterror}</span>
                    )}
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="markup"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Mark Up <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="markup"
                      id="markup"
                      autocomplete="markup"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.markup}
                      onKeyUp={MarkupValidation}
                      required
                    />
                    {markupError && (
                      <span style={{ color: "red" }}>{markupError}</span>
                    )}
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="gstcost"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    GST 18% on Markup
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="gstcost"
                      id="gstcost"
                      autocomplete="gstcost"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.gstcost}
                      readOnly
                    />
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="idealcollection"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Ideal Collection <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="idealcollection"
                      id="idealcollection"
                      autocomplete="idealcollection"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.idealcollection}
                      readOnly
                    />
                  </div>
                </div>
                {/* <div class="sm:col-span-3">
                    <label
                      for="actualcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Actual Collection <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        name="actualcollection"
                        id="actualcollection"
                        autocomplete="actualcollection"
                        class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.actualcollection}
                        onKeyUp={ActualCollectionValidation}
                        readOnly
                        required
                      />
                      {actuallcollectionError && (
                        <span style={{ color: "red" }}>
                          {actuallcollectionError}
                        </span>
                      )}
                    </div>
                  </div> */}
                <div class="sm:col-span-3">
                  <label
                    for="deficit"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Deficit <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="deficit"
                      id="deficit"
                      autocomplete="deficit"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.deficit}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex flex-wrap items-center justify-center">
                <button
                  className="rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  Save
                </button>
                <div className="mx-4 sm:mx-4"></div>
                <button
                  onClick={handleDiscard}
                  className="rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      ) : null}
    </>
  );
};

export default Insurance;
