import React, { useEffect, useState } from "react";
import { useAdmin } from "../../../hooks/useAdmin";
import { FaTimes } from "react-icons/fa";
import { Validation } from "../../../views/auth/quotation/Validation";
import { toast } from "react-toastify";

const UpdateInsurance = ({ onClose, data }) => {
  const [id, setId] = useState(data._id);
  const [disablesubmit, setdisableSubmit] = useState('');
  const { updateInsuranceInvoice } = useAdmin();

  const [formData, setFormData] = useState({
    cust_name: "",
    insurance_type: "",
    no_of_person: "",
    net_cost: "",
    mark_up: "",
    gst_on_markup: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        cust_name: data.cust_name,
        insurance_type: data.insurance_type,
        no_of_person: data.no_of_person,
        net_cost: data.net_cost,
        mark_up: data.mark_up,
        gst_on_markup: data.gst_on_markup,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        deficit: data.deficit,
        date_time: data.date_time,
      });
    }
  }, [data]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      idealcollection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   updateInsuranceInvoice({ id, formData });
  //   setInterval(() => {
  //     window.location.reload();
  //   }, 1000);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setdisableSubmit(true);
  
    const requiredFields = [
      "insurance_type",
      "no_of_person",
      "net_cost",
      "mark_up",
    ];
  
    const isValid = requiredFields.every((field) => formData[field].trim() !== "");
  
    if (!isValid) {
      toast.error("Please fill all mandatory fields");
      setdisableSubmit(false);
      return;
    }
  
    try {
      await updateInsuranceInvoice({ id, formData });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
     
      toast.error("An error occurred during submission");
      setdisableSubmit(false);
    }
  };
  const handleClose = () => {
    onClose();
  };

  //Validation Code
  const {
    NetCostValidation,
    netcostError,
    MarkupValidation,
    markupError,
    ActualCollectionValidation,
    actuallcollectionError,
    HotelNumberValidation,
    HotelNumberError
  } = Validation();

  // console.log(formData);
  return (
    <>
      <div className="relative">
        <div className="fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                {" "}
                <FaTimes size={24} />{" "}
              </span>
              <h2 className="mb-4 text-center text-lg font-semibold underline ">
                Update Insurance Invoice
              </h2>

              {/* </div> */}
              <div className="border-b border-gray-900/10 pb-12"></div>

              <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    for="destination-name"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Customer Name <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="cust_name"
                      id="cust_name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.cust_name}
                      readOnly
                    />
                    <span id="last"></span>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="insurance_type"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Type of Insurance
                  </label>
                  <div class="mt-2">
                    <select
                      id="insurance_type"
                      name="insurance_type"
                      value={formData.insurance_type}
                      onChange={handleInputChange}
                      autoComplete="typeofvisa"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="">Select</option>
                      <option>Single Trip Insurance</option>
                      <option>Multi Trip Insurance</option>
                      <option>Educational Travel Insurance</option>
                      <option>Group Policy Insurance</option>
                    </select>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="numofperson"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Number of Person <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="no_of_person"
                      id="no_of_person"
                      autoComplete="numofperson"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.no_of_person}
                      onKeyUp={HotelNumberValidation}
                    />
                   {HotelNumberError&& (
                      <span style={{ color: "red" }}>{HotelNumberError}</span>
                    )}
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="netcost"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Net Cost <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="net_cost"
                      id="net_cost"
                      autoComplete="netcost"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.net_cost}
                      onKeyUp={NetCostValidation}
                      required
                    />
                    {netcostError && (
                      <span style={{ color: "red" }}>{netcostError}</span>
                    )}
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="markup"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Mark Up <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="mark_up"
                      id="mark_up"
                      autoComplete="markup"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={formData.mark_up}
                      onChange={handleInputChange}
                      onKeyUp={MarkupValidation}
                      required
                    />
                    {markupError && (
                      <span style={{ color: "red" }}>{markupError}</span>
                    )}
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="gstcost"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    GST 18% on Markup<sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="gst_on_markup"
                      id="gst_on_markup"
                      autoComplete="gst_on_markup"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.gstmarkup}
                      readOnly
                    />
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="idealcollection"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Ideal Collection <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="ideal_collection"
                      id="ideal_collection"
                      autoComplete="idealcollection"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.ideal_collection}
                      readOnly
                    />
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="actualcollection"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Actual Collection <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="actual_collection"
                      id="actual_collection"
                      autoComplete="actualcollection"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.actual_collection}
                      onKeyUp={ActualCollectionValidation}
                      required
                    />
                    {actuallcollectionError && (
                      <span style={{ color: "red" }}>
                        {actuallcollectionError}
                      </span>
                    )}
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="deficit"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Deficit <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="deficit"
                      id="deficit"
                      autoComplete="deficit"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.deficit}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex place-content-center">
                <button
                  className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  onClick={handleSubmit}
                  disabled={disablesubmit}
                >
                  Update
                </button>
                {/* <button
                  class="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                  onClick={handleClose}
                >
                  Back
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateInsurance;
