import { useAdmin } from "../../../../hooks/useAdmin";
import React, { useEffect, useState } from "react";
import { baseURL } from "repositories/Repository";

const DownloadFile = ({ data, onClose }) => {
  const { reqDownloadFile, downloadfile, reqDownloadinvoiceFile } = useAdmin();
  const [filedata, setFiledata] = useState(null);

  useEffect(() => {
    if (data) {
      if (data?.invoice) {
        reqDownloadinvoiceFile(data);
      }
      reqDownloadFile(data);

    }
    
  }, [data]);

  useEffect(() => {
    if (downloadfile && downloadfile.files.length > 0) {
      setFiledata(downloadfile.files[0]);
       // Assuming only one file is fetched
    }
  }, [downloadfile]);

  const handleDownload = async () => {
    try {
      // Check if filedata is valid
      if (!filedata || !filedata.path) {
        console.error("File data or path not available.");
        return;
      }

      // Construct the download URL using baseURL and filedata.path
      const downloadUrl = `${baseURL}/${filedata.path}`;

      // Determine file type based on file extension (for example)
      const isPdf = filedata.path.endsWith('.pdf');
      const isImage = filedata.path.match(/\.(jpeg|jpg|png|gif)$/i);

      // For PDF and images, handle download differently
      if (isPdf || isImage) {
        const response = await fetch(downloadUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/octet-stream' // Force download as octet-stream
          },
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filedata.name); // Set the file name for download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up: remove the link from the DOM after download
      } else {
        // For other file types, use standard download behavior
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filedata.name); // Set the file name for download
        link.style.display = 'none'; // Hide the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up: remove the link from the DOM after download
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error
    }
    setInterval(() => {
      window.location.reload();
    }, 1000);
  };


  return (
    <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
    {filedata ? (
      <div className="mx-auto max-w-lg rounded-lg bg-gray-200 p-8 shadow-lg">
        <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
          <div>
            <label
              htmlFor="filePath"
              className="block text-sm font-medium text-gray-700"
            >
              File Path
            </label>
            <div className="mt-1">
              <input
                id="filePath"
                name="filePath"
                type="text"
                required
                value={filedata?.path || ""}
                readOnly
                className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
  
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDownload}
              className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Download
            </button>
          </div>
        </form>
      </div>
    ) : (
      <div className="mx-auto max-w-lg rounded-lg bg-white p-8 shadow-lg">
        <p className="mb-4">File not added</p>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onClose}
            className="rounded-md border border-gray-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-dark hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </div>
  

  );
};

export default DownloadFile;
