import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { useAdmin } from "../../../hooks/useAdmin";
import { FaTimes } from "react-icons/fa";
import Itinaray from "../quotation/Itinaray";
import { baseURL } from "axios";
import { toast } from "react-toastify";
export default function UpdateDomesticQuotation({ data, onClose }) {
  const [id, setId] = useState(data._id);
  const [clientId, setClientId] = useState(data.client_id);
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [totaldiscount, setTotaldiscount] = useState("");
  const [applygst, setApplygst] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [fileData, setFileData] = useState([]);
  const [disablesubmit, setdisableSubmit] = useState("");
  const { updateDomesticQuotes } = useAdmin();

  const [documents, setDocuments] = useState([]);

  const editor = useRef(null);

  const [formData, setFormData] = useState({
    quotation_type: "",
    cust_name: "",
    start_date: "",
    end_date: "",
    proposal_title: "",
    budget: "",
    follow_up_date: "",
    no_of_adult: "",
    no_of_children: "",
    package_type: "",
    destination: "",
    markup: "",
    travel_date: "",
    duration: "",
    net_cost: "",
    markup: "",
    gross_cost: "",
    discount: "",
    gst: "",
    gstamount: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
    cancellation_charges: "",
    refund_amount: "",
    date_time: "",
  });

  const [editorContents, setEditorContents] = useState({
    itinerary: "",
    overview: "",
    flight: "",
    hotel: "",
    inclusions: "",
    exclusions: "",
    photogallery: "",
    priceguideline: "",
    termsconditions: "",
  });

  useEffect(() => {
    if (data) {
      // console.log(updatedata);
      setFormData({
        quotation_type: data.quotation_type,
        cust_name: data.cust_name,
        start_date: data.start_date,
        end_date: data.end_date,
        proposal_title: data.proposal_title,
        budget: data.budget,
        follow_up_date: data.follow_up_date,
        no_of_adult: data.no_of_adult,
        no_of_children: data.no_of_children,
        package_type: data.package_type,
        destination: data.destination,
        travel_date: data.travel_date,
        duration: data.duration,
        net_cost: data.net_cost,
        markup: data.markup,
        gross_cost: data.gross_cost,
        discount: data.discount,
        gst: data.gst,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        deficit: data.deficit,
        cancellation_charges: data.cancellation_charges,
        refund_amount: data.refund_amount,
        date_time: data.date_time,
      });
      setEditorContents({
        itinerary: data.editorContents.itinerary,
        overview: data.editorContents.overview,
        flight: data.editorContents.flight,
        hotel: data.editorContents.hotel,
        inclusions: data.editorContents.inclusions,
        exclusions: data.editorContents.exclusions,
        photogallery: data.editorContents.photogallery,
        priceguideline: data.editorContents.priceguideline,
        termsconditions: data.editorContents.termsconditions,
      });
    }
  }, [data]);

  const baseURL = "https://otripserver.fresherszone.in";

  function openDocumentInNewTab(documentPath) {
    const url = `${baseURL}/${documentPath}`;
  }
  const handleEditorChangeParent = (name, value, files) => {
    setEditorContents((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (files) {
      const filesArray = Array.from(files);
      setDocuments([...documents, ...filesArray]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const netCost = parseFloat(formData.net_cost) || 0;
    const markup = parseFloat(formData.markup) || 0;
    const discount = parseFloat(formData.discount) || 0;
    const actualCollection = parseFloat(formData.actual_collection) || 0;
    const gst = parseFloat(formData.gst) || 0;
    if (gst === 5) {
      var add = netCost + markup;
      var total = add + (add * 5) / 100;
      var gst5 = (total * 5) / 100;
    } else {
      var total = netCost + markup + (markup * 18) / 100;
      var gst18 = (markup * 18) / 100;
      var total = netCost + markup + gst18;
    }
    const grossCost = total;

    const totalWithDiscount = grossCost - discount;
    const idealCollection = totalWithDiscount;
    const deficit = (idealCollection - actualCollection).toFixed(2);
    const gst_amount = gst5 || gst18 || 0;

    setFormData((prevFormData) => ({
      ...prevFormData,
      gross_cost: grossCost.toFixed(2),
      ideal_collection: idealCollection.toFixed(2),
      gstamount: gst_amount,
      deficit,
    }));
  }, [
    formData.net_cost,
    formData.markup,
    formData.discount,
    formData.gst,
    formData.actual_collection,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setdisableSubmit(true);

    const requiredFields = [
      "destination",
      "travel_date",
      "duration",
      "net_cost",
      "markup",
      "discount",
    ];

    // const isValid = requiredFields.every((field) => formData[field].trim() !== "");

    // if (!isValid) {
    //   toast.error("Please fill all mandatory fields");
    //   setdisableSubmit(false);
    //   return;
    // }

    const data1 = new FormData();
    data1.append("formData", JSON.stringify(formData));
    data1.append("editorContents", JSON.stringify(editorContents));
    data1.append("clientId", JSON.stringify(clientId));
    fileData.forEach((file) => {
      data1.append("document", file);
    });

    const id = data._id;

    try {
      await updateDomesticQuotes({ data1, id });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("An error occurred during submission");
      setdisableSubmit(false);
    }
  };

  //Validation Code
  const {
    LastnameValidation,
    DurationValidation,
    NetVisaCostValidation,
    MarkupValidation,
    ActualCollectionValidation,
    DiscountValidation,
    GrrosCostValidation,
    CancelationChargesValidation,
    RefundAmountValidation,
  } = Validation();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <div className="relative">
        <div className="fixed inset-0 z-50 m-1  items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 pb-10 pl-5 pr-5 pt-10 backdrop-blur-0 lg:pl-10 lg:pr-10">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <div>
                <span
                  className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    zIndex: "1100px",
                  }}
                >
                  {" "}
                  <FaTimes size={24} />{" "}
                </span>
                <h2 className="mb-4 text-center text-lg font-semibold underline">
                  Update Domestic Package Quotation
                </h2>
              </div>
              <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="custname"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="cust_name"
                        id="custname"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="destination"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Destination <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="destination"
                        id="destination"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.destination}
                        onKeyUp={LastnameValidation}
                      />
                      <span id="last"></span>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="travel_date"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Travel Date <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        name="travel_date"
                        id="travel_date"
                        autoComplete="traveldate"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 
                          shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={formData.travel_date.slice(0, 10)}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="duration"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Duration (In Days)
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        id="duration"
                        name="duration"
                        type="text"
                        autoComplete="duration"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.duration}
                        // onKeyUp={DurationValidation}
                      />
                      <span id="Duration"></span>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="gst"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      GST <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <select
                        id="gst"
                        name="gst"
                        value={formData.gst}
                        onChange={handleInputChange}
                        required
                        className="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Select Package</option>
                        <option value="5">With 5% GST</option>
                        <option value="18">With 18% GST</option>
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="netCost"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="net_cost"
                        id="netCost"
                        autoComplete="netCost"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.net_cost}
                        onKeyUp={NetVisaCostValidation}
                      />
                      <span id="netVisaCostValidation"></span>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="markup"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="markup"
                        id="markUp"
                        autoComplete="markUp"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.markup}
                        onKeyUp={MarkupValidation}
                      />
                      <span id="MarkupValidation"></span>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="grossCost"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Gross Cost <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="gross_cost"
                        id="grossCost"
                        autoComplete="grossCost"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gross_cost}
                        onKeyUp={GrrosCostValidation}
                        readOnly
                      />
                      <span id="grrosCostValidation"></span>
                    </div>
                  </div>

                  {/* <div className="sm:col-span-3">
                    <label
                      htmlFor="discount"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Discount <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="discount"
                        id="discount"
                        autoComplete="discount"
                        className="text-grey-500 ring-grey-300 focus:ring-grey-600 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm 
                          ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.discount}
                        onKeyUp={DiscountValidation}
                      />
                      <span id="discountValidation"></span>
                    </div>
                  </div> */}

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="idealCollection"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="ideal_collection"
                        id="idealCollection"
                        autoComplete="idealCollection"
                        className="text-grey-500 ring-grey-300 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 
                          ring-inset focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="deficit"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="deficit"
                        id="deficit"
                        autoComplete="deficit"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Itinaray
                  ref={editor}
                  editorContents={editorContents}
                  onEditorChange={handleEditorChangeParent}
                  setFileData={setFileData}
                  id={data._id}
                />
              </div>
              <div className="mt-4 flex place-content-center">
                <button
                  className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  onClick={handleSubmit}
                  disabled={disablesubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
