import Repository from "./Repository.js";

class AdminRepository {
  // REPOSITORY FOR POST LEAD DATA
  async addLead(payload) {
    const response = await Repository.post("/admin/save-lead", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //Update Lead Data
  async updateLead(payload) {
    const response = await Repository.patch("/admin/update-lead", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // REPOSITORY FOR UPDATE ACCOUNT DETAILS
  async updateAccount(params) {
    const response = await Repository.patch(`/admin/update-account`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPORITORY FOR UPDATE PROFILE SETTINGS
  async updateProfile(params) {
    const response = await Repository.patch(`/admin/update-profile`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // REPOSITORY FOR POST SETTINGS DATA
  async addSettingsData(payload) {
    const response = await Repository.post("/admin/settings", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPOSITORY FOR FETCH ACCOUNT DATA
  async reqAccountData() {
    const response = await Repository.get("/admin/accountdata")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // REPOSITORY FOR FETCH SETTINGS DATA
  async reqSettingsData() {
    const response = await Repository.get("/admin/settingdata")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // REPOSITORY FOR POST ACCOUNT DETAILS
  async addAccountDetails(payload) {
    const response = await Repository.post("/admin/account", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For International Quotation
  async postInternationalQuotation(payload) {
    const response = await Repository.post("/admin/international-quot", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return response;
  }

  // UPDATE QUOTATION REPOSITORY ========================== //

  // Repository For Update International Quotation

  async updateInternationalQuot(payload) {
    const { id, data1 } = payload;
    const response = await Repository.patch(
      `/admin/update-international-quotation/${id}`,
      data1
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Domestic Quotation

  async updateDomesticQuot(payload) {
    const { id, data1 } = payload;
    const response = await Repository.patch(
      `/admin/update-domestic-quotation/${id}`,
      data1
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Hotel Book Quotation
  async updateHotelBookQuot(payload) {
    const { id, data1 } = payload;
    const response = await Repository.patch(
      `/admin/update-hotelbook-quotation/${id}`,
      data1
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Visa Quotation

  async updateVisaQuot(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-visa-quotation/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Insurance Quotation

  async updateInsuranceQuot(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-insurance-quotation/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Insurance Quotation

  async updateFlightQuot(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-flight-quotation/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update International Invoice

  async updateInvoiceData(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-invoice/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Domestic Invoice

  async updateDomesticInvoiceData(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-domestic-invoice/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Domestic Invoice

  async updateHotelBookInvoiceData(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-hotelbook-invoice/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Domestic Invoice

  async updateVisaInvoiceData(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-visa-invoice/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Insurance Invoice

  async updateInsuranceInvoiceData(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-insurance-invoice/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Update Insurance Invoice
  async updateFlightInvoiceData(params) {
    const { id, formData } = params;
    const response = await Repository.patch(
      `/admin/update-flight-invoice/${id}`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // Repository For Domestic  Quotation
  async postDomesticQuotation(payload) {
    const response = await Repository.post("/admin/domestic-quot", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }

  // Repository For Hotel  Quotation
  async postHotelQuotation(payload) {
    const response = await Repository.post("/admin/hotel-quot", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }

  // Repository For Visa  Quotation
  async postVisaQuotation(payload) {
    const response = await Repository.post("/admin/visa-quot", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }

  // Repository For Insurance  Quotation
  async postInsuranceQuotation(payload) {
    const response = await Repository.post("/admin/insurance-quot", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }

  // Repository For Get Lead Data
  async getLeadData() {
    const response = await Repository.get("/admin/get-leads")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }

  // GET LEAD DATA
  async getLeads() {
    const response = await Repository.get("/admin/get-leads")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }

  // get quotation data
  async getQuotationData() {
    const response = await Repository.get("/admin/get-quotation-data")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // post invoice data
  async postInvoiceData(payload) {
    const response = await Repository.post("/admin/save-invoice-data", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // post flight data
  async postFlightData(payload) {
    const response = await Repository.post("/admin/flight-quot", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  // GET ALL QUOTATION COMBINED DATA
  async getCombinedQuotationData() {
    const response = await Repository.get("/admin/get-combine-quotation-data")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }

  // GET ALL INVOICE COMBINED DATA
  async getCombinedInvoiceData() {
    const response = await Repository.get("/admin/get-combine-invoice-data")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return response;
  }
  //REPOSITORY FOR REMARK DETAILS
  async postRemark(payload) {
    const response = await Repository.post("/admin/remark-details", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPOSITORY FOR FETCH REMARK DETAILS
  async reqRemarkDetails() {
    const response = await Repository.get("/admin/remarkdata")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPOSITORY FOR DELETE FILES
  async deleteFilesData(payload) {
    const { documentId, leadId } = payload;
    const response = await Repository.delete(
      `/admin/delete-file/${leadId}/${documentId}`
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPOSITORY FOR DELETE FILES
  async deleteItineraryFilesData(payload) {
    const { documentId, id } = payload;
    const response = await Repository.delete(
      `/admin/delete-itinerary-file/${id}/${documentId}`
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPOSITOTY FOR ADD FILES
  async reqAddFiles(payload) {
    const { id, formData } = payload;
    const response = await Repository.post("/admin/add-file", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //
  async reqAddInvFiles(payload) {
    const { id, formData } = payload;
    const response = await Repository.post("/admin/add-invoice-file", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPOSITOTY FOR DOWNLOAD FILES
  async reqDownloadFiles(payload) {
    const response = await Repository.patch("/admin/download-file", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //REPOSITOTY FOR DOWNLOAD INVOICE FILES
  async reqDownloadinvoice(payload) {
    console.log(payload);
    const response = await Repository.patch("/admin/download-invoice-file", payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }
}
export default new AdminRepository();
