import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import LeadsData from "./components/Dashboard/LeadsData";
import { IoPeopleSharp } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import { useAdmin } from "../../../hooks/useAdmin";
import { RiPassPendingLine } from "react-icons/ri";
import DashboardTables from "./components/Dashboard/DashboardTables";

const Dashboard = () => {
  const [quantity, setquantity] = useState(0);

  const {
    getLeadDetails,
    leadData,
    getCombinedQuotationData,
    quotationCombinedData,
    getCombinedInvoiceData,
    invoiceCombinedData,
    loading,
  } = useAdmin();

  useEffect(() => {
    getCombinedQuotationData();
    getLeadDetails();
  }, [loading]);

  useEffect(() => {
    getCombinedInvoiceData();
  }, [loading]);

  const customers = leadData?.filter((data) => data.client_type === "Customer");
  const leads = leadData?.filter((data) => data.client_type === "Lead");
  const invoice = invoiceCombinedData?.filter(
    (data) => data.client_type === "invoice"
  );
  const quotation = leadData?.filter(
    (data) => data.client_type === "Quotation"
  );

  return (
    <div className="p-4">
      <div className="mb-3 mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
        <Widget
          className="flex flex-col items-center rounded-lg bg-white p-4 shadow"
          icon={<MdBarChart className="h-8 w-8" />}
          title={"Total Leads"}
          subtitle={leadData?.length}
        />
        <Widget
          className="flex flex-col items-center rounded-lg bg-white p-4 shadow"
          icon={<IoPeopleSharp className="h-8 w-8" />}
          title={"Converted to Customer"}
          subtitle={customers?.length}
        />
        <Widget
          className="flex flex-col items-center rounded-lg bg-white p-4 shadow"
          icon={<RiPassPendingLine className="h-8 w-8" />}
          title={"Pending Leads"}
          subtitle={leads?.length}
        />
      </div>
      <div className="mb-4 mt-3">
        <DashboardTables />
      </div>
    </div>
  );
};

export default Dashboard;
