import axios from "axios";

export const baseURL = "https://otripserver.fresherszone.in";

export const axiosInstance = axios.create({
  baseURL,
});

// axiosInstance.interceptors.request.use(function (config) {
//   const token = localStorage.getItem("token");
//   config.headers.Authorization = `Bearer ${token}`;
//   return config;
// });

export default axiosInstance;
