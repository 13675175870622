import { useEffect, useState } from "react";
import International from "./International";
import Domestic from "./Domestic";
import HotelBook from "./HotelBook";
import Visa from "./Visa";
import Flight from "./Flight";
import Insurance from "./Insurance";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Validation } from "views/admin/marketplace/components/Validation";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { useDispatch } from "react-redux";

export default function Quotation({ onClose, data }) {
  const dispatch = useDispatch();

  // console.log(data);

  const [showInternational, setShowInternational] = useState(false);
  const [showDomestic, setShowDomestic] = useState(false);
  const [showHotelBook, setShowHotelBook] = useState(false);
  const [showVisa, setShowVisa] = useState(false);
  const [showFlight, setShowFlight] = useState(false);
  const [showInsurance, setShowInsurance] = useState(false);
  const [showPckgPopUp, setShowPckgPopUp] = useState(false);
  const [cutomerEmail, setCustomerEmail] = useState("");
  const { getLeadDetails, leadData, loading } = useAdmin();

  const [data1, setdata1] = useState();

  const handleSubModalClose = () => {
    setParentFormData((parentFormData) => {
      return { ...parentFormData, package_type: "" };
    });
  };

  const closeNewLeadForm = () => {
    handleSubModalClose();
    setShowInternational(false);
  };
  const closeDomesticForm = () => {
    handleSubModalClose();
    setShowDomestic(false);
  };
  const closeHotelForm = () => {
    handleSubModalClose();
    setShowHotelBook(false);
  };
  const closeFlightForm = () => {
    handleSubModalClose();
    setShowFlight(false);
  };
  const closeVisaForm = () => {
    handleSubModalClose();
    setShowVisa(false);
  };
  const closeInsuranceForm = () => {
    handleSubModalClose();
    setShowInsurance(false);
  };

  useEffect(() => {
    getLeadDetails();
  }, [loading]);

  // Forms data for parent class
  const [parentFormData, setParentFormData] = useState({
    start_date: "",
    end_date: "",
    proposal_title: "",
    budget: "",
    follow_up_date: "",
    no_of_adult: "",
    no_of_children: "",
    package_type: "Select Package",
  });

  const [exist, setExist] = useState(false);
  const [name, setName] = useState();
  const [clientID, setClientId] = useState();

  function validateForm() {
    if (
      parentFormData.start_date !== "" &&
      parentFormData.end_date !== "" &&
      parentFormData.proposal_title !== "" &&
      parentFormData.budget !== "" &&
      parentFormData.follow_up_date !== "" &&
      parentFormData.no_of_adult !== "" &&
      parentFormData.no_of_children !== ""
    ) {
      return true;
    }
    return false;
  }

  // Conditions for open quotations forms
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "package_type") {
      setShowInternational(false);
      setShowDomestic(false);
      setShowHotelBook(false);
      setShowVisa(false);
      setShowFlight(false);
      setShowInsurance(false);
      if (value === "International") {
        if (validateForm() === true) {
          setShowInternational(true);
          setdata1(data1);
        } else {
          toast.error("Please fill mandatory fields");
        }
      } else if (value === "Domestic") {
        if (validateForm() === true) {
          setShowDomestic(true);
          setdata1(data1);
        } else {
          toast.error("Please fill mandatory fields");
        }
      } else if (value === "HotelBook") {
        if (validateForm() === true) {
          setShowHotelBook(true);
          setdata1(data1);
        } else {
          toast.error("Please fill mandatory fields");
        }
      } else if (value === "Visa") {
        if (validateForm() === true) {
          setShowVisa(true);
          setdata1(data1);
        } else {
          toast.error("Please fill mandatory fields");
        }
      } else if (value === "Flight") {
        if (validateForm() === true) {
          setShowFlight(true);
          setdata1(data1);
        } else {
          toast.error("Please fill mandatory fields");
        }
      } else if (value === "Insurance") {
        if (validateForm() === true) {
          setShowInsurance(true);
          setdata1(data1);
        } else {
          toast.error("Please fill mandatory fields");
        }
      }
    }

    setParentFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const isFilled = Object.keys(parentFormData)
      .filter((key) => key !== "package_type") // Exclude the 'fieldToExclude'
      .every((key) => parentFormData[key].trim() !== "");

    // setShowSelect(isFilled);
  };

  const updatePckgPopUpState = (flag) => {
    setShowPckgPopUp(flag);
  };

  // Validations For Form
  const {
    AdultNumberValidation,
    ChildNumberValidation,
    FirstnameValidation,
    OnlyNumberValidation,
  } = Validation();

  // To close childs
  // const [closeForm, setCloseForm] = useState(true);
  const handleClose = (e) => {
    onClose();
    setShowInternational(false);
    setShowDomestic(false);
    setShowHotelBook(false);
    setShowVisa(false);
    setShowFlight(false);
    setShowInsurance(false);
  };

  useEffect(() => {
    console.log("International value", showInternational);
  }, [showInternational]);

  return (
    <>
      <ToastContainer />
      {/* {closeForm ? ( */}
      <div className="relative">
        <div class="backdrop-blu fixed inset-0 z-50 items-center justify-center overflow-y-scroll bg-gray-400 pb-20 pl-5 pr-5 pt-3 lg:pl-20 lg:pr-20">
          <div>
            <div className="z-50 rounded-tl-lg rounded-tr-lg bg-gray-200  shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                {" "}
                <FaTimes size={24} />{" "}
              </span>
              <form
                style={{ padding: "20px 20px" }}
                onSubmit={() => console.log("Form is submitting")}
              >
                <h2 className="mb-4 text-center text-xl font-semibold underline">
                  Quotation
                </h2>
                <div className="mb-8"></div>
                <div class="">
                  <h2 className="text-normal mb-4 text-center font-semibold underline">
                    Enter Customer Details
                  </h2>
                  <div className="border-b border-gray-900/10 pb-12">
                    <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      {/* <div class="sm:col-span-3">
                      <label
                        for="customerName"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Package Type
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          type="text"
                          name=""
                          id="customerName"
                          class="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          // onChange={handleInputChange}
                          value={data.package_type}
                          readOnly
                        />
                        <span id="fullnamevalidation"></span>
                      </div>
                    </div> */}
                      <div class="sm:col-span-3">
                        <label
                          for="customerName"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Customer Name
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            type="text"
                            name=""
                            id="customerName"
                            class="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            // onChange={handleInputChange}
                            value={data.name}
                            readOnly
                          />
                          <span id="fullnamevalidation"></span>
                        </div>
                      </div>
                      <div class="sm:col-span-3">
                        <label
                          for=""
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Customer Email{" "}
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            type="text"
                            name="cust_email"
                            id="customerEmail"
                            class="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            // onChange={handleInputChange}
                            value={data.email}
                            readOnly
                          />
                          <span id="Email"></span>
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="startdate"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Quotation Generate Date{" "}
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            type="date"
                            name="start_date"
                            id="startdate"
                            autoComplete="startdate"
                            class="block w-full appearance-none rounded-md border border-gray-300 px-2 py-1.5 placeholder-gray-400 
                               shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={handleInputChange}
                            value={parentFormData.start_date}
                            // max={new Date().toISOString().split("T")[0]}
                            required
                          />
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="praporsal title"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Proposal Title{" "}
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            type="text"
                            name="proposal_title"
                            id="praposaltitle"
                            autocomplete="praposaltitle"
                            class="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 
                                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={parentFormData.proposal_title}
                            // onKeyUp={FirstnameValidation}
                            required
                          />
                          {/* <span id="first"></span> */}
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="enddate"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Quotation Expiry Date{" "}
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            type="date"
                            name="end_date"
                            id="enddate"
                            autocomplete="enddate"
                            class="block w-full appearance-none rounded-md border border-gray-300 px-2 py-1.5 placeholder-gray-400 
                    shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={handleInputChange}
                            value={parentFormData.end_date}
                            min={parentFormData.start_date}
                            required
                          />
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="budget"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Budget(INR) <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="budget"
                            name="budget"
                            type="text"
                            autocomplete="budget"
                            class="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 
                  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={parentFormData.budget}
                            onKeyUp={OnlyNumberValidation}
                            required
                          />
                          <span id="onlyNumber"></span>
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="follow-up-date"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Follow up Date{" "}
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            type="date"
                            name="follow_up_date"
                            id="followupdate"
                            autoComplete="followupdate"
                            class="block w-full appearance-none rounded-md border border-gray-300 px-2 py-1.5 placeholder-gray-400 
        shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={handleInputChange}
                            value={parentFormData.follow_up_date}
                            min={parentFormData.start_date}
                            max={parentFormData.end_date}
                            required
                          />
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="num-of-adult"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Number of Adults{" "}
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="numofadult"
                            name="no_of_adult"
                            type="text"
                            autoComplete="noofadult"
                            class="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={parentFormData.numofadult}
                            onKeyUp={AdultNumberValidation}
                            required
                          />
                          <span id="adultnumber"></span>
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="numofchild"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Number of Children{" "}
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        <div class="mt-2">
                          <input
                            id="numofchild"
                            name="no_of_children"
                            type="text"
                            autoComplete="numofchild"
                            class="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleInputChange}
                            value={parentFormData.numofchild}
                            onKeyUp={ChildNumberValidation}
                            required
                          />
                          <span id="childnumber"></span>
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label
                          for="items"
                          class="text-black block text-sm font-semibold leading-6"
                        >
                          Package Types
                          <sup className="mb-2 text-red-500">*</sup>
                        </label>
                        {/* {showSelect ? ( */}
                        <div class="mt-2">
                          <select
                            id="items"
                            name="package_type"
                            className="mt-2 block w-full rounded-md border border-gray-300 px-4 py-1.5 shadow-sm focus:border-indigo-500 sm:text-sm"
                            onChange={handleInputChange}
                            value={parentFormData.package_type}
                            required
                          >
                            <option>Select Package</option>
                            <option value="International">
                              International Package
                            </option>
                            <option value="Domestic">Domestic Package</option>
                            <option value="HotelBook">Hotel Booking</option>
                            <option value="Flight">Flight Booking</option>
                            <option value="Visa">Visa</option>
                            <option value="Insurance">Insurance</option>
                          </select>
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
          {showInternational ? (
            <International
              parentData={parentFormData}
              clientId={data._id}
              name={data.name}
              onClose={closeNewLeadForm}
              data={data1}
            />
          ) : null}
          {showDomestic ? (
            <Domestic
              parentData={parentFormData}
              clientId={data._id}
              name={data.name}
              onClose={closeDomesticForm}
            />
          ) : null}
          {showHotelBook ? (
            <HotelBook
              parentData={parentFormData}
              clientId={data._id}
              name={data.name}
              onClose={closeHotelForm}
            />
          ) : null}
          {showVisa ? (
            <Visa
              parentData={parentFormData}
              clientId={data._id}
              name={data.name}
              onClose={closeVisaForm}
            />
          ) : null}
          {showFlight ? (
            <Flight
              parentData={parentFormData}
              clientId={data._id}
              name={data.name}
              onClose={closeFlightForm}
            />
          ) : null}
          {showInsurance ? (
            <Insurance
              parentData={parentFormData}
              clientId={data._id}
              name={data.name}
              onClose={closeInsuranceForm}
            />
          ) : null}
        </div>
      </div>
      {/* ) : null} */}

      {exist ? (
        <div className={showPckgPopUp ? "block" : "hidden"}>
          {showInternational ? (
            <International
              handleBack={updatePckgPopUpState}
              parentData={parentFormData}
              clientId={clientID}
              name={name}
            />
          ) : null}
          {showDomestic ? (
            <Domestic
              handleBack={updatePckgPopUpState}
              parentData={parentFormData}
              clientId={clientID}
              name={name}
            />
          ) : null}
          {showHotelBook ? (
            <HotelBook
              handleBack={updatePckgPopUpState}
              parentData={parentFormData}
              clientId={clientID}
              name={name}
            />
          ) : null}
          {showVisa ? (
            <Visa
              handleBack={updatePckgPopUpState}
              parentData={parentFormData}
              clientId={clientID}
              name={name}
            />
          ) : null}
          {showFlight ? (
            <Flight
              handleBack={updatePckgPopUpState}
              parentData={parentFormData}
              clientId={clientID}
              name={name}
            />
          ) : null}
          {showInsurance ? (
            <Insurance
              handleBack={updatePckgPopUpState}
              parentData={parentFormData}
              clientId={clientID}
              name={name}
            />
          ) : null}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
