import axios from "axios";
// import { useCustomer } from "hooks/useCustomer";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Validation } from "./Validation";
import { useAdmin } from "hooks/useAdmin";


const UpdateCustomer = ({ onClose }) => {

  const {Customerdetails}=useAdmin();
  
  let [countries, setCountries] = useState([]);
  let [Cities, setCities] = useState([]);

  const [formData, setFormData] = useState({
    // name: "John Brown",
    // mobileNumber: "1234567890",
    // email: "john.brown@gmail.com",
    // pan: "CVBG5216L",
    // country: "India",
    // city: "New York",
    // gst:"5%",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'country') {
      fetchCities(value);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Customerdetails(formData);
    onClose();
  };


  const fetchCountries = async () => {
    let country = await axios.get(
      "https://countriesnow.space/api/v0.1/countries"
    );
    console.log(country);

    setCountries(country.data.data);
  };
  const fetchCities = (country) => {
    const Cities = countries.find((c) => c.country === country);
    setCities(Cities.cities);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  
//validation code
const {
  FullnameValidation,
  CitynameValidation,
  ContrynameValidation,
  NumberValidation,
  PanCardValidation,
  GSTnumberValidation,
  EmailValidation,
  } = Validation();

  return (
    <>
      <div className="relative">
        <div class="fixed inset-0 z-50 m-1 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur">
          <div>
            {/* <div className="mb-1">
              
            </div> */}
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <h2 className="mb-4 text-center text-lg font-semibold underline">
                Update Customer Form
              </h2>
              <span
                className="fa-times-icon mr-[-27px] mb-2 flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                <FaTimes size={24} />
              </span>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="mb-1 block">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full rounded-lg border-gray-800 px-3 py-2"
                    placeholder="Enter Customer Name"
                    required
                    onKeyUp={FullnameValidation} 
                  />            
                </div>
                <snap id='fullnamevalidation'></snap>
                <div className="mb-4">
                  <label htmlFor="mobileNumber" className="mb-1 block">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className="w-full rounded-lg border-gray-800 px-3 py-2"
                    placeholder="Enter Customer Mobile Number"
                    required
                    onKeyUp={NumberValidation}
                  />
                </div>
                <span id='Number'></span>
                <div className="mb-4">
                  <label htmlFor="gst" className="mb-1 block">
                    GST Number
                  </label>
                  <input
                    type="text"
                    id="gst"
                    name="gst"
                    value={formData.gst}
                    onChange={handleChange}
                    className="w-full rounded-lg border-gray-800 px-3 py-2"
                    placeholder="Enter Customer GST Number"
                    required
                    onKeyUp={GSTnumberValidation}
                  />
                </div>
                <span id='gstnumber'></span>
                <div className="mb-4">
                  <label htmlFor="email" className="mb-1 block">
                    Email ID
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full rounded-lg border-gray-800 px-3 py-2"
                    placeholder="Enter Customer Email "
                    required
                    onKeyUp={EmailValidation}
                  />
                </div>
                <span id='Email'></span>
                <div className="mb-4">
                  <label htmlFor="email" className="mb-1 block">
                    PAN Number
                  </label>
                  <input
                    type="pan"
                    id="pan"
                    name="pan"
                    value={formData.pan}
                    onChange={handleChange}
                    className="w-full rounded-lg border-gray-800 px-3 py-2"
                    placeholder="Enter customer PAN Number"
                    required
                    onKeyUp={PanCardValidation}
                  />
                </div>
                <span id='Pancard'></span>
                <div className="mb-4 flex flex-wrap">
                  <div className="mb-4">
                  <label htmlFor="name" className="mb-1 block">
                   Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className="mb-2 w-full rounded-lg border-gray-300 px-3 py-2 md:mb-0 md:mr-2 md:w-auto"
                    placeholder="Enter Customer Country"
                    required
                    onKeyUp={ContrynameValidation}
                  />       
                  <div><span id='countri'></span></div>    
                </div>
          
                <div className="mb-4">
                  <label htmlFor="name" className="mb-1 block">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="mb-2 w-full rounded-lg border-gray-300 px-3 py-2 md:mb-0 md:mr-2 md:w-auto"
                    placeholder="Enter Customer City"
                    required
                    onKeyUp={CitynameValidation}
                  />            
                </div>
                <div><span id="City"></span></div> 
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="rounded-lg bg-blue-500 px-4 py-2 text-white"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCustomer;
