import Card from "components/card";
import { useEffect, useState, useRef, useMemo } from "react";
import { IoFilter } from "react-icons/io5";
import { useAdmin } from "hooks/useAdmin";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as XLSX from "xlsx";

const InvoiceReports = (props) => {
  const { getCombinedInvoiceData, loading, invoiceCombinedData } = useAdmin();
  const [showloading, setShowLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const menuRef = useRef();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getCombinedInvoiceData();
    setShowLoading(false);
  }, [loading]);
  useEffect(() => {
    if (invoiceCombinedData && Array.isArray(invoiceCombinedData)) {
      // Reverse the quotationCombinedData array and then set it to filteredData state
      const reversedData = [...invoiceCombinedData].reverse();
      setFilteredData(reversedData);
    }
  }, [invoiceCombinedData]);

  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    const filteredResults = invoiceCombinedData.filter((item) =>
      item?.package_type.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsFilterOpen(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const searchValue = e.target.value.toLowerCase();
    const filteredResults = invoiceCombinedData.filter((item) => {
      const packageTypeMatch = item?.package_type
        .toLowerCase()
        .includes(searchValue);
      const customerNameMatch = item?.cust_name
        .toLowerCase()
        .includes(searchValue);
      return packageTypeMatch || customerNameMatch;
    });

    setFilteredData(filteredResults);
  };

  useEffect(() => {
    const filteredResults = invoiceCombinedData?.filter((item) => {
      const itemDate = new Date(item.start_date);
      const searchStartDate = startDate ? new Date(startDate) : null;
      const searchEndDate = endDate ? new Date(endDate) : null;

      if (searchStartDate && searchEndDate) {
        return itemDate >= searchStartDate && itemDate <= searchEndDate;
      }
      return true;
    });

    setFilteredData(filteredResults);
  }, [startDate && endDate]);

  let length = filteredData?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const record = filteredData?.length
    ? filteredData.slice(firstIndex, lastIndex)
    : 0;
  const nPages = filteredData?.length
    ? Math.ceil(filteredData?.length / recordPerPage)
    : 0;
  const numbers = [...Array(nPages + 1)?.keys()]?.slice(1);

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(nPages);
  };

  //code for export to excel
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      filteredData.map((item, index) => {
        // Calculate GST percentage
        let gstPercentage = 0; // Default GST percentage to 0

        if (item?.package_type === "HotelBook") {
          // Check conditions for Hotel Bookings
          if (item?.deficit !== 0 && item?.gst_on_markup !== 0) {
            gstPercentage = 18; // Set GST to 18% for hotel bookings
          }
        } else if (
          item?.package_type === "Visa" ||
          item?.package_type === "Insurance" ||
          item?.package_type === "Flight"
        ) {
          // Set GST to 18% for Visa, Insurance, and Flight bookings
          gstPercentage = 18;
        } else if (item?.package_type === "International") {
          // Set GST to 5% for International bookings
          gstPercentage = 5;
        }
        if (item?.package_type === "Domestic" && item?.gst === "5") {
          gstPercentage = 5;
        } else if (item?.package_type === "Domestic" && item?.gst === "18") {
          gstPercentage = 18;
        }

        return {
          ...item,
          "GST Percentage": `${gstPercentage.toFixed(2)}%`,
          key: index,
        };
      })
    );
    XLSX.utils.book_append_sheet(wb, ws, "invoice Data");
    XLSX.writeFile(wb, "invoice_data.xlsx");
  };

  return (
    <>
      {showloading ? (
        <Skeleton count={10} height={40} />
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between"></header>
          <div className="mt-4 flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="inline-block min-w-full p-1.5 align-middle">
                <div className="divide-y divide-gray-200 rounded-lg border dark:divide-gray-700 dark:border-gray-700">
                  <div className="px-4 py-3">
                    <div className="flex items-center justify-between">
                      <div className="relative w-1/2">
                        <label className="sr-only">Search</label>
                        <input
                          type="text"
                          name="hs-table-with-pagination-search"
                          id="hs-table-with-pagination-search"
                          className="dark:bg-slate-900 border-black block w-full rounded-lg border px-4 py-2 ps-10 text-sm"
                          placeholder="Search a Invoice by Name/Package type"
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                          <svg
                            className="size-4 text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                          </svg>
                        </div>
                      </div>
                      <div ref={menuRef} className="relative mr-20">
                        <div className="dark:bg-slate-900 flex items-center space-x-2 text-nowrap px-4 py-2 ps-10 text-sm text-gray-400">
                          <label htmlFor="startDate">From:</label>
                          <input
                            type="date"
                            id="startDate"
                            onChange={(e) => setStartDate(e.target.value)}
                            className="rounded border p-2"
                          />
                          {/* <br /> */}
                          <label htmlFor="endDate">To:</label>
                          <input
                            type="date"
                            id="endDate"
                            onChange={(e) => setEndDate(e.target.value)}
                            className="rounded border p-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-400 dark:bg-gray-700">
                        <tr className="whitespace-nowrap">
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Sr No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Customer Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Package Type
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Start Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            End Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-sm font-extrabold uppercase text-gray-900"
                          >
                            Client Type
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {record && record?.length >= 0 ? (
                          record?.map((data, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {index +
                                      1 +
                                      (currentPage - 1) * recordPerPage}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.cust_name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {data.package_type}
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {new Date(
                                      data.start_date
                                    ).toLocaleDateString("en-IN")}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    {new Date(data.end_date).toLocaleDateString(
                                      "en-IN"
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-1 text-center text-sm font-medium text-gray-800 dark:text-gray-200">
                                    Invoice
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" className="py-4 text-center">
                              No data found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="flex items-center justify-center -space-x-px rounded-md shadow-sm">
            <ul className="flex items-center justify-center border-t px-4 py-3 sm:px-6">
              {currentPage !== 1 && currentPage !== 2 && (
                <>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={goToFirstPage}
                      aria-label="First"
                    >
                      First
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={prePage}
                      aria-label="Previous"
                    >
                      Previous
                    </Link>
                  </li>
                </>
              )}
              {numbers.map(
                (n, i) =>
                  (n === currentPage ||
                    n === currentPage - 1 ||
                    n === currentPage + 1) && (
                    <li
                      className={`page-item ${
                        currentPage === n ? "active" : ""
                      }`}
                      key={i}
                    >
                      <Link
                        href="#"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                          currentPage === n ? "bg-gray-200" : ""
                        }`}
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </Link>
                    </li>
                  )
              )}
              {currentPage !== nPages && (
                <>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={nextPage}
                      aria-label="Next"
                    >
                      Next
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={goToLastPage}
                      aria-label="Last"
                    >
                      Last
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="flex justify-end">
            <button
              className="flex items-end justify-center rounded border-gray-600 bg-green-500 px-2 py-1 text-white hover:bg-green-700"
              onClick={exportToExcel}
            >
              Export To Excel
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

export default InvoiceReports;
