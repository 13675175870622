import { useState } from "react";
import AuthRepository from "repositories/AuthRepository";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { login, logOut } from "store/auth/reducer";
import { useDispatch } from "react-redux";
// import { useHistory } from 'react-router-dom';

export const useAuth = () => {
  const route = useNavigate();
  const dispatch = useDispatch();

  return {
    logIn: async (payload) => {
      const response = await AuthRepository.loginReq(payload);
      if (response) {
        if (response.status == 200) {
          localStorage.setItem("token", response.data?.accessToken);
          const timeSpan = new Date().getTime();
          localStorage.setItem("timeSpan", JSON.stringify({ timeSpan }));
          dispatch(login());
          toast.success(response.data?.message);

          const delayTime = 3000;
          setTimeout(() => {
            route("/admin/default");
          }, delayTime);
        } else {
          toast.warn("Authentication Failed");
        }
      }
    },

    logout: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("timeSpan");
      dispatch(logOut());
      toast.success("Logout Successfully");
      const delayTime = 5000;
      setTimeout(() => {
        route("/");
      }, delayTime);
    },

    forgotpassword: async (payload) => {
      const response = await AuthRepository.forgotPass(payload);
      if (response) {
        if (response.status == 200) {
          toast.success(response.data?.message);

          const delayTime = 5000; // 2000 milliseconds = 2 seconds
          setTimeout(() => {
            route("/");
          }, delayTime);
        } else if (response.status === 401) {
          toast.error(response.data?.message);
        } else if (response.status === 404) {
          toast.warn(response.data?.message);
        }
      }
    },
  };
};
