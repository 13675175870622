import { useEffect, useState } from "react";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";
import { useAdmin } from "../../../hooks/useAdmin";
import { toast } from "react-toastify";
import { Validation } from "views/admin/marketplace/components/Validation";
export default function UpdateFlightMultiCityQuotation({ onClose, data }) {
  const { updateFlightQuotation } = useAdmin();
  const [id, setId] = useState(data._id);
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [formData, setFormData] = useState({
    trip_type: "",
    cust_name: "",
    diparture_city: "",
    destination_city: "",
    pnr: "",
    travel_date: "",
    airline: "",
    booking_source: "",
    net_cost: "",
    mark_up: "",
    gstcost: "",
    ideal_collection: "",
    actual_collection: "",
    deficit: "",
    cancellation_charges: "",
    refund_amount: "",
    multi_city_route: [],
  });

  useEffect(() => {
    if (data) {
      setFormData({
        trip_type: data.trip_type,
        cust_name: data.cust_name,
        airline: data.airline,
        booking_source: data.booking_source,
        net_cost: data.net_cost,
        mark_up: data.mark_up,
        gstcost: data.gstcost,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        cancellation_charges: data.cancellation_charges,
        deficit: data.deficit,
        refund_amount: data.refund_amount,
        multi_city_route: data.multi_city_route.map((route) => ({
          destination_city: route.destination_city,
          diparture_city: route.diparture_city,
          pnr: route.pnr,
          travel_date: new Date(route.travel_date),
        })),
      });
    }
  }, [data]);

  //   console.log(formData);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const uppercaseValue = name === "pnr" ? value.toUpperCase() : value;

    if (index !== undefined) {
      setFormData((prevState) => {
        const updatedRoutes = [...prevState.multi_city_route];
        updatedRoutes[index][name] = value;
        return { ...prevState, multi_city_route: updatedRoutes };
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: uppercaseValue,
      }));
    }

    if (name === "netcost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };

  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      ideal_collection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  //Validation Code

  const {
    AlphaValidation,
    ToValidation,
    error1,
    errorMessage,
    PnrValidation,
    pnrError,
    AirlineValidation,
    airlineError,
    BookingSourceValidation,
    bookingError,
    NetVisaCostValidation,
    MarkupValidation,
    ActualCollectionValidation,
    CancelationChargesValidation,
    RefundAmountValidation,
  } = Validation();






  const handleRouteChange = (index, field, value) => {
    const updatedRoutes = formData.multi_city_route.map((route, i) =>
      i === index ? { ...route, [field]: value } : route
    );
    setFormData((prevState) => ({
      ...prevState,
      multi_city_route: updatedRoutes,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "diparture_city",
      "destination_city",
      "pnr",
      "travel_date",
      "airline",
      "booking_source",
      "net_cost",
      "mark_up",

    ];

    const allRoutesValid = formData.multi_city_route.every((route) =>
      ["destination_city", "diparture_city", "pnr", "travel_date"].every(
        (field) => route[field]?.toString().trim() !== ""
      )
    );

    const isValid = requiredFields.every(
      (field) => formData[field]?.toString().trim() !== ""
    );

    if (!isValid || !allRoutesValid) {
      toast.error("Please fill all mandatory fields");
      return;
    }

    setDisableSubmit(true);

    try {
      await updateFlightQuotation({ id, formData });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("An error occurred during submission");
      setDisableSubmit(false);
    }
  };








  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <div className={`$ "hidden" : "block" relative`}>
        <div class="fixed inset-0 z-50 m-1 items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                <FaTimes size={24} />
              </span>
              <form style={{ padding: "40px 40px" }}>
                <h2 className="mb-4 text-center text-lg font-semibold underline">
                  Update Multi City Quotation
                </h2>
                {/* <div class="border-b border-gray-900/10 pb-12">
                  <div className="mt-4"> */}

                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {/* <div className="mb-4 flex flex-wrap"> */}
                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Trip Type <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="trip_type"
                        id="trip_type"
                        autocomplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                              focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.trip_type}
                        readOnly
                      />
                      <span id="last"></span>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name{" "}
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="cust_name"
                        id="cust_name"
                        autocomplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                              focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        readOnly
                      />
                      <span id="last"></span>
                    </div>
                  </div>
                </div>
                <div className="mb-4 flex flex-wrap">
                  {formData.multi_city_route &&
                    formData.multi_city_route.map((route, index) => (
                      <>
                        <div className="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-2 lg:ml-[50px]">
                            <label
                              for={`diparture_city_${index}`}
                              class="text-black block text-sm font-semibold leading-6"
                            >
                              From{" "}
                              <sup className="mb-2 text-red-500">*</sup>
                            </label>
                            <div class="mt-2">
                              <input
                                id={`diparture_city_${index}`}
                                name="diparture_city"
                                type="text"
                                autocomplete="diparture_city"
                                className="block w-full lg:w-[350px] rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                             focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                             onChange={(e) => handleRouteChange(index, "diparture_city", e.target.value)}
                                onKeyUp={AlphaValidation}
                                value={route.diparture_city}
                                required
                              />
                              {errorMessage && (
                                <span style={{ color: "red" }}>
                                  {errorMessage}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="flex justify-center items-center sm:col-span-2 mt-4 sm:mt-0">
                            <FontAwesomeIcon
                              icon={faArrowRightArrowLeft}
                              className="text-red-500"
                              style={{ marginTop: "40px" }}
                            // onClick={() => swapValuesMultiCity(i)}
                            />
                          </div>
                          <div class="sm:col-span-2 lg:mr-[50px]">
                            <label
                              for={`destination_city_${index}`}
                              class="text-black block text-sm font-semibold leading-6"
                            >
                              To<sup className="mb-2 text-red-500">*</sup>
                            </label>
                            <div class="mt-2">
                              <input
                                id={`destination_city_${index}`}
                                name="destination_city"
                                type="text"
                                autocomplete="destination_city"
                                className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  handleInputChange(e, index)
                                }
                                value={route.destination_city}
                                onKeyUp={ToValidation}
                                required
                              />
                              {error1 && (
                                <span style={{ color: "red" }}>
                                  {error1}
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="sm:col-span-3">
                            <label
                              for={`pnr_${index}`}
                              class="text-black block text-sm font-semibold leading-6"
                            >
                              PNR
                            </label>
                            <div class="mt-2">
                              <input
                                id={`pnr_${index}`}
                                name="pnr"
                                type="text"
                                autocomplete="pnr"
                                className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => {
                                  e.target.value = e.target.value.toUpperCase();
                                  handleInputChange(e, index);
                                }}
                                onKeyUp={PnrValidation}
                                value={route.pnr}
                                required
                              />
                              {/* {pnrError && (
                                    <span style={{ color: "red" }}>
                                      {pnrError}
                                    </span>
                                  )} */}
                            </div>
                          </div>
                          <div class="sm:col-span-3">
                            <label
                              for={`travel_date_${index}`}
                              class="text-black mr-4 block text-sm font-semibold leading-6"
                            >
                              Travel Date{" "}
                              <sup className="mb-2 text-red-500">*</sup>
                            </label>
                            <div class="mt-2">
                              <input
                                id={`travel_date_${index}`}
                                name="travel_date"
                                type="date"
                                autoComplete="travel_date"
                                className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                            focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  handleInputChange(e, index)
                                }
                                value={
                                  route.travel_date
                                    ? new Date(route.travel_date)
                                      .toISOString()
                                      .split("T")[0]
                                    : ""
                                }
                                reuired
                              />
                            </div>
                          </div>


                          {/* <button
                          type="button"
                          className="mt-7  rounded border-gray-600 bg-red-500 px-5 py-2 text-white hover:bg-red-700"
                          // onClick={handleDelete}
                        >
                          Delete
                        </button> */}
                        </div>
                      </>
                    ))}
                </div>
                {/* ))} */}
                {/* </div> */}
                {/* <button
                      type="button"
                      className="mt-7  rounded border-gray-600 bg-red-500 px-5 py-2 text-white hover:bg-red-700"
                      // onClick={handleClick}
                    >
                      Add +
                    </button> */}
                {/* </div> */}

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      for="airline"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Airlines<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="airline"
                        name="airline"
                        type="text"
                        autocomplete="airline"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.airline}
                        onKeyUp={AirlineValidation}
                        required
                      />
                      {airlineError && (
                        <span style={{ color: "red" }}>{airlineError}</span>
                      )}
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="bookingsource"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Booking Source
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="booking_source"
                        name="booking_source"
                        type="text"
                        autocomplete="bookingsource"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.booking_source}
                        onKeyUp={BookingSourceValidation}
                        required
                      />
                      {bookingError && (
                        <span style={{ color: "red" }}>{bookingError}</span>
                      )}
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="netcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="net_cost"
                        name="net_cost"
                        type="text"
                        autocomplete="netcost"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.net_cost}
                        onKeyUp={NetVisaCostValidation}
                        required
                      />
                      <span id="netVisaCostValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="markup"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="mark_up"
                        name="mark_up"
                        type="text"
                        autocomplete="markup"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.mark_up}
                        onKeyUp={MarkupValidation}
                        required
                      />
                      <span id="MarkupValidation"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gstcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      GST 18% on Mark Up
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="gstcost"
                        name="gstcost"
                        type="text"
                        autocomplete="gstcost"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gstcost}
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="idealcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="ideal_collection"
                        name="ideal_collection"
                        type="text"
                        autocomplete="idealcollection"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        readOnly
                      />
                    </div>
                  </div>
                  {/* <div class="sm:col-span-3">
                      <label
                        for="actualcollection"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Actual Collection
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="actual_collection"
                          name="actual_collection"
                          type="text"
                          autocomplete="actualcollection"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.actual_collection}
                          onKeyUp={ActualCollectionValidation}
                          required
                          readOnly
                        />
                       
                      </div>
                    </div> */}
                  <div class="sm:col-span-3">
                    <label
                      for="deficit"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="deficit"
                        name="deficit"
                        type="text"
                        autocomplete="deficit"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        readOnly
                      />
                    </div>
                  </div>

                  {/* <div class="sm:col-span-3">
                      <label
                        for="cancellationCharges"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Cancellation Charges
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="cancellation_charges"
                          name="cancellation_charges"
                          type="text"
                          autocomplete="cancellationCharges"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.cancellation_charges}
                          onKeyUp={CancelationChargesValidation}
                          required
                        />
                      <span id="cancelationChargesValidation"></span>
                      </div>
                    </div> */}

                  {/* <div class="sm:col-span-3">
                      <label
                        for="refundAmount"
                        class="text-black block text-sm font-semibold leading-6"
                      >
                        Refund Amount
                        <sup className="mb-2 text-red-500">*</sup>
                      </label>
                      <div class="mt-2">
                        <input
                          id="refund_amount"
                          name="refund_amount"
                          type="text"
                          autocomplete="refundAmount"
                          class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleInputChange}
                          value={formData.refund_amount}
                          onKeyUp={RefundAmountValidation}
                          required
                        />
                           <span id="refundAmountValidation"></span>
                      </div>
                    </div> */}
                </div>

                <div className="mt-4 flex place-content-center ">
                  <button
                    className="mr-2 rounded border-gray-600 bg-green-500 sm:px-5 sm:py-1 px-2 py-1 text-white hover:bg-green-700"
                    // type="submit"
                    onClick={handleSubmit}
                   disabled={disableSubmit}
                  >
                    Update
                  </button>
                  {/* <button
                      class="br-3 rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700 "
                      onClick={handleClose}
                    >
                      Back
                    </button> */}
                </div>

                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
