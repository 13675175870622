import { useEffect, useState } from "react";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";
import { Validation } from "views/admin/marketplace/components/Validation";
import { useAdmin } from "../../../hooks/useAdmin";
import { toast } from "react-toastify";

export default function UpdateFlightOneWay({ onClose, data }) {
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [actualcollection, setActualcollection] = useState("");
  const [showMultiCiti, setShowMultiCiti] = useState(false);
  const [showRoundTrip, setShowRoundTrip] = useState(false);
  const [disablesubmit, setdisableSubmit] =useState('')

  const { updateFlightInvoice } = useAdmin();

  const [id, setId] = useState(data._id);
  const [formData, setFormData] = useState({
    trip_type: "",
    cust_name: "",
    from: "",
    to: "",
    departure_date: "",
    airline: "",
    pnr: "",
    booking_source: "",
    net_cost: "",
    mark_up: "",
    gstcost: "",
    ideal_collection: "",
    actual_collection: "",
    cancellation_charges: "",
    deficit: "",
    refund_amount: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        trip_type: data.trip_type,
        cust_name: data.cust_name,
        from: data.from,
        to: data.to,
        departure_date: data.departure_date,
        airline: data.airline,
        pnr: data.pnr,
        booking_source: data.booking_source,
        net_cost: data.net_cost,
        mark_up: data.mark_up,
        gstcost: data.gstcost,
        ideal_collection: data.ideal_collection,
        actual_collection: data.actual_collection,
        cancellation_charges: data.cancellation_charges,
        deficit: data.deficit,
        refund_amount: data.refund_amount,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = name === "pnr" ? value.toUpperCase() : value;
    setShowRoundTrip(false);
    if (name === "triptype") {
      if (value === "RoundTrip") {
        setShowRoundTrip(true);
      }
    }
    setShowMultiCiti(false);
    if (name === "triptype") {
      if (value === "MultiCity") {
        setShowMultiCiti(true);
      }
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: uppercaseValue,
    }));

    if (name === "net_cost") {
      setNetcost(value);
    } else if (name === "mark_up") {
      setMarkup(value);
    } else if (name === "actualcollection") {
      setActualcollection(value);
    }
  };

  useEffect(() => {
    const amount = parseFloat(formData.net_cost) || 0;
    const markupCost = parseFloat(formData.mark_up) || 0;
    const actualcost = parseFloat(formData.actual_collection) || 0;
    const withgst = markupCost * 0.18;
    const idealcost = amount + markupCost + withgst;
    const setdeficit = idealcost - actualcost;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstmarkup: withgst,
      ideal_collection: idealcost,
      gstcost: withgst,
      deficit: setdeficit,
    }));
  }, [formData.net_cost, formData.mark_up, formData.actual_collection]);

  const handleSubmit = (e) => {
    setdisableSubmit(true)
    e.preventDefault();
    const requiredFields = [
      "from",
      "to",
      "departure_date",
       "net_cost",
       "mark_up",
       "airline",
       "booking_source",
      
    ];
    const isValid = requiredFields.every((field) => formData[field].trim() !== "");
  
    if (!isValid) {
      toast.error("Please fill all mandatory fields");
      setdisableSubmit(false)
      return;
    }
    updateFlightInvoice({ id, formData });
    setInterval(() => {
      window.location.reload();
    }, 1000);
  };
  //   console.log(formData);

  const handleClose = () => {
    onClose();
  };

  //Validation Code
  const {
    NumberValidation,
    LastnameValidation,
    NetVisaCostValidation,
    MarkUpValidation,
    ActualCollectionValidation,
    CancelationChargesValidation,
    RefundAmountValidation,
    ToValidation,
    AirlineValidation,
  } = Validation();

  return (
    <>
      <div className="relative">
        <div class="backdrop-blu fixed inset-0 z-50  m-1 items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 pb-10 pl-20 pr-20 pt-10">
          <div>
            <div className="z-50 rounded-xl bg-gray-200 p-5 shadow-xl">
              <span
                className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
                onClick={handleClose}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  zIndex: "1100px",
                }}
              >
                <FaTimes size={24} />
              </span>
              <form>
                <h2 class="text-center text-base font-semibold leading-7 underline">
                  Update One Way Trip Invoice
                </h2>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Trip Type <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="trip_type"
                        id="trip_type"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.trip_type}
                        required
                        readOnly
                      />
                      <span id="last"></span>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      for="destination-name"
                      className="text-black block text-sm font-semibold leading-6"
                    >
                      Customer Name <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="cust_name"
                        id="cust_name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.cust_name}
                        required
                        readOnly
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-2 lg:ml-[50px]">
                    <label
                      for="from"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      From <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="from"
                        name="from"
                        type="text"
                        autoComplete="from"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        onKeyUp={LastnameValidation}
                        value={formData.from}
                        required
                      />
                      <span id="last"></span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center sm:col-span-2">
                    <FontAwesomeIcon
                      icon={faArrowRightArrowLeft}
                      className="mx-auto mb-4 text-red-500"
                      style={{ marginTop: "40px" }}
                      // onClick={swapValues}
                    />
                  </div>

                  <div class="sm:col-span-2 lg:mr-[50px]">
                    <label
                      for="to"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      To<sup className="mb-2 text-red-500">*</sup>
                    </label>

                    <div class="mt-2">
                      <input
                        id="to"
                        name="to"
                        type="text"
                        autoComplete="from"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.to}
                        onKeyUp={ToValidation}
                        required
                      />
                      <span id="toV"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="departure_date"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Departure Date <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="departure_date"
                        name="departure_date"
                        type="date"
                        autoComplete="departure_date"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={
                          formData.departure_date
                            ? formData.departure_date.split("T")[0]
                            : ""
                        }
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="airline"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Airlines<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="airline"
                        name="airline"
                        type="text"
                        autoComplete="airlines"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.airline}
                        onChange={handleInputChange}
                        onKeyUp={AirlineValidation}
                        required
                      />
                      <span id="airlineV"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="pnr"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      PNR<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="pnr"
                        name="pnr"
                        type="text"
                        autoComplete="pnr"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        // onKeyUp={NumberValidation}
                        value={formData.pnr}
                        required
                      />
                      {/* <span id="Number"></span> */}
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="booking_source"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Booking Source<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="booking_source"
                        name="booking_source"
                        type="text"
                        autoComplete="bookingsource"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.booking_source}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="net_cost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Net Cost<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="net_cost"
                        name="net_cost"
                        type="text"
                        autoComplete="netcost"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        onKeyUp={NetVisaCostValidation}
                        value={formData.net_cost}
                      />
                      <span id="netVisaCostValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="mark_up"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Mark Up<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="mark_up"
                        name="mark_up"
                        type="text"
                        autoComplete="mark_up"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        onKeyUp={MarkUpValidation}
                        value={formData.mark_up}
                      />
                      <span id="markUpValidation"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="gstcost"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      GST 18% on Mark Up
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="gstcost"
                        name="gstcost"
                        type="text"
                        autoComplete="gstcost"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.gstcost}
                        required
                        readOnly
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="idealcollection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Ideal Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="idealcollection"
                        name="idealcollection"
                        type="text"
                        autoComplete="idealcollection"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.ideal_collection}
                        required
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="actual_collection"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Actual Collection
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="actual_collection"
                        name="actual_collection"
                        type="text"
                        autoComplete="actual_collection"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        onKeyUp={ActualCollectionValidation}
                        value={formData.actual_collection}
                      />
                      <span id="actualCollectionValidation"></span>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="deficit"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Deficit <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="deficit"
                        name="deficit"
                        type="text"
                        autoComplete="deficit"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={formData.deficit}
                        required
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="cancellation_charges"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Cancellation Charges
                      <sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="cancellation_charges"
                        name="cancellation_charges"
                        type="text"
                        autoComplete="cancellation_charges"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        onKeyUp={CancelationChargesValidation}
                        value={formData.cancellation_charges}
                      />
                      <span id="cancelationChargesValidation"></span>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="refund_amount"
                      class="text-black block text-sm font-semibold leading-6"
                    >
                      Refund Amount<sup className="mb-2 text-red-500">*</sup>
                    </label>
                    <div class="mt-2">
                      <input
                        id="refund_amount"
                        name="refund_amount"
                        type="text"
                        autoComplete="refund_amount"
                        class="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        onKeyUp={RefundAmountValidation}
                        value={formData.refund_amount}
                      />
                      <span id="refundAmountValidation"></span>
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex flex-wrap place-content-center">
                  <button
                    // type="submit"
                    onClick={handleSubmit}
                    disabled={disablesubmit}
                    className="mr-2 rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
