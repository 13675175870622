import React, { useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import Card from "components/card";

import { useAdmin } from "../../../hooks/useAdmin";
import { MdDeleteForever } from "react-icons/md";

const Itinaray = ({ editorContents, onEditorChange, setFileData,id }) => {
  const [activeTab, setActiveTab] = useState("itinerary");
  const [itineraryContent, setItineraryContent] = useState("");
  // const [overviewContent, setOverviewContent] = useState("");
  const [flightDetailsContent, setFlightDetailsContent] = useState("");
  const [hotelDetailsContent, setHotelDetailsContent] = useState("");
  const [inclusionsContent, setInclusionsContent] = useState("");
  const [exclusionsContent, setExclusionsContent] = useState("");
  const [photoGallaryContent, setPhotoGallaryContent] = useState("");
  const [priceGuidelinesContents, setPriceGuidelinesContents] = useState("");
  const [termsconditionContents, setTermsConditionsContents] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadItinearary, setUploadItinerary] = useState([]);

  const { deleteItenaryFiles,itineraryDocuments } = useAdmin();

  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
      setUploadItinerary(editorContents.photogallery)
  },[editorContents])

  useEffect(() => {
    if (itineraryDocuments) {
      setUploadItinerary(itineraryDocuments);
    }
  }, [itineraryDocuments])
  
  const handleDeleteFile = (documentId) => {
      deleteItenaryFiles({ documentId, id });
  };


  const [files, setFiles] = useState([]);
  const handleEditorChange = (editorName, newContent) => {
    const imgRegex =
      /<img.*?src=['"](data:image\/(?:jpeg|png|gif);base64,.*?)['"]/g;
    const matches = newContent.matchAll(imgRegex);
    const extractedFiles = [...matches].map((match) => match[1]);
    setFiles(extractedFiles);
    onEditorChange(editorName, newContent);
  };
  const filePaths = files.map((data, index) => {
    return data.split(":")[1];
  });

  // const { AddInternationalQuotation } = useAdmin();

  const resetData = () => {
    setItineraryContent("");
    // setOverviewContent("");
    setFlightDetailsContent("");
    setHotelDetailsContent("");
    setInclusionsContent("");
    setExclusionsContent("");
    setPhotoGallaryContent("");
    setPriceGuidelinesContents("");
    setTermsConditionsContents("");
  };

  // const resetOverview = () => {
  //   handleEditorChange("overview", "");
  // };
  const resetItinerary = () => {
    handleEditorChange("itinerary", "");
  };
  const resetFlightDetails = () => {
    handleEditorChange("flight", "");
  };
  const resetHotelDetails = () => {
    handleEditorChange("hotel", "");
  };
  const resetInclusions = () => {
    handleEditorChange("inclusions", "");
  };
  const resetExclusions = () => {
    handleEditorChange("exclusions", "");
  };

 
  const resetPhotoGallery = () => {
    
    setSelectedFile([]);
    setFileData([]); 
  
    
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.error("File input element not found.");
    }
  };
  
  const resetPriceGuidelines = () => {
    handleEditorChange("priceguideline", "");
  };
  const resetTermsConditions = () => {
    handleEditorChange("termsconditions", "");
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    const newFilesArray = Array.from(files);
    setFileData((prevFiles) => [...prevFiles, ...newFilesArray]);
  };

  return (
    <>
      <Card>
        <div className="mx-auto max-w-full justify-start overflow-x-auto px-1 sm:px-2 lg:px-2">
          <ul className="text-black-500 border-red mb-3 ml-4 mr-4 mt-6 flex flex-wrap items-start justify-start overflow-x-auto border px-2 py-2 text-center text-base font-semibold dark:bg-gray-700 sm:ml-8 sm:mr-8 sm:flex-nowrap">
            <div className="flex flex-wrap justify-center sm:justify-start">
            
              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "itinerary" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "itinerary"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("itinerary")}
                  style={{ border: "2px solid black" }}
                >
                  Itinerary
                </button>
              </li>

              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "flight" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "flight"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("flight")}
                  style={{ border: "2px solid black" }}
                >
                  Flight Details
                </button>
              </li>

              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "hotel" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "hotel"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("hotel")}
                  style={{ border: "2px solid black" }}
                >
                  Hotel Details
                </button>
              </li>

              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "inclusions" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "inclusions"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("inclusions")}
                  style={{ border: "2px solid black" }}
                >
                  Inclusions
                </button>
              </li>

              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "exclusions" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "exclusions"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("exclusions")}
                  style={{ border: "2px solid black" }}
                >
                  Exclusions
                </button>
              </li>

              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "photogallery" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "photogallery"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("photogallery")}
                  style={{ border: "2px solid black" }}
                >
                  Photo Gallery
                </button>
              </li>

              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "priceguideline" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "priceguideline"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("priceguideline")}
                  style={{ border: "2px solid black" }}
                >
                  Price Guideline
                </button>
              </li>

              <li className="border-black mb-2 inline-block border-r border-solid sm:mb-0">
                <button
                  type="button"
                  className={`tab-btn ${activeTab === "termsconditions" && "active"
                    } border-black w-32 cursor-pointer border-2 px-2 text-center sm:w-auto ${activeTab === "termsconditions"
                      ? "bg-gray-900 text-white"
                      : "text-black bg-transparent"
                    }`}
                  onClick={() => handleTab("termsconditions")}
                  style={{ border: "2px solid black" }}
                >
                  Terms & Conditions
                </button>
              </li>
            </div>
          </ul>

          {/* Conditionally render the corresponding component based on the active tab */}
          {activeTab === "itinerary" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="Itinerary"></label>
              <JoditEditor
                value={editorContents.itinerary}
                onChange={(newContent) =>
                  handleEditorChange("itinerary", newContent)
                }
              />
            </div>
          )}

          {activeTab === "overview" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="Overview"></label>
              <JoditEditor
                value={editorContents.overview}
                onChange={(newContent) =>
                  handleEditorChange("overview", newContent)
                }
              />
            </div>
          )}
          {activeTab === "flight" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="FlightDetails"></label>
              <JoditEditor
                value={editorContents.flight}
                onChange={(newContent) =>
                  handleEditorChange("flight", newContent)
                }
              />
            </div>
          )}
          {activeTab === "hotel" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="HotelDetails"></label>
              <JoditEditor
                value={editorContents.hotel}
                onChange={(newContent) =>
                  handleEditorChange("hotel", newContent)
                }
              />
            </div>
          )}
          {activeTab === "inclusions" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="Inclusions"></label>
              <JoditEditor
                value={editorContents.inclusions}
                onChange={(newContent) =>
                  handleEditorChange("inclusions", newContent)
                }
              />
            </div>
          )}
          {activeTab === "exclusions" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="Exclusions"></label>
              <JoditEditor
                value={editorContents.exclusions}
                onChange={(newContent) =>
                  handleEditorChange("exclusions", newContent)
                }
              />
            </div>
          )}
          {activeTab === "photogallery" && (
            <div className="border-black flex items-center justify-center border border-solid">
              <div className="text-center">
                <label
                  className="mx-auto mb-4 block w-3/4"
                  htmlFor="photogallery"
                ></label>
                <div className="mb-4 p-1">
                  <div className="inline-block rounded-md border-dashed bg-white p-2">
                    <label htmlFor="file-upload" className="block">
                      <img src="/uploadlogo.png" alt="" className="w-12" />
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      accept=".pdf,.jpg,.jpeg,.png"
                      className="custom-file-input h-12 w-full text-center "
                      name="document"
                      multiple
                      onChange={handleFileUpload}
                    />
                    {
                      uploadItinearary && uploadItinearary.map((data) => {
                        return <div className="flex items-center">
                          <span>{data.path}</span>
                          <span className="ml-2" > <MdDeleteForever className="text-red-500  hover:text-green-600 focus:text-green-600"
                                onClick={() =>
                                  handleDeleteFile(data._id, data._id)
                                }/>
                                </span>
                          </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === "priceguideline" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="priceguideline"></label>
              <JoditEditor
                value={editorContents.priceguideline}
                onBlur={(newContent) =>
                  handleEditorChange("priceguideline", newContent)
                }
              />
            </div>
          )}
          {activeTab === "termsconditions" && (
            <div>
              <label className="mx-auto w-3/4" htmlFor="termsconditions"></label>
              <JoditEditor
                value={editorContents.termsconditions}
                onBlur={(newContent) =>
                  handleEditorChange("termsconditions", newContent)
                }
              />
            </div>
          )}
        </div>
      </Card>
     
      {activeTab === "itinerary" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-gray-800 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetItinerary}
          >
            Reset Itinerary
          </button>
        </div>
      )}
      {activeTab === "flight" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-gray-800 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetFlightDetails}
          >
            Reset Flight Details
          </button>
        </div>
      )}
      {activeTab === "hotel" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-gray-800 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetHotelDetails}
          >
            Reset Hotel Details
          </button>
        </div>
      )}
      {activeTab === "inclusions" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-red-500 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetInclusions}
          >
            Reset Inclusions
          </button>
        </div>
      )}
      {activeTab === "exclusions" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-gray-800 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetExclusions}
          >
            Reset Exclusions
          </button>
        </div>
      )}
      {activeTab === "photogallery" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-gray-800 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetPhotoGallery}
          >
            Reset Photo Gallery
          </button>
        </div>
      )}
      {activeTab === "priceguideline" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-gray-800 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetPriceGuidelines}
          >
            Reset Price Guideline
          </button>
        </div>
      )}
      {activeTab === "termsconditions" && (
        <div className="mt-4 flex justify-center">
          <button
          type="button"
            className="flex items-end justify-center rounded border-gray-800 bg-gray-800 px-5 py-1 text-white hover:bg-gray-800"
            onClick={resetTermsConditions}
          >
            Reset Terms & Conditions
          </button>
        </div>
      )}
    </>
  );
};

export default Itinaray;
