import { useAuth } from '../../../hooks/useAuth';
import React, { useEffect } from 'react'

const LogOut=()=> {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, []);
  
}
export default LogOut;