
import Repository from "./Repository"

class AuthRepository {

    async loginReq(params) {
        const response = await Repository.post('/admin/user', params)
            
            .then((response) => {
                console.log(response)
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async forgotPass(payload){
    const response=await Repository.post('/login/forgot-password',payload)
                .then((response) => {
                    return response
                }).catch((error) => {
                    console.log(error)
                    return error
                })
            return response
    }

}

export default new AuthRepository()