import React from "react";
import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
// import RTLDefault from "views/rtl/default";
// import SignIn from "views/auth/SignIn/SignIn";
// import CoustomerDitails from "views/admin/marketplace/components/CoustomerDitails";
// import Quotation from "views/auth/quotation/Quotation";
import Setting from "views/auth/Settings/Settings";
import { IoMdHome } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { RiBarChart2Fill } from "react-icons/ri";
import InvoiceTable from "views/admin/marketplace/components/InvoiceTable";
import QuotationTable from "views/admin/marketplace/components/QuotationTable";
import Marketplace from "views/admin/marketplace/index";
import Reports from "views/auth/Reports/Reports";
import { IoLogOutSharp } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import LogOut from "views/auth/SignOut/LogOut";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <IoMdHome className="h-5 w-5" />,
    component: <MainDashboard />,
  },
  {
    name: "Leads",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <HiUsers className="h-5 w-5" />,
    component: <Marketplace />,
    secondary: true,
  },
  {
    name: "Quotation",
    layout: "/auth",
    path: "quotation",
    icon: <FaFileInvoice className="h-5 w-5" />,
    component: <QuotationTable />,
  },
  {
    name: "Invoices",
    layout: "/admin",
    path: "profile",
    icon: <FaFileInvoiceDollar className="h-5 w-5" />,
    component: <InvoiceTable />,
  },
  {
    name: "Customers",
    layout: "/admin",
    icon: <FaUsers className="h-5 w-5" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Reports",
    layout: "/auth",
    path: "reports",
    icon: <RiBarChart2Fill className="h-5 w-5" />,
    component: <Reports />,
  },
  {
    name: "Settings",
    layout: "/auth",
    path: "settings",
    icon: <IoMdSettings className="h-5 w-5" />,
    component: <Setting />,
  },
  {
    name: "Log Out",
    layout: "/auth",
    path: "logout",
    icon: <IoLogOutSharp className="h-5 w-5" />,
    component: <LogOut />,
  },
];

export default routes;
