import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { Validation } from "views/admin/marketplace/components/Validation";
import { useAdmin } from "../../../hooks/useAdmin";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import Itinaray from "./Itinaray";
import { toast } from "react-toastify";

export default function Domestic({ parentData, clientId, name, onClose }) {
  const [netcost, setNetcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [totaldiscount, setTotaldiscount] = useState("");
  const [applygst, setApplygst] = useState("");
  const [fileData, setFileData] = useState([]);
  const [actualcollection, setActualcollection] = useState("");
  const { AddDomesticQuotation } = useAdmin();
  const editor = useRef(null);
  const [itdomesticData, setDomesticData] = useState("");
  const [disableSubmit, setdisableSubmit] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDomesticData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState({
    destination: "",
    travel_date: "",
    duration: "",
    net_cost: "",
    markup: "",
    gross_cost: "",
    discount: "",
    gst: "",
    actual_collection: "",
    deficit: "",
    cancellation_charges: "",
    refund_amount: "",
    gstamount: "",
  });

  const [editorContents, setEditorContents] = useState({
    itinerary: "",
    overview: "",
    flight: "",
    hotel: "",
    inclusions: "",
    exclusions: "",
    priceguideline: "",
    termscondition: "",
  });

  const handleEditorChangeParent = (editorName, newContent) => {
    setEditorContents((prevContents) => ({
      ...prevContents,
      [editorName]: newContent,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "net_cost") {
      setNetcost(value);
    } else if (name === "markup") {
      setMarkup(value);
    } else if (name === "discount") {
      setTotaldiscount(value);
    } else if (name === "gst") {
      setApplygst(value);
    } else if (name === "actual_collection") {
      setActualcollection(value);
    }
  };

  const handleDiscard = () => {
    setFormData({
      destination: "",
      travel_date: "",
      duration: "",
      net_cost: "",
      markup: "",
      gross_cost: "",
      // discount: "",
      gst: "",
      gsttype: "",
      actual_collection: "",
      ideal_collection: "",
      deficit: "",
      cancellation_charges: "",
      refund_amount: "",
    });
  };
  useEffect(() => {
    const amount = parseFloat(netcost) || 0;
    const grossCost = parseFloat(markup) || 0;
    const discount = parseFloat(totaldiscount) || 0;
    const actualcoll = parseFloat(actualcollection) || 0;

    const gst = parseFloat(applygst) || 0;
    if (gst === 18) {
      var gst18 = (grossCost * 18) / 100;
      var grossamount = amount + grossCost + gst18;
    }
    if (gst === 5) {
      var total = amount + grossCost;
      var grossamount = total + (total * 5) / 100;
      var gst5 = (total * 5) / 100;
    }
    const adddiscount = grossamount - discount;
    const idealColl = adddiscount;
    const totalideal = idealColl || 0;
    const newdeficit = (totalideal - actualcoll).toFixed(2) || 0;
    const gst_amount = gst5 || gst18 || 0;

    setFormData((prevFormData) => ({
      ...prevFormData,
      gross_cost: grossamount,
      ideal_collection: totalideal,
      deficit: newdeficit,
      actual_collection: actualcoll,
      gstamount: gst_amount,
    }));
  }, [markup, netcost, totaldiscount, applygst, actualcollection]);

  function validateForm() {
    if (
      formData.destination !== "" &&
      formData.travel_date !== "" &&
      formData.duration !== "" &&
      formData.net_cost !== "" &&
      formData.markup !== "" &&
      formData.gross_cost !== "" &&
      // formData.discount !== "" &&
      formData.gst !== "" &&
      formData.actual_collection !== "" &&
      formData.deficit !== ""
    ) {
      return true;
    }
    return false;
  }

  //post data
  const handleSubmit = async (e) => {
    setdisableSubmit(true);
    e.preventDefault();
    const finalData = new FormData();
    finalData.append("formData", JSON.stringify(formData));
    finalData.append("parentData", JSON.stringify(parentData));
    finalData.append("clientId", JSON.stringify(clientId));
    finalData.append("editorContents", JSON.stringify(editorContents));
    finalData.append("name", JSON.stringify(name));
    fileData.forEach((file) => {
      finalData.append("document", file);
    });
    if (validateForm() === true) {
      await AddDomesticQuotation(finalData);
      setInterval(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Please fill manadatory field");
      setdisableSubmit(false);
    }
  };

  //Validation Code
  const {
    LastnameValidation,
    DurationValidation,
    NetVisaCostValidation,
    MarkupValidation,
    DiscountValidation,
    GrrosCostValidation,
  } = Validation();

  const [closeForm, setCloseForm] = useState(true);
  const handleClose = () => {
    onClose();
    setCloseForm(false);
  };

  return (
    <>
      {closeForm ? (
        <div className="relative">
          {/* <div class="fixed inset-0 z-50 m-1  items-center justify-center overflow-y-auto bg-gray-400 pb-10 pl-10 pr-10 pt-10 backdrop-blur-0"> */}
          {/* <div> */}
          <div className="z-50 rounded-bl-lg rounded-br-lg bg-gray-200 p-5 shadow-xl">
            <span
              className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
              onClick={handleClose}
              style={{
                marginRight: "8px",
                cursor: "pointer",
                zIndex: "1100px",
              }}
            >
              {" "}
              <FaTimes size={24} />{" "}
            </span>
            <h2 className="mb-4 text-center text-lg font-semibold underline">
              Domestic Package
            </h2>

            <div class="border-b border-gray-900/10 pb-12">
              <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label
                    for="destination"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Destination <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="destination"
                      id="destination"
                      autocomplete="given-name"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.destination}
                      onKeyUp={LastnameValidation}
                    />
                    <span id="last"></span>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="startdate"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Travel Date <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="date"
                      name="travel_date"
                      id="traveldate"
                      autocomplete="traveldate"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 
                shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={handleInputChange}
                      value={formData.travel_date}
                      required
                    />
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="duration"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Duration (In days){" "}
                    <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      id="duration"
                      name="duration"
                      type="text"
                      autocomplete="duration"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.duration}
                      // onKeyUp={DurationValidation}
                    />
                    <span id="Duration"></span>
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="gst type"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    GST <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <select
                      id="gsttype"
                      name="gst"
                      value={formData.gsttype}
                      onChange={handleInputChange}
                      required
                      className="text-black block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option>Select Package</option>

                      <option value="5">With 5% GST</option>
                      <option value="18">With 18% GST</option>
                    </select>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="netCost"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Net Cost <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="net_cost"
                      id="netCost"
                      autocomplete="netCost"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.net_cost}
                      onKeyUp={NetVisaCostValidation}
                    />
                    <span id="netVisaCostValidation"></span>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="markup"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Mark Up <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="markup"
                      id="markup"
                      autocomplete="markup"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.markup}
                      onKeyUp={MarkupValidation}
                    />
                    <span id="MarkupValidation"></span>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="grossCost"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Gross Cost <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="gross_cost"
                      id="grossCost"
                      autoComplete="grossCost"
                      class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.gross_cost}
                      onKeyUp={GrrosCostValidation}
                      readOnly
                    />
                    <span id="grrosCostValidation"></span>
                  </div>
                </div>

                {/* <div class="sm:col-span-3">
                  <label
                    for="discount"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Discount <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="discount"
                      id="discount"
                      autocomplete="discount"
                      class="text-grey-500 ring-grey-300 focus:ring-grey-600 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm 
                ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.discount}
                      onKeyUp={DiscountValidation}
                    />
                    <span id="discountValidation"></span>
                  </div>
                </div> */}

                <div class="sm:col-span-3">
                  <label
                    for="actualCollection"
                    class="text-black block text-sm font-semibold leading-6"
                  >
                    Ideal Collection <sup className="mb-2 text-red-500">*</sup>
                  </label>
                  <div class="mt-2">
                    <input
                      type="text"
                      name="ideal_collection"
                      id="idealCollection"
                      autocomplete="idealCollection"
                      class="text-grey-500 ring-grey-300 block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 
                ring-inset focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.ideal_collection}
                      readOnly
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    for="tls-cost"
                    className="text-black block text-sm font-semibold leading-6"
                  >
                    Deficit
                    <sup classNameName="mb-2 text-red-500">*</sup>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="deficit"
                      id="deficit"
                      autocomplete="deficit"
                      className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleInputChange}
                      value={formData.deficit}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Itinaray
                  ref={editor}
                  editorContents={editorContents}
                  onEditorChange={handleEditorChangeParent}
                  setFileData={setFileData}
                />
              </div>

              <div className="mt-4 flex flex-wrap items-center justify-center">
                <button
                  className=" rounded border-gray-600 bg-green-500 px-5 py-1 text-white hover:bg-green-700"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  Save
                </button>
                <div className="mx-4 sm:mx-4"></div>
                <button
                  type="button"
                  onClick={handleDiscard}
                  class=" rounded border-gray-600 bg-red-500 px-3 py-1 text-white hover:bg-red-700 "
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      ) : null}
    </>
  );
}
