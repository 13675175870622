import React, { useState } from "react"; // Make sure to import React
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Image from "./pic.png"; // Assuming this import is correct

export default function ForgetPass() {
  const [showOTPBox, setShowOTPBox] = useState(false); // State for displaying OTP box
  const [formData, setFormData] = useState({
    password: "",
    email: "", // Add email to formData state
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Set showOTPBox to true after submitting the form
    setShowOTPBox(true);
    // You can handle other form submission logic here
  };

  const handleOtpSubmit = () => {
    window.location.href = "/forget-pass/reset-pass";
  };

  return (
    <>
      <ToastContainer />
      <section className="flex min-h-screen items-center justify-center bg-gray-50">
        <div className="flex max-w-3xl items-center rounded-2xl bg-gray-100 p-5 shadow-lg">
          <div className="hidden w-1/2 md:block">
            <img className="rounded-2xl" src={Image} alt="Login" />
          </div>
          <div className="px-8 md:w-1/2 md:px-16">
            <h2 className="text-2xl font-bold text-[#002D74]">
              Forget Password ?
            </h2>
            <p className="mt-4 text-xs text-[#002D74]">
              Welcome back please enter your Email-ID
            </p>

            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <input
                className="mt-8 rounded-xl border p-2"
                type="text"
                name="email"
                placeholder="Enter Email-ID"
                onChange={handleInputChange}
                value={formData.email}
                required
              />

              {/* Conditionally render OTP box based on showOTPBox state */}
              {showOTPBox && (
                <div className="flex flex-col">
                  <input
                    className="mt-8 rounded-xl border p-2"
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    // Add necessary props and event handlers
                    required
                  />
                  <button
                    className="rounded-xl bg-[#FF0000] py-2 text-white duration-300 hover:scale-105"
                    onClick={handleOtpSubmit}
                  >
                    Submit OTP
                  </button>
                </div>
              )}

              {/* Render the Submit button */}
              {!showOTPBox && (
                <button
                  className="rounded-xl bg-[#FF0000] py-2 text-white duration-300 hover:scale-105"
                  type="submit"
                >
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
