import { toast } from "react-toastify";
import AdminRepository from "../repositories/AdminRepository";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { data } from "autoprefixer";

export const useAdmin = () => {
  const [remarkData, setRemarkData] = useState();
  const [leadData, setLeadData] = useState();
  const [loading, setLoading] = useState(false);
  const [quotationData, setquotationData] = useState();
  const [quotationCombinedData, setQuotationCombinedData] = useState();
  const [invoiceCombinedData, setInvoiceCombinedData] = useState();
  const [settingdata, setSeetingdata] = useState();
  const [accountData, setAccountData] = useState();
  const [newdocuments, setDocuments] = useState();
  const [itineraryDocuments, setItineraryDocuments] = useState();
  const [downloadfile, setDownloadfile] = useState();
  const [addfiles, setAddFiles] = useState();
  const route = useNavigate();

  return {
    invoiceCombinedData,
    quotationCombinedData,
    quotationData,
    loading,
    leadData,
    accountData,
    settingdata,
    remarkData,
    newdocuments,
    itineraryDocuments,
    downloadfile,
    addfiles,
    // HOOK FOR SAVE LEAD DATA
    saveLeadDetails: async (payload) => {
      const response = await AdminRepository.addLead(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook For Update Account Dteails

    updateAccountDetails: async (payload) => {
      const response = await AdminRepository.updateAccount(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    //Hook For Update Profile Settings

    updateProfileDetails: async (payload) => {
      const response = await AdminRepository.updateProfile(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Update Lead Deatails
    updateLeadDetails: async (payload) => {
      const response = await AdminRepository.updateLead(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    //HOOK FOR FECTH ACCOUNT DATA

    reqAccountData: async () => {
      const response = await AdminRepository.reqAccountData();
      if (response.status === 200) {
        toast.success(response.data.message);
        setAccountData(response.data.data);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR FETCH SETTINGS DATA
    reqSettingsData: async () => {
      const response = await AdminRepository.reqSettingsData();
      if (response.status === 200) {
        toast.success(response.data.message);
        setSeetingdata(response.data.data);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR SAVE SETTINGS DATA
    saveSettingsData: async (payload) => {
      const response = await AdminRepository.addSettingsData(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR SAVE ACCOUNT DATA
    saveAccountData: async (payload) => {
      const response = await AdminRepository.addAccountDetails(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook For Add New Quotation Data
    AddInternationalQuotation: async (payload) => {
      const response = await AdminRepository.postInternationalQuotation(
        payload
      );
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // Hook For Add New Domestic Quotation Data
    AddDomesticQuotation: async (payload) => {
      const response = await AdminRepository.postDomesticQuotation(payload);
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // Hook For Add New Hotel Quotation Data
    AddHotelQuotation: async (payload) => {
      const response = await AdminRepository.postHotelQuotation(payload);
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // Hook For Add New Visa Quotation Data
    AddVisaQuotation: async (payload) => {
      const response = await AdminRepository.postVisaQuotation(payload);
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // Hook For Add New Insurance Quotation Data
    AddInsuranceQuotation: async (payload) => {
      const response = await AdminRepository.postInsuranceQuotation(payload);
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // Hook For Add New Flight Quotation Data
    AddFlightQuotation: async (payload) => {
      const response = await AdminRepository.postFlightData(payload);
      if (response) {
        if (response.status === 200) {
          toast.success(response.data.message);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // ========================= UPADTE QUOTATION DATA API  //

    // Hook for update international quotation

    updateInternationalQuotation: async (payload) => {
      const response = await AdminRepository.updateInternationalQuot(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook for update deomestic quotation

    updateDomesticQuotes: async (payload) => {
      const response = await AdminRepository.updateDomesticQuot(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.data.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook for update hotel book quotation

    updateHotelbookQuotes: async (payload) => {
      const response = await AdminRepository.updateHotelBookQuot(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.data.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook for update visa quotation

    updateVisaQuotation: async (payload) => {
      const response = await AdminRepository.updateVisaQuot(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.data.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook for update insurance quotation

    updateInsuranceQuotation: async (payload) => {
      const response = await AdminRepository.updateInsuranceQuot(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.data.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook for update insurance quotation

    updateFlightQuotation: async (payload) => {
      const response = await AdminRepository.updateFlightQuot(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.data.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR UPDATE INTERNATIONAL INVOICE  ======>=============>===========>========>

    updateInternationalInvoice: async (payload) => {
      const response = await AdminRepository.updateInvoiceData(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR UPDATE DOMESTIC INVOICE

    updateDomesticInvoice: async (payload) => {
      const response = await AdminRepository.updateDomesticInvoiceData(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR UPDATE HOTELBOOK INVOICE

    updateHotelbookInvoice: async (payload) => {
      const response = await AdminRepository.updateHotelBookInvoiceData(
        payload
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR UPDATE VISA INVOICE
    updateVisaInvoice: async (payload) => {
      const response = await AdminRepository.updateVisaInvoiceData(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR UPDATE INSURANCE INVOICE updateFlightInvoiceData
    updateInsuranceInvoice: async (payload) => {
      const response = await AdminRepository.updateInsuranceInvoiceData(
        payload
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // HOOK FOR UPDATE FLIGHT INVOICE
    updateFlightInvoice: async (payload) => {
      const response = await AdminRepository.updateFlightInvoiceData(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hook For Fetched Combined Data Of All Quotations
    getCombinedQuotationData: async () => {
      const response = await AdminRepository.getCombinedQuotationData();
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setQuotationCombinedData(response.data.combinedData);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // Hook For Fetched Combined Data Of All Invoices
    getCombinedInvoiceData: async () => {
      const response = await AdminRepository.getCombinedInvoiceData();
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setInvoiceCombinedData(response.data.combinedData);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // GET LEADS
    getLeadDetails: async () => {
      const response = await AdminRepository.getLeads();
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setLeadData(response.data.data);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },

    // get quotation data
    quotationDetails: async () => {
      const response = await AdminRepository.getQuotationData();
      if (response) {
        if (response.status == 200) {
          toast.success(response.data.message);
          setquotationData(response.data.data);
          setLoading(true);
        } else if (response.code === "ERR_NETWORK") {
          toast.error(response.message);
        } else {
          toast.warn(response.data.message);
        }
      }
    },
    // Hook For Save Remark Data

    saveRemarkDetails: async (payload) => {
      const response = await AdminRepository.postRemark(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(data);
        return response;
      } else {
        toast.error(response.data.message);
      }
    },

    // Hook For Fetch Remark Details

    reqRemarkDetails: async () => {
      const response = await AdminRepository.reqRemarkDetails();
      if (response.status === 200) {
        setRemarkData(response.data.data);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },
    // post invoice data
    saveInvoiceDetails: async (payload) => {
      const response = await AdminRepository.postInvoiceData(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(data);
        return response;
      } else {
        toast.error(response.data.message);
      }
    },

    deleteFiles: async (payload) => {
      const response = await AdminRepository.deleteFilesData(payload);
      if (response.status === 200) {
        setDocuments(response.data.documents);
        toast.success(response.data.message);
        return response;
      } else {
        toast.error(response.data.message);
      }
    },

    deleteItenaryFiles: async (payload) => {
      const response = await AdminRepository.deleteItineraryFilesData(payload);
      if (response.status === 200) {
        setItineraryDocuments(response.data.documents);
        // toast.success(response.data.message);
        setLoading(data);
        return response;
      } else {
        toast.error(response.data.message);
      }
    },

    // Hooks for add files

    reqAddNewFile: async (payload) => {
      const response = await AdminRepository.reqAddFiles(payload);
      if (response.status === 200) {
        setAddFiles(response.data.data);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },
    reqAddNewInvFile: async (payload) => {
      const response = await AdminRepository.reqAddInvFiles(payload);
      if (response.status === 200) {
        setAddFiles(response.data.data);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },
    // Hooks for add files

    reqAddNewFile: async (payload) => {
      const response = await AdminRepository.reqAddFiles(payload);
      if (response.status === 200) {
        setAddFiles(response.data.data);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },

    // Hooks for adownload files

    reqDownloadFile: async (payload) => {
      const response = await AdminRepository.reqDownloadFiles(payload);
      if (response.status === 200) {
        setDownloadfile(response.data);
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },
    // Hook for invoice download files

    reqDownloadinvoiceFile: async (payload) => {
      console.log(payload);
      const response = await AdminRepository.reqDownloadinvoice(payload);
      if (response.status === 200) {
        setDownloadfile(response.data)
        setLoading(true);
      } else if (response.code === "ERR_NETWORK") {
        toast.error(response.message);
      } else {
        toast.warn(response.data.message);
      }
    },
  };
};

