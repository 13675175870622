import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Card from "components/card";
import logo from "./Final_Logo.png";
import { useAdmin } from "hooks/useAdmin";
import { Link, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const InsuranceInvoicePrint = () => {
  const {
    getCombinedInvoiceData,
    invoiceCombinedData,
    reqAccountData,
    accountData,
    reqSettingsData,
    settingdata,
    getLeadDetails,
    leadData,
    loading,
  } = useAdmin();

  const location = useLocation();
  // const [id, setId] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [finalleaddata, setFinalleaddata] = useState("");
  const [totalSum, setTotalSum] = useState(0);

  // useEffect(() => {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  //   setId(id);
  // }, [location]);

  useEffect(() => {
    getCombinedInvoiceData();
    reqAccountData();
    reqSettingsData();
    getLeadDetails();
  }, [loading]);

  useEffect(() => {
    filterDataById();
  }, [invoiceCombinedData || leadData]);

  console.log(data.gst_on_markup);

  const filterDataById = () => {
    if (id && invoiceCombinedData && invoiceCombinedData.length > 0) {
      const filtered = invoiceCombinedData.filter((data) => data._id === id);
      setFilteredData(filtered);

      let sum = 0;
      filtered.forEach((data) => {
        const markUp = parseInt(data?.mark_up) || 0;
        const netCost = parseInt(data?.net_cost) || 0;
        sum += markUp + netCost;
      });
      setTotalSum(sum);
    } else {
      setFilteredData([]);
    }

    if (leadData && leadData.length > 0) {
      const filteredLead = leadData.filter(
        (data) => data._id === filteredData[0]?.client_id
      );
      setFinalleaddata(filteredLead);
    } else {
      setFinalleaddata([]);
    }
  };

  const [isPrinting, setIsPrinting] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Insurance Invoice",
    onAfterPrint: () => {
      setIsPrinting(false); // Reset print mode after printing
    },
  });

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting, handlePrint]);

  function numberToWordsWithCurrency(num) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const suffixes = ["", "Thousand", "Lakh", "Crore"];

    if (num === 0) return "Zero Rupees Only";

    let words = "";
    let group = 0;

    const numParts = String(num).split(".");
    const integerPart = parseInt(numParts[0], 10);
    const decimalPart = parseInt(numParts[1] || 0, 10);

    // Convert integer part
    if (integerPart > 0) {
      let integerWords = "";
      let remainingNum = integerPart;

      while (remainingNum > 0) {
        let chunk;
        if (group === 0) {
          chunk = remainingNum % 1000;
          remainingNum = Math.floor(remainingNum / 1000);
        } else {
          chunk = remainingNum % 100;
          remainingNum = Math.floor(remainingNum / 100);
        }

        if (chunk !== 0) {
          let chunkWords = "";
          if (chunk >= 100) {
            chunkWords += units[Math.floor(chunk / 100)] + " Hundred ";
            chunk %= 100;
          }
          if (chunk >= 11 && chunk <= 19) {
            chunkWords += teens[chunk - 11] + " ";
            chunk = 0; // Skip tens and units
          } else {
            chunkWords += tens[Math.floor(chunk / 10)] + " ";
            chunk %= 10;
            chunkWords += units[chunk] + " ";
          }

          integerWords = chunkWords + suffixes[group] + " " + integerWords;
        }

        group++;
      }

      words += integerWords.trim() + " Rupees ";
    }

    // Convert decimal part
    if (decimalPart > 0) {
      const decimalWords =
        units[Math.floor(decimalPart / 10)] +
        (decimalPart % 10 !== 0 ? "-" + units[decimalPart % 10] : "") +
        " Paise Only";
      words += "and " + decimalWords;
    } else {
      // If the decimal part is zero, add "Only" at the end
      words += "Only";
    }

    return words.trim();
  }
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    // <>
    //   <Card extra={"w-full pb-10 p-4 h-full"}>
    //     <Link
    //       to="/admin/profile"
    //       className="fa-times-icon mb-2 mr-[-27px] flex justify-end text-red-600"
    //       onClick={toggleShowAll}
    //       style={{
    //         marginRight: "330px",
    //         cursor: "pointer",
    //         zIndex: "1100px",
    //       }}
    //     >
    //       {" "}
    //       <FaTimes size={24} />{" "}
    //     </Link>
    //     <div>
    //       {filteredData.map((data) => (
    //         <div
    //           ref={componentRef}
    //           className={`overflow border-gray m-auto h-[297mm] w-[210mm] border ${
    //             isPrinting ? "print-page" : ""
    //           }`}
    //           style={{ padding: "20px 20px" }}
    //         >
    //           <div className="flex items-center justify-between rounded-lg p-3">
    //             <img src={logo} alt="Company Logo" className="h-20 mr-2 w-27" />
    //             <div className="flex flex-col">
    //               <div className="ml-4">
    //                 <b>
    //                     <h4 className="text-black mt-5 text-xl font-semibold leading-7 ">
    //                       {" "}
    //                       {settingdata[0]?.agencyName}
    //                   </h4>
    //                 </b>
    //                 <h6 className="font-light">
    //                   Address | {settingdata[0]?.address}
    //                 </h6>
    //                 <h6 className="font-light">
    //                   <span className="font-bold">Email :</span>{" "}
    //                   <span style={{ color: "#0db9ef" }}>
    //                     {settingdata[0]?.company_person_email}
    //                   </span>
    //                 </h6>
    //                 <h6 className="font-light">
    //                   <span className="font-bold">Tel : </span>
    //                   +91- {settingdata[0]?.phonenum}
    //                 </h6>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="mb-4 ">
    //             {/* <div>
    //             <h2 className="mb-2 mt-5 text-center text-lg font-bold ">
    //               Tax Invoice
    //             </h2>
    //             <div className="border-black  flex rounded-2xl border border-r border-gray-900 px-2 py-1 font-bold">
    //               <table className="min-w-full divide-y ">
    //                 <thead>
    //                   <tr>
    //                     <th className=" border-r border-gray-900 px-2 py-1 font-bold">
    //                       Bill To:
    //                     </th>
    //                     <th className=" px-2 py-1 font-bold">Invoice Details</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <td className="border-r border-gray-900 px-2 py-1 text-start font-bold">
    //                       <span>Name : {data.cust_name} </span><br />
    //                       <span>City : Nashik </span><br />
    //                       <span className=" font-bold">Mobile Number : {finalleaddata[0]?.mobile_no}</span>

    //                     </td>
    //                     <td className=" px-2 py-1 font-bold ">
    //                       Invoice No : {data.invoice_number} <br />
    //                       <span className=" font-bold">
    //                         Invoice Date :{" "}
    //                         {new Date(data.start_date).toLocaleDateString(
    //                           "en-IN"
    //                         )}
    //                       </span>{" "}
    //                       <br />
    //                       <span className=" font-bold">
    //                         Invoice Duo Date :{" "}
    //                         {new Date(data.end_date).toLocaleDateString(
    //                           "en-IN"
    //                         )}{" "}
    //                       </span>
    //                       <br />
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //             </div> */}
    //             <div>
    //               <h2 className="mb-2 mt-5 text-center text-lg font-bold ">
    //                 Tax Invoice
    //               </h2>
    //               <div className="border-black overflow-x-auto rounded-lg border border-gray-900 px-2 py-1 font-bold">
    //                 {/* Invoice Details */}
    //                 <table className="w-full divide-gray-900">
    //                   <thead className="bg-gray-300">
    //                     <tr>
    //                       <th className="border-r border-gray-900 px-2 py-1 font-bold">
    //                         Bill To:
    //                       </th>
    //                       <th className="px-2 py-1 font-bold">
    //                         Invoice Details
    //                       </th>
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     <tr>
    //                       <td className="border-r border-gray-900 px-2 py-1 text-start font-bold">
    //                         <span className="font-bold">
    //                           Name: {data.cust_name}
    //                         </span>
    //                         <br />
    //                         <span className="font-light">
    //                           City: {finalleaddata[0]?.city}
    //                         </span>
    //                         <br />
    //                         <h6 className="font-light">
    //                           <span className="font-bold">Mobile No : </span>
    //                           +91- {finalleaddata[0]?.mobile_no}
    //                         </h6>
    //                       </td>
    //                       <td className="px-2 py-1 font-bold ">
    //                         <h6 className="font-light">
    //                           <span className="font-bold">Invoice No : </span>
    //                           {data.invoice_number}
    //                         </h6>
    //                         <h6 className="font-light">
    //                           <span className="font-bold">Invoice Date : </span>
    //                           {new Date(data.start_date).toLocaleDateString(
    //                             "en-IN"
    //                           )}
    //                         </h6>
    //                         <h6 className="font-light">
    //                           <span className="font-bold">
    //                             Invoice Due Date :{" "}
    //                           </span>
    //                           {new Date(data.end_date).toLocaleDateString(
    //                             "en-IN"
    //                           )}
    //                         </h6>
    //                       </td>
    //                     </tr>
    //                   </tbody>
    //                 </table>
    //               </div>
    //             </div>
    //             <hr className="dark:bg-black-700 my-8 h-px border-0 bg-gray-200" />
    //             Narration
    //             <hr className="my-8 h-px border-0 bg-gray-200 dark:bg-gray-700" />
    //             <div className="flex justify-start ">
    //               <div className="overflow-x-auto">
    //                 <table className="min-w-full divide-y divide-gray-200">
    //                   <thead className="bg-gray-300">
    //                     <tr>
    //                       <th className="border border-gray-900 px-2 py-1 font-bold">
    //                         Sr No
    //                       </th>
    //                       <th className="border border-gray-900 px-2 py-1 font-bold">
    //                         Particular
    //                       </th>
    //                       <th className="border border-gray-900 px-2 py-1 font-bold">
    //                         Total Price
    //                       </th>
    //                     </tr>
    //                   </thead>
    //                   <tbody>
    //                     <tr>
    //                     <td className="border border-gray-900 px-2 py-1 text-center font-bold">
    //                         <span className="font-light">1</span>
    //                       </td>
    //                       <td className="border border-gray-900 px-2 py-1 font-bold">
    //                         <span className="font-light">
    //                           Package Name: {data.package_type}
    //                         </span>{" "}
    //                         <span className="font-light">
    //                           Insurance Type: {data.insurance_type}
    //                         </span>
    //                         <br />
    //                         <span className="font-light">
    //                           Date:{" "}
    //                           {new Date(data.date_time).toLocaleDateString(
    //                             "en-IN"
    //                           )}
    //                         </span>
    //                       </td>
    //                       <td className="border border-gray-900 px-2 py-1 font-bold">
    //                         <span className="font-light">
    //                           INR : {parseFloat(data.deficit).toFixed(2)}
    //                         </span>
    //                       </td>
    //                     </tr>
    //                     <tr>
    //                       <td className="w-64 border border-gray-900 px-2 py-1 font-bold">
    //                         Sub Total
    //                       </td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1 font-bold"></td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1 font-bold">
    //                         INR {parseFloat(data.deficit).toFixed(2)}
    //                       </td>
    //                     </tr>
    //                     <tr>
    //                       <td className="w-64 border border-gray-900 px-2 py-1 font-bold">
    //                         {" "}
    //                         {parseFloat(data.deficit) > 0
    //                           ? numberToWordsWithCurrency(
    //                               parseFloat(data.deficit)
    //                             )
    //                           : "Zero Rupees"}
    //                       </td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1 font-bold"></td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1 font-bold">
    //                         INR {parseFloat(data.deficit).toFixed(2)}
    //                       </td>
    //                     </tr>
    //                   </tbody>
    //                 </table>
    //               </div>
    //             </div>
    //             <div className="font-light">
    //               - Total price inclusive of all taxes
    //             </div>
    //             <div>
    //               <h2 className="mb-2 mt-5 text-start text-lg font-bold ">
    //                 Bank Details
    //               </h2>
    //               <div className="overflow-x-auto">
    //                 <table className="min-w-full border-collapse divide-y divide-gray-900 border  shadow-sm">
    //                   <thead>
    //                     <tr>
    //                       <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
    //                         Account Holder Name
    //                       </th>
    //                       <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
    //                         Bank Name
    //                       </th>
    //                       <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
    //                         Account Number
    //                       </th>
    //                       <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
    //                         IFSC Code
    //                       </th>
    //                       <th className="text-black border border-gray-900 px-2 py-1 text-left text-sm font-bold uppercase">
    //                         Branch Name/Address
    //                       </th>
    //                     </tr>
    //                   </thead>
    //                   <tbody className="divide-y divide-gray-200  shadow-sm">
    //                     <tr>
    //                       <td className="w-64 border border-gray-900 px-2 py-1">
    //                         {accountData[0]?.accountholderName}
    //                       </td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1">
    //                         {accountData[0]?.banckname}
    //                       </td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1">
    //                         {accountData[0]?.accountNumber}
    //                       </td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1">
    //                         {accountData[0]?.ifsccode}
    //                       </td>
    //                       <td className="w-64 border border-gray-900 px-2 py-1">
    //                         {accountData[0]?.branchname}
    //                       </td>
    //                     </tr>
    //                   </tbody>
    //                 </table>
    //               </div>
    //             </div>
    //           </div>

    //           <p className="text-center">
    //             This is computer-generated and does not require a signature.
    //           </p>
    //         </div>
    //       ))}
    //     </div>
    //   </Card>
    //   <div className="flex place-content-center">
    //     <button
    //       type="button"
    //       className="mt-4 place-content-center rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700"
    //       onClick={() => setIsPrinting(true)}
    //     >
    //       Download PDF
    //     </button>
    //   </div>
    // </>
    <>
      <Card extra={"w-full pb-10 p-4 h-full"}>
        <div className="flex flex-col items-end justify-end px-4 sm:flex-row sm:px-6 md:px-8">
          <Link
            to="/admin/profile"
            className="ml-0 mt-2 text-red-600 hover:text-red-700 sm:ml-2 sm:mt-0"
            onClick={toggleShowAll}
            style={{ cursor: "pointer" }}
          >
            <FaTimes size={20} />{" "}
          </Link>
        </div>
        <div>
          {filteredData.map((data, index) => (
            <div
              ref={componentRef}
              className={`overflow m-auto h-[297mm] w-[210mm] border border-gray-400 ${
                isPrinting ? "print-page" : ""
              }`}
              style={{ padding: "20px 20px" }}
            >
              <div className="flex items-center justify-between rounded-lg p-3">
                <img src={logo} alt="Company Logo" className="w-27 mr-2 h-24" />
                <div className="flex flex-col">
                  <div className="ml-4">
                    <b>
                      <h4 className="text-black mt-5 text-xl font-semibold leading-7 ">
                        {" "}
                        {settingdata[0]?.agencyName}
                      </h4>
                    </b>
                    <h6 className="font-light">
                      Address | {settingdata[0]?.address}
                    </h6>

                    <h6 className="font-light">
                      <span className="font-bold">Email :</span>{" "}
                      <span style={{ color: "#0db9ef" }}>
                        {settingdata[0]?.company_person_email}
                      </span>
                    </h6>

                    <h6 className="font-light">
                      <span className="font-bold">Tel : </span>
                      +91- {settingdata[0]?.phonenum}
                    </h6>

                    <h6 className="font-light">
                      <span className="font-bold">GST Number : </span>
                      {accountData[0]?.GstNo}
                    </h6>

                    <h6 className="font-light">
                      <span className="font-bold">HSN Code : </span>
                      {accountData[0]?.hsncode}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="mb-4 ">
                <div>
                  <h2 className="text-md mb-2 mt-5 text-center font-bold ">
                    Tax Invoice
                  </h2>
                  <div className="border-black flex h-44 justify-between overflow-x-auto px-2 py-1 font-bold">
                    {/* Invoice Details */}
                    <div className="mr-4 w-96 rounded-lg border border-gray-400">
                      <h2 className="text-center">Bill To</h2>
                      <h3 className="ml-4 mt-4">{data.cust_name}</h3>

                      <h3 className=" ml-4 font-light">
                        {finalleaddata[0]?.city}
                      </h3>

                      {/* Conditionally render Firm Name if it exists */}
                      {finalleaddata[0]?.firm_name && (
                        <h3 className="ml-4">
                          Firm Name:
                          <span className="mx-1 font-light">
                            {finalleaddata[0]?.firm_name}
                          </span>
                        </h3>
                      )}

                      {/* Conditionally render GST Number if it exists */}
                      {finalleaddata[0]?.cust_gst && (
                        <h3 className="ml-4">
                          GST Number:
                          <span className="mx-1 font-light">
                            {finalleaddata[0]?.cust_gst}
                          </span>
                        </h3>
                      )}

                      <div className="ml-4 flex">
                        <h3 className="font-bold">Mobile No:</h3>
                        <h3 className="mx-1 font-light">
                          {" "}
                          +91- {finalleaddata[0]?.mobile_no}
                        </h3>
                      </div>
                    </div>
                    <div className="w-96 rounded-lg border border-gray-400">
                      <h2 className="text-center">Invoice Details</h2>
                      <div className="ml-4 mt-4 flex">
                        <h3 className="font-bold">Invoice No : </h3>
                        <h3 className="mx-1 font-light">
                          {" "}
                          {data.invoice_number}
                        </h3>
                      </div>
                      <div className="ml-4 flex">
                        <h3 className="font-bold">Invoice Date : </h3>
                        <h3 className="mx-1 font-light">
                          {" "}
                          {new Date(data.start_date).toLocaleDateString(
                            "en-IN"
                          )}
                        </h3>
                      </div>
                      <div className="ml-4 flex">
                        <h3 className="font-bold">Invoice Due Date : </h3>
                        <h3 className="mx-1 font-light">
                          {" "}
                          {new Date(data.end_date).toLocaleDateString("en-IN")}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="dark:bg-black-700 my-2 h-px border-0 bg-gray-200" />
                Narration :
                <hr className=":bg-gray-700 my-2 h-px border-0 bg-gray-200" />
                <div className="flex justify-start ">
                  <div className="overflow-x-auto">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="" style={{ backgroundColor: "#f1f1f1" }}>
                        <tr>
                          <th
                            class="border border-gray-400 px-2 py-1 text-center font-bold"
                            style={{ width: "0px" }}
                          >
                            Sr No.
                          </th>
                          <th
                            class="border border-gray-400 px-2 py-1 text-left font-bold"
                            style={{ width: "500px" }}
                          >
                            Particular
                          </th>
                          <th class="border border-gray-400 px-2 py-1 text-right font-bold">
                            Total Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border border-gray-400 px-2 py-1 text-center font-light">
                            {index + 1}
                          </td>
                          <td class="border border-gray-400 px-2 py-1 font-light">
                            Package Name : {data.package_type} <br />
                            <span class=" font-light">
                              Insurance Type : {data.insurance_type}
                            </span>
                            <br />
                            <span class=" font-light">
                              Date :
                              {new Date(data.date_time).toLocaleDateString(
                                "en-IN"
                              )}
                            </span>
                            <br />
                          </td>
                          <td class="border border-gray-400 px-2 py-1 text-right font-light">
                            INR {totalSum.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tr>
                        <td
                          class="w-160 mr-0 border border-gray-400 px-2  py-1 text-left font-light"
                          style={{ width: "643px" }}
                        >
                          GST (18%)
                        </td>
                        <td class="ml-0 w-60 border border-gray-400 px-2 py-1 text-right font-light">
                          INR {parseFloat(data.gst_on_markup).toFixed(2)}
                        </td>
                      </tr>

                      <tr>
                        <td
                          class="w-160 mr-0 border border-gray-400 px-2  py-1 text-left font-light"
                          style={{ width: "643px" }}
                        >
                          Sub Total
                        </td>
                        <td class="ml-0 w-60 border border-gray-400 px-2 py-1 text-right font-light">
                          INR {parseFloat(data.deficit).toFixed(2)}
                        </td>
                      </tr>

                      <tr>
                        <td
                          class="border border-gray-400 px-1 py-1 font-semibold"
                          style={{ fontSize: "16px" }}
                        >
                          {parseFloat(data.deficit) > 0
                            ? numberToWordsWithCurrency(
                                parseFloat(data.deficit).toFixed(2)
                              )
                            : "Zero Rupees"}
                        </td>

                        <td class="border border-gray-400 px-2 py-1 text-right font-bold">
                          INR {parseFloat(data.deficit).toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="font-light">
                  - Total price inclusive of all taxes
                </div>
                <div>
                  <h2 className="text-md mb-2 mt-5 text-center font-semibold ">
                    Bank Details
                  </h2>
                  <div className="overflow-x-auto">
                    <table className="min-w-full border-collapse divide-y divide-gray-900 border  shadow-sm">
                      <thead>
                        <tr>
                          <th className="text-black border border-gray-400 px-2 py-1 text-left text-sm font-bold uppercase">
                            Account Holder Name
                          </th>
                          <th className="text-black border border-gray-400 px-2 py-1 text-left text-sm font-bold uppercase">
                            Bank Name
                          </th>
                          <th className="text-black border border-gray-400 px-2 py-1 text-left text-sm font-bold uppercase">
                            Account Number
                          </th>
                          <th className="text-black border border-gray-400 px-2 py-1 text-left text-sm font-bold uppercase">
                            IFSC Code
                          </th>
                          <th className="text-black border border-gray-400 px-2 py-1 text-left text-sm font-bold uppercase">
                            Branch Name/Address
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200  shadow-sm">
                        <tr>
                          <td className="w-64 border border-gray-400 px-2 py-1">
                            {accountData[0]?.accountholderName}
                          </td>
                          <td className="w-64 border border-gray-400 px-2 py-1">
                            {accountData[0]?.banckname}
                          </td>
                          <td className="w-64 border border-gray-400 px-2 py-1">
                            {accountData[0]?.accountNumber}
                          </td>
                          <td className="w-64 border border-gray-400 px-2 py-1">
                            {accountData[0]?.ifsccode}
                          </td>
                          <td className="w-64 border border-gray-400 px-2 py-1">
                            {accountData[0]?.branchname}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="text-sm font-light">
                      * This is computer-generated invoice and does not require
                      a signature.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="mt-10 text-center">
                  Thank you for giving us an opportunity to serve you!
                </p>
              </div>
            </div>
          ))}
        </div>
      </Card>

      <div className="flex place-content-center">
        <button
          type="button"
          className="mt-4 place-content-center rounded border-gray-600 bg-red-500 px-5 py-1 text-white hover:bg-red-700"
          onClick={() => setIsPrinting(true)}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default InsuranceInvoicePrint;
